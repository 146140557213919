import { connect } from "react-redux";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";

import { GetGiftCardsPaid } from "../../../store/actions";
import openNotification from "../../../../components/notification";

import {
  DatePicker,
  Input,
  InputContainer,
  InputLabel,
  InputsContainer,
  RangePickerContainer,
  Table,
  TableContentContainer,
  TableValue,
  Button,
} from "../../styles";
import { useForm } from "react-hook-form";
import PaginationComponent from "../../../../components/pagination";

function GifCardsPaidTab({
  isLoadingPaids,
  paidsGiftsCards,
  meta,

  error,
  getList,
}) {
  useEffect(() => {
    if (error) {
      openNotification("Error", "Error getting table data", "error");
    }
  }, [error]);

  const { register, handleSubmit, setValue, watch } = useForm();

  const onSubmit = (body) => {
    const bodyP = { ...body, paginate: 15 };
    getList(bodyP);
  };

  useEffect(() => {
    const body = {
      name: null,
      from: null,
      to: null,
      paginate: null,
    };
    getList(body);
  }, [getList]);

  const formatdate = (date) =>
    new Date(date).toLocaleDateString("en-US", { timeZone: "UTC" });
  const formatAmount = (value) =>
    value
      ?.toString()
      .replace(/,/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const column = [
    {
      title: "Establecimiento",
      dataIndex: "business_name",
      width: "25%",
      render: (nameData) => (
        <Link to="/dashboard/giftCardPayments/establishment">
          <TableValue isFlex>
            <span>{nameData}</span>
          </TableValue>
        </Link>
      ),
    },
    {
      title: "No. Lote",
      dataIndex: "lote_number",
      width: "15%",
    },
    {
      title: "No. Referencia",
      dataIndex: "reference",
      width: "15%",
    },
    {
      title: "Fecha de Envío Lote",
      dataIndex: "date_pending_end",
      width: "15%",
      render: (nameData) => (
        <TableValue>
          <span>{formatdate(nameData)}</span>
        </TableValue>
      ),
    },
    {
      title: "Fecha de Pago",
      dataIndex: "date_paid",
      width: "15%",
      render: (nameData) => (
        <TableValue>
          <span>{formatdate(nameData)}</span>
        </TableValue>
      ),
    },
    {
      title: "Monto Pagado",
      dataIndex: "sum",
      width: "15%",
      render: (nameData, allData) => (
        <TableValue>
          <span>{`${formatAmount(nameData)} ${allData.currency_symbol}`}</span>
        </TableValue>
      ),
    },
  ];

  const getListFn = (bdy) => {
    const body = {
      ...bdy,
      name: watch("name"),
      from: watch("from"),
      to: watch("to"),
      status: watch("status"),
    };
    getList(body);
  };

  return (
    <TableContentContainer>
      <InputsContainer onSubmit={handleSubmit(onSubmit)}>
        <InputContainer>
          <InputLabel>Nombre del establecimiento</InputLabel>
          <Input
            placeholder="Ej. La Floridita"
            {...register("name")}
            disabled={isLoadingPaids}
          />
        </InputContainer>

        <InputContainer>
          <InputLabel>Desde - Hasta</InputLabel>
          <RangePickerContainer>
            <DatePicker.RangePicker
              disabled={isLoadingPaids}
              placeholder={["dd/mm/aaaa", "dd/mm/aaaa"]}
              allowClear
              getPopupContainer={(trigger) => trigger}
              format="yyyy/MM/DD"
              {...register("from")}
              {...register("to")}
              onChange={(_, value) => {
                setValue("from", value[0]);
                setValue("to", value[1]);
              }}
            />
          </RangePickerContainer>
        </InputContainer>
        <InputContainer>
          <Button htmlType="submit" disabled={isLoadingPaids}>
            Buscar
          </Button>
        </InputContainer>
      </InputsContainer>

      <Table
        loading={isLoadingPaids}
        bordered={false}
        dataSource={paidsGiftsCards}
        columns={column}
        scroll={{ x: "max-content" }}
        pagination={false}
      />
      {meta && <PaginationComponent meta={meta} changePage={getListFn} />}
    </TableContentContainer>
  );
}
const mapStateToProps = (state) => ({
  isLoadingPaids: state.giftsCardsPaid.isLoadingPaids,
  paidsGiftsCards: state.giftsCardsPaid.paidsGiftsCards,
  meta: state.giftsCardsPaid.meta,
  error: state.giftsCardsPaid.error,
});
const mapDispatchToProps = (dispatch) => ({
  getList: (body) => dispatch(GetGiftCardsPaid(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GifCardsPaidTab);
