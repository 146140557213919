import styled from "styled-components";
import { Button as ButtonBase, Input as InputBase } from "antd";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 8px;
  font-family: Sora;
  background: transparent;
  height: auto;

  @media screen and (max-width: 480px) {
    margin-left: 0;
  }
`;

export const Button = styled(ButtonBase)`
  :hover,
  :active,
  :focus {
    background: ${(props) =>
      props.disabled
        ? "linear-gradient(45deg, #01C5B7 0%, #5ECF59 100%)"
        : "linear-gradient(45deg, #01C5B7 0%, #5ECF59 100%)"};
    color: #ffffff;
  }

  background: ${(props) =>
    props.disabled
      ? "linear-gradient(45deg, #01C5B7 0%, #5ECF59 100%)"
      : "linear-gradient(45deg, #01C5B7 0%, #5ECF59 100%)"};
  border-radius: 8px;
  border: none;
  font-family: Sora;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 16px;

  min-width: 117px;
  height: 48px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: ${(props) => (props.margin ? props.margin : "0")};

  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 16px 0 8px 0;
  }
`;

export const CancelButton = styled(ButtonBase)`
  :hover,
  :active,
  :focus {
    background: transparent;
    color: #ffffff;
  }

  background: transparent;
  border-radius: 8px;
  border: none;
  font-family: Sora;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 16px;
  min-width: 105px;
  height: 48px;
  color: #fcfdfd;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;

  margin: ${(props) => (props.margin ? props.margin : "0")};

  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 16px 0 8px 0;
  }
`;

export const ButtonText = styled.span`
  background: linear-gradient(45deg, #01c5b7 0%, #5ecf59 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

export const ButtonsContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 100px;
  margin-right: 110px;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    margin-top: 0;
    margin-right: 0;
  }
`;

export const InputLabel = styled.label`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
  background: ${(props) => (props.disabled ? "#F0F2F1" : "#fdfdfd")};
  padding: 0 4px;
  margin: 0 12px;
  position: relative;
  top: 10px;
  width: max-content;
  z-index: 1;
`;

export const InputContainer = styled.div`
  width: 546px;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin: 0 0 24px 0;
  width: auto;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const Input = styled(InputBase)`
  height: 48px;
  border-radius: 4px;

  :hover,
  :focus {
    box-shadow: none;
    border: 1px solid #d9d9d9;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 32px;

  @media screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 24px;
  }
`;

export const Title = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #35403b;
  display: flex;
  align-self: flex-start;
`;

export const ErrorLabel = styled.label`
  color: red;
`;
