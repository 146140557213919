import styled from "styled-components";
import { Image as ImageBase } from "antd";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  width: 98px;
  height: 152px;
  border: 1px solid #d2d9d6;
  box-sizing: border-box;
  border-radius: 16px;
  margin-right: 16px;
`;

export const Title = styled.h5`
  height: 24px;
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #35403b;
`;

export const LogoImageContainer = styled.div`
  width: 63px;
  height: 80px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const PictureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 100%;
  height: 152px;
  border: 1px solid #d2d9d6;
  box-sizing: border-box;
  border-radius: 16px;
`;

export const PicturesRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 80px;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: thin;
  gap: 16px 24px;
`;

export const BusinessImage = styled.div`
  width: 80px;
  height: 80px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const LogoImage = styled.div`
  width: 63px;
  height: 80px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const ImageLogo = styled(ImageBase)`
  display: flex;
  width: 63px;
  height: 80px;
  position: relative;
  top: auto;
  left: 0px;
`;

export const Image = styled(ImageBase)`
  display: flex;
  width: 80px;
  height: 80px;
  position: relative;
`;

export const PrevMaskImage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
