import * as types from "../types";

export const GetGiftCardsAmounts = () => ({
  type: types.GET_GIFT_CARDS_AMOUNTS,
  payload: {},
});

export const GetGiftCardsPaid = (body) => ({
  type: types.GET_PAID_GIFT_CARDS,
  payload: body,
});

export const GetPendingGiftCards = (body) => ({
  type: types.GET_PENDING_GIFT_CARDS,
  payload: body,
});
