import * as types from "../types";

const initialState = {
  isLoadingDetails: false,
  detailsData: undefined,
  detailsError: undefined,
};

function businessTypeDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_BUSINESS_TYPE_DETAILS:
      return {
        isLoadingDetails: true,
        detailsData: undefined,
        detailsError: undefined,
      };

    case types.GET_BUSINESS_TYPE_DETAILS_SUCCESS:
      return {
        isLoadingDetails: false,
        detailsData: action.payload.data,
        detailsError: undefined,
      };

    case types.GET_BUSINESS_TYPE_DETAILS_ERROR:
      return {
        isLoadingDetails: false,
        detailsData: undefined,
        detailsError: action.payload.error,
      };

    default:
      return state;
  }
}

export default businessTypeDetailsReducer;
