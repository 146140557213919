import React from "react";
import { FromDateIcon, LeftArrowIcon } from "../../../assets/icons";
import { Link } from "react-router-dom";
import LoggedLayout from "../../../ui/components/layout/index";
import {
  Container,
  HeadSection,
  Title,
  GiftCardData,
  GreenBlock,
  FinalLabel,
  GoBackLink,
  Row,
  PrincipalSection,
  GeneralText,
  RightContainer,
  NumberGiftCard,
  TableContainer,
  Table,
  LeftContainer,
  NameText,
  GlobalText,
  DatePicker,
  InputTitle,
  ColumnContent,
} from "./styles";

export default function SalesReportDetail() {
  const dataSource = [];

  for (let i = 0; i < 100; i += 1) {
    dataSource.push({
      key: i.toString(),
      date: `0${i}/12/2022`,
      giftCard: `${i}00000`,
      amount: `$1${i}2`,
    });
  }
  const columns = [
    {
      title: "Codigo de GiftCard",
      dataIndex: "giftCard",
      width: "74%",
      key: "giftCard",
      render: (nameData) => (
        <LeftContainer>
          <NameText>{nameData}</NameText>
        </LeftContainer>
      ),
    },
    {
      title: "Fecha de GiftCard Usado",
      dataIndex: "date",
      width: "13%",
      key: "date",
    },
    {
      title: "Monto",
      dataIndex: "amount",
      width: "13%",
      key: "amount",
      render: (amountData) => (
        <RightContainer>
          <GlobalText>{amountData}</GlobalText>
        </RightContainer>
      ),
    },
  ];

  return (
    <LoggedLayout>
      <Container>
        <HeadSection>
          <PrincipalSection>
            <Row>
              <Link to="/dashboard/salesReport/list">
                <LeftArrowIcon />
              </Link>
              <GoBackLink>Reportes de ventas</GoBackLink>
            </Row>
            <Title>Nombre del establecimiento</Title>
            <ColumnContent>
              <InputTitle> Desde - Hasta</InputTitle>
              <DatePicker
                placeholder={["dd/mm/aaaa  -  ", "dd/mm/aaaa"]}
                allowClear={false}
                suffixIcon={<FromDateIcon />}
                getPopupContainer={(trigger) => trigger}
                format="DD/MM/yyyy"
              />
            </ColumnContent>
          </PrincipalSection>
          <GiftCardData>
            <GreenBlock>
              <GeneralText>GiftCard usados</GeneralText>
              <NumberGiftCard>32</NumberGiftCard>
            </GreenBlock>
          </GiftCardData>
        </HeadSection>
        <TableContainer>
          <Table
            dataSource={dataSource}
            columns={columns}
            bordered
            rowClassName="editable-row"
            pagination={{
              pageSizeOptions: ["5", "10", "20", "50"],
              responsive: true,
              locale: { items_per_page: "" },
              showLessItems: true,
            }}
          />
          <FinalLabel>Showing: 1 - 4 of 4</FinalLabel>
        </TableContainer>
      </Container>
    </LoggedLayout>
  );
}
