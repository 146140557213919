import React from "react";
import {
  LogoIcon,
  GiftsCardsMenuIcon,
  EstablishmentsIcon,
  RequestIcon,
  BusinessTypesIcon,
  SalesReportIcon,
  PaidGiftsCardsIcon,
  SettingsIcon,
  UserIcon,
} from "../../../../assets/icons";
import { Container, Content, Menu } from "./styles";

import { useLocation, useNavigate } from "react-router-dom";

export default function SideBarComponent() {
  const { SubMenu } = Menu;
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Container>
      <LogoIcon />
      <Content>
        <Menu
          style={{ width: 224 }}
          selectedKeys={[location.pathname]}
          defaultOpenKeys={["sub1"]}
          mode="inline"
        >
          <SubMenu
            key="sub1"
            icon={<GiftsCardsMenuIcon />}
            title="Gift Card Negocios"
          >
            <Menu.Item
              onClick={() => navigate("/dashboard/establishments/list")}
              key={["/dashboard/establishments/list"]}
              icon={<EstablishmentsIcon />}
            >
              Establecimientos
            </Menu.Item>

            <Menu
              selectedKeys={[location.pathname]}
              defaultOpenKeys={[
                "/dashboard/applications/pending",
                "/dashboard/applications/detail/2",
                "/dashboard/applications/update",
                "/dashboard/applications/declined",
              ]}
              mode="inline"
            >
              <SubMenu
                key={[location.pathname]}
                icon={<RequestIcon />}
                title="Solicitudes"
              >
                <Menu.Item
                  key="/dashboard/applications/pending"
                  onClick={() => navigate("/dashboard/applications/pending")}
                >
                  Solicitudes Pendientes
                </Menu.Item>
                <Menu.Item
                  key="/dashboard/applications/update"
                  onClick={() => navigate("/dashboard/applications/update")}
                >
                  Actualización de datos
                </Menu.Item>
                <Menu.Item
                  key="/dashboard/applications/declined"
                  onClick={() => navigate("/dashboard/applications/declined")}
                >
                  Declinaciones
                </Menu.Item>
              </SubMenu>
            </Menu>

            <Menu.Item
              onClick={() => navigate("/dashboard/businessTypes/list")}
              key={["/dashboard/businessTypes/list"]}
              icon={<BusinessTypesIcon />}
            >
              Tipos de negocios
            </Menu.Item>
            <Menu.Item
              key="/dashboard/salesReport/list"
              onClick={() => navigate("/dashboard/salesReport/list")}
              icon={<SalesReportIcon />}
            >
              Reportes de ventas
            </Menu.Item>
            <Menu.Item
              key={["/dashboard/giftCardPayments/list"]}
              onClick={() => navigate("/dashboard/giftCardPayments/list")}
              icon={<PaidGiftsCardsIcon />}
            >
              Pagos GiftCard
            </Menu.Item>
            <Menu.Item
              key="/dashboard/configuration"
              onClick={() => navigate("/dashboard/configuration")}
              icon={<SettingsIcon />}
            >
              Configuración
            </Menu.Item>

            <Menu
              selectedKeys={[location.pathname]}
              defaultOpenKeys={[
                "/dashboard/users",
                "/dashboard/roles",
                "/dashboard/permissions",
              ]}
              mode="inline"
            >
              <SubMenu
                key={[location.pathname]}
                icon={<UserIcon />}
                title="Mantenimiento Usuarios"
              >
                <Menu.Item
                  key="/dashboard/users"
                  onClick={() => navigate("/dashboard/users")}
                >
                  Usuarios
                </Menu.Item>
                <Menu.Item
                  key="/dashboard/roles"
                  onClick={() => navigate("/dashboard/roles")}
                >
                  Roles
                </Menu.Item>
                <Menu.Item
                  key="/dashboard/permissions"
                  onClick={() => navigate("/dashboard/permissions")}
                >
                  Permisos
                </Menu.Item>
              </SubMenu>
            </Menu>
          </SubMenu>
        </Menu>
      </Content>
    </Container>
  );
}
