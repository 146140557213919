import * as types from "../types";

const initialState = {
  userData: undefined,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER:
      return {
        userData: action.payload.userData,
      };

    case types.CLEAN_USER:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
