import styled from "styled-components";
import { Button as ButtonBase, Modal as ModalBase } from "antd";

export const Container = styled.div``;

export const HeaderBack = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
  margin-bottom: 24px;
  cursor: pointer;
`;

export const HeaderBackContent = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderText = styled.p`
  min-width: 93px;
  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #35403b;
  margin: 0px;
  margin-left: 9px;
`;

export const BusinessName = styled.h5`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #35403b;
  flex: none;
  margin-bottom: 24px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  border: 1px solid #d2d9d6;
  box-sizing: border-box;
  border-radius: 16px;
  margin-bottom: 16px;
`;

export const SubTitle = styled.p`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #35403b;
  margin: 0px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: ${(props) => props.width ?? "100%"};
  max-width: ${(props) => props.maxWidth ?? "100%"};
  margin-top: 16px;
`;

export const TimeTitle = styled.h5`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #6a736f;
  margin: 16px 0px 0px;
`;

export const DeclarationTitle = styled.h5`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #35403b;
  margin-bottom: 16px;
`;

export const QuesionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  margin: 0 0 16px;
`;

export const FilesBox = styled.div`
  width: 97%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border: 1px solid #d2d9d6;
  box-sizing: border-box;
  border-radius: 8px;
  gap: 103px;
  margin-top: 16px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 24px 0 72px;
`;

export const CancelBtn = styled(ButtonBase)`
  width: 106px;
  height: 48px;
  border-radius: 8px;
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: linear-gradient(45deg, #01c5b7 0%, #5ecf59 100%);
  background: -webkit-linear-gradient(#01c5b7, #5ecf59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.015);
  :hover,
  :active,
  :focus {
    background: -webkit-linear-gradient(#01c5b7, #5ecf59);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  border: none;
`;

export const ContinueBtn = styled(ButtonBase)`
  width: 101px;
  height: 48px;
  border-radius: 8px;
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-left: 16px;
  background: linear-gradient(45deg, #01c5b7 0%, #5ecf59 100%);
  border: 1px solid #44b641;
  color: #fcfdfd;
  :hover,
  :active,
  :focus {
    border-color: #44b641;
    color: #fcfdfd;
    background: linear-gradient(45deg, #01c5b7 0%, #5ecf59 100%);
  }
`;

export const Modal = styled(ModalBase)`
  .ant-modal-footer {
    border-top: none;
  }
  .ant-modal-content {
    border-radius: 16px;
    width: 552px;
  }
  .ant-modal-header {
    border-radius: 16px 16px 0 0;
  }
  .ant-modal-title {
    font-family: Sora;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #35403b;
  }
  .ant-modal-close {
    display: none;
  }
  &.ant-modal {
    width: 552px;
  }
`;

export const Table = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  thead > tr > th {
    font-family: "Sora";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: #9da6a2;
  }
  tbody > tr > td {
    font-family: "Sora";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #35403b;
  }
  tbody > tr  {
    
    border-bottom: 1px solid #D2D9D6;
  }

  tr {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 4px 8px;
  }
  tr > th,
  td {
    width: 20%;
  }
  tr > td:nth-child(2) {
    text-align: center;
  }
  tr > th:nth-child(1) {
    text-align: initial;
  }
`;
