import { connect } from "react-redux";
import React, { useEffect } from "react";
import openNotification from "../../components/notification";
import {
  Container,
  HeaderContainer,
  Tittle,
  PendingCountBox,
  TittlePendingCountBox,
  TotalPendingCountBox,
  InputContainer,
  InputLabel,
  Input,
  DatePickerContainer,
  DatePicker,
  SearchHeaderContainer,
  TableContainer,
  Table,
  Row,
  RowTitle,
  RowText,
  RowTextFirst,
  Button,
} from "./styles";
import { CalendarIcon } from "../../../assets/icons";
import LoggedLayout from "../../../ui/components/layout/index";
import { getApplicationPendingList } from "../store/actions";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import PaginationComponent from "../../components/pagination";

const formatdate = (date) =>
  new Date(date).toLocaleDateString("en-US", { timeZone: "UTC" });

const columns = [
  {
    title: "Establecimiento",
    dataIndex: "name",
    key: "establishment",
    render: (data) => (
      <Row>
        <RowTitle>Establecimiento</RowTitle>
        <RowTextFirst>{data}</RowTextFirst>
      </Row>
    ),
  },
  {
    title: "Fecha de solicitud",
    dataIndex: "application_date",
    key: "applicationdate",
    width: "25%",
    render: (data) => (
      <Row>
        <RowTitle>Fecha de solicitud</RowTitle>
        <RowText>{formatdate(data)}</RowText>
      </Row>
    ),
  },
  {
    title: "Fincimex Estatus",
    dataIndex: "fincimex_status",
    key: "status",
    width: "25%",
    render: (status) => (
      <Row justify="center">
        <RowTitle>Estatus</RowTitle>
        <RowText>{status}</RowText>
      </Row>
    ),
  },
  {
    title: "Ciudad",
    dataIndex: "city",
    key: "city",
    width: "40%",
    render: (data) => <RowText>{data}</RowText>,
  },
];

const PendingApplications = ({
  isLoadingGetPendingList,
  getPendingListError,
  getPeddingList,
  pendingList,
  meta,
  pendingAmounts,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const body = { page: 1, name: null, from: null, to: null, paginate: 15 };
    getPeddingList(body);
  }, [getPeddingList]);

  useEffect(() => {
    if (getPendingListError) {
      openNotification(
        "Error",
        "Ocurrió un error cargando la lista, intente nuevamente",
        "error"
      );
    }
  }, [getPendingListError]);

  const { handleSubmit, register, setValue, watch } = useForm();

  const onSubmit = (body) => {
    getPeddingList(body);
  };

  const getPeddingListFn = (bd) => {
    const body = {
      ...bd,
      name: watch("name"),
      from: watch("from"),
      to: watch("to"),
    };
    getPeddingList(body);
  };

  return (
    <LoggedLayout>
      <Container>
        <HeaderContainer>
          <Tittle>Solicitudes pendientes</Tittle>
          <PendingCountBox>
            <TittlePendingCountBox>
              Solicitudes pendientes
            </TittlePendingCountBox>
            <TotalPendingCountBox>
              {isLoadingGetPendingList ? "Cargando..." : pendingAmounts?.review}
            </TotalPendingCountBox>
          </PendingCountBox>
        </HeaderContainer>

        <SearchHeaderContainer onSubmit={handleSubmit(onSubmit)}>
          <InputContainer>
            <InputLabel>Nombre del establecimiento</InputLabel>
            <Input
              placeholder="Ej: Floridita"
              {...register("name")}
              disabled={isLoadingGetPendingList}
            />
          </InputContainer>
          <DatePickerContainer>
            <InputLabel>Desde - Hasta</InputLabel>
            <DatePicker
              placeholder={["dd/mm/aaaa  -  ", "dd/mm/aaaa"]}
              getPopupContainer={(trigger) => trigger}
              suffixIcon={<CalendarIcon />}
              format="yyyy/MM/DD"
              {...register("from")}
              {...register("to")}
              onChange={(_, value) => {
                setValue("from", value[0]);
                setValue("to", value[1]);
              }}
            />
          </DatePickerContainer>
          <InputContainer>
            <Button htmlType="submit" disabled={isLoadingGetPendingList}>
              Buscar
            </Button>
          </InputContainer>
        </SearchHeaderContainer>
        <TableContainer>
          <Table
            loading={isLoadingGetPendingList}
            dataSource={pendingList?.data}
            columns={columns}
            pagination={false}
            onRow={(element) => ({
              onClick: () =>
                navigate(`/dashboard/applications/detail/${element.id}`),
            })}
          />
        </TableContainer>
        {meta && (
          <PaginationComponent meta={meta} changePage={getPeddingListFn} />
        )}
      </Container>
    </LoggedLayout>
  );
};

const mapStateToProps = (state) => ({
  isLoadingGetPendingList: state.applicationsPending.isLoadingGetPendingList,
  getPendingListError: state.applicationsPending.getPendingListError,
  pendingList: state.applicationsPending.pendingList,
  meta: state.applicationsPending.meta,
  pendingAmounts: state.applicationsPending.pendingAmounts,
});
const mapDispatchToProps = (dispatch) => ({
  getPeddingList: (body) => dispatch(getApplicationPendingList(body)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingApplications);
