import styled from "styled-components";
import { Select as SelectBase } from "antd";

export const Container = styled.div`
  margin-top: 64px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const Ul = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  //flex-wrap: wrap;
  list-style-type: none;
  gap: 8px;

  max-width: 500px;
  overflow-x: auto;
  scrollbar-width: thin;
  flex-wrap: nowrap;
`;

export const Li = styled.li`
  padding: 16px 24px;
  height: 56px;
  background: ${(props) => (props.active ? "#44B641" : "#fcfcfc")};
  border-radius: 4px;

  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => (props.active ? "#fcfcfc" : "#9DAAAE")};

  cursor: pointer;
`;

export const Select = styled(SelectBase)`
  min-width: 107px;
  height: 56px;
  background: #fcfcfc;
  border: 1px solid #c7ced1;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #333333;
`;

export const Showing = styled.label`
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #809297;
`;
