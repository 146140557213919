import styled from "styled-components";
import { Table as TableBase, Button as ButtonBase } from "antd";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 52px 24px 24px 24px;
  font-family: Sora;

  @media screen and (max-width: 480px) {
    padding: 0;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GreenBoxLabel = styled.label`
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #575f66;
  text-align: center;
`;

export const FooterGreenBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  width: 237px;
  height: 42px;
  background: rgba(183, 239, 180, 0.25);
  border: 1px solid #b7efb4;
  border-radius: 8px;
  justify-content: space-between;

  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 16px 0 8px 0;
    align-items: center;
  }
`;

export const GreenBoxAmount = styled.label`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #299c27;
`;

export const Title = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.color || "#35403b"};
  display: flex;
  align-self: flex-start;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

export const GreenBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  justify-content: flex-end;
  margin-top: ${(props) => props.marginTop || "0"};

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const Table = styled(TableBase)`
  margin-top: 27px;
  width: 100%;
  .ant-table-thead > tr > th {
    font-family: Sora;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    color: #9da6a2;
    padding: 0;
    border: none;
    background: none;
    padding-left: 8px;
  }
  .ant-table-tbody > tr > td {
    padding: 8px 0 8px 8px;
  }

  &.ant-table.ant-table-bordered
    > &.ant-table-container
    > &.ant-table-content
    > table
    > tbody
    > tr
    > td:first-child {
    background: red;
    text-align: left;
  }

  .ant-table table,
  .ant-table-thead > tr > th:not(:first-child) {
    text-align: center;
  }
  .ant-pagination-options {
    .ant-select {
      background: #fcfcfc;
      border: 1px solid #c7ced1;
      box-sizing: border-box;
      border-radius: 4px;

      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;

      font-family: Sora;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #333333;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      box-shadow: none;
      border: none;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
    }
  }

  .ant-pagination-options {
    order: -1;
    margin-left: 0;
    margin-right: 16px;
    @media screen and (max-width: 480px) {
      display: inline-block;
    }
  }

  .ant-pagination-item {
    width: 58px;
    height: 56px;
    background: #fcfcfc;
    border: none;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    display: flex;
    a {
      font-family: Sora;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #9daaae;
    }
  }
  .ant-pagination-item-active {
    background: #44b641;
    a {
      color: #fcfcfc;
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    width: 58px;
    height: 56px;
    background: #fcfcfc;
  }
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    outline: 0;
    width: 58px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a.ant-pagination-item-link {
    width: inherit;
  }

  .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #44b641;
  }

  .ant-table-pagination.ant-pagination {
    margin: 61px 0px 0px 0px;
  }

  .ant-table-container table > thead > tr:first-child {
    text-align: end;
    padding-right: 8px;
  }
  td:nth-child(1) {
    text-align: left;
  }
  .ant-table-thead > tr > th::before {
    display: none;
  }
  .ant-pagination-prev:focus-visible .ant-pagination-item-link,
  .ant-pagination-next:focus-visible .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: #44b641;
    border: none;
  }
  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
    color: #9daaae;
    border: none;
    cursor: not-allowed;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: none;
  }

  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table {
    border-top: none;
  }
  .ant-table.ant-table-bordered > .ant-table-container {
    border-left: none;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    border-right: none;
  }
  .ant-table-tbody > tr > td {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #35403b;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    border-right: none;
    padding-bottom: 4px;
  }
  .ant-table-pagination.ant-pagination {
    margin: 11px 0px 0px 0px;
    justify-content: center;
  }
  .ant-pagination-options .ant-select {
    width: 107px;
    height: 56px;
    @media screen and (max-width: 480px) {
      margin: 15px 200px 15px 15px;
    }
  }
  .ant-select-arrow,
  .anticon {
    color: #333333;
  }
  .ant-pagination.mini .ant-pagination-prev,
  .ant-pagination.mini .ant-pagination-next {
    @media screen and (max-width: 480px) {
      width: 30px;
    }
  }
`;

export const Text = styled.span`
  font-family: Mulish;
  font-weight: ${(props) => props.fontWeight || "normal"};
  font-size: ${(props) => props.fontSize || "14px"};
  line-height: ${(props) => props.fontWeight || "20px"};
  color: ${(props) => props.fontWeight || "#6A736F"};
  margin-top: ${(props) => props.marginTop || "0"};
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 480px) {
    .responsive-title {
      flex-direction: column;
    }
  }
`;

export const FinalLabel = styled.span`
  font-weight: normal;
  font-size: 16px;
  margin-top: 25px;
  line-height: 24px;
  color: #809297;
  width: 100%;
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 46px;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    margin-top: 0;
  }
`;

export const Button = styled(ButtonBase)`
  padding: 12px 16px;
  min-width: 98px;
  height: 42px;

  :hover,
  :active,
  :focus {
    background: ${(props) =>
      props.disabled
        ? "linear-gradient(45deg, #01C5B7 0%, #5ECF59 100%)"
        : "linear-gradient(45deg, #01C5B7 0%, #5ECF59 100%)"};
    color: #ffffff;
  }

  background: ${(props) =>
    props.disabled
      ? "linear-gradient(45deg, #01C5B7 0%, #5ECF59 100%)"
      : "linear-gradient(45deg, #01C5B7 0%, #5ECF59 100%)"};
  border-radius: 8px;
  border: none;

  font-family: Sora;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 1361px) {
    margin-left: 24px;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 16px 0 8px 0;
  }
`;

export const CancelButton = styled(ButtonBase)`
  padding: 12px 16px;
  min-width: 106px;
  height: 42px;

  :hover,
  :active,
  :focus {
    background: transparent;
    color: #01c5b7;
  }

  background: transparent;
  color: #01c5b7;
  border: none;
  font-family: Sora;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 16px 0 0 0;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;

  @media screen and (max-width: 480px) {
    ${GreenBoxContainer} {
      margin-top: 30px;
    }
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 50px 0 10px 0;
`;

export const InlineTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent || "space-between"};
  margin-top: ${(props) => props.marginTop || "0"};
  align-items: center;
`;

export const TableBottom = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 170px;
  row-gap: 10px;
  margin-top: 40px;

  ${InlineTextContainer}:nth-child(3) > span:last-child {
    font-size: 16px;
    line-height: 24px;
    color: #35403b;
  }

  ${InlineTextContainer} > span:last-child {
    font-weight: bold;
  }

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`;
