import React from "react";
import { FooterIcon } from "../../../../assets/icons";
import {
  Container,
  Content,
  LogoContainer,
  Rights,
  SupportContainer,
  Title,
  Links,
} from "./styles";

export default function FooterComponent() {
  return (
    <Container>
      <Content>
        <LogoContainer>
          <FooterIcon />
          <Rights>©2020 Cashship Business. All rights reserved</Rights>
        </LogoContainer>
        <SupportContainer>
          <Title>Support</Title>
          <Links>Help</Links>
          <Links>Report Fraud</Links>
          <Links>Contant</Links>
        </SupportContainer>
        <SupportContainer>
          <Title>Legal Information</Title>

          <Links>Terms and Conditions</Links>
          <Links>Anti-money laundering policies</Links>
          <Links>Privacy Notice</Links>
          <Links>Compliance</Links>
          <Links>Licences</Links>
        </SupportContainer>
      </Content>
    </Container>
  );
}
