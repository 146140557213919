import * as types from "../types";

const initialState = {
  isLoadingDeclinedList: false,
  meta: undefined,
  declinedList: undefined,
  declinedError: undefined,
};

const applicationDeclinesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_APPLICATIONS_DECLINED:
      return {
        ...state,
        isLoadingDeclinedList: true,
      };
    case types.GET_APPLICATIONS_DECLINED_SUCCESS:
      return {
        isLoadingDeclinedList: false,
        meta: action.payload.meta,
        declinedList: action.payload.data,
        declinedError: undefined,
      };

    case types.GET_APPLICATIONS_DECLINED_ERROR:
      return {
        ...state,
        isLoadingDeclinedList: false,
        meta: undefined,
        declinedList: undefined,
        declinedError: action.payload.error,
      };

    default:
      return state;
  }
};

export default applicationDeclinesReducer;
