import styled from "styled-components";
import { Button as ButtonBase } from "antd";

export const Container = styled.div`
  max-width: 874px;
  width: 100%;
  min-height: 486px;
  background: #fcfdfd;
  border-radius: 16px;
  padding: 40px 0px 40px 40px;
  margin: 0 auto 120px auto;

  display: flex;
  align-items: center;
`;

export const Form = styled.form`
  max-width: 392px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.label`
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #35403b;
  text-align: center;
`;

export const SubTitle = styled.label`
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #35403b;
  text-align: center;
  margin-bottom: 32px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 12px;
`;

export const InputLabel = styled.label`
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  color: #35403b;
`;

export const Input = styled.input`
  background: #fcfdfd;
  border: 1px solid #35403b;
  border-radius: 4px;
  padding: 12px 16px;

  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #35403b;
`;

export const SubmitBtn = styled(ButtonBase)`
  padding: 12px 16px;
  height: 48px;

  border-radius: 8px;

  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  background: linear-gradient(45deg, #01c5b7 0%, #5ecf59 100%);
  color: #fcfdfd;
  border: none;
  :hover,
  :active,
  :focus {
    background: linear-gradient(45deg, #01c5b7 0%, #5ecf59 100%);
    color: #fcfdfd;
    border: none;
  }
`;

export const CancelBtn = styled.button`
  height: 48px;
  background: #fcfdfd;
  border-radius: 8px;

  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  background: #fcfdfd;

  border: 1px solid #5ecf59;
  color: #5ecf59;
  cursor: pointer;
`;

export const BtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
`;

export const Illustration = styled.div`
  margin: 0 auto;
`;

export const ErrorLabel = styled.label`
  font-family: "Sora";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: red;
`;
