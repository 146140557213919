import styled from "styled-components";
import { Select as SelectBase, Button as ButtonBase } from "antd";

export const InputTitle = styled.span`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
  background: #fdfdfd;
  padding: 0 4px;
  margin: 0 12px;
  top: 8px;
  width: max-content;
  position: relative;
  z-index: 1;
`;
export const Form = styled.form`
  width: 100%;
`;
export const AmountTitle = styled.span`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
  background: #fdfdfd;
  padding: 0 4px;
  margin: 10px 12px 0 12px;
  top: 8px;
  width: max-content;
  position: relative;
  z-index: 1;
`;
export const ColumnInputs = styled.div`
  flex-direction: column;
  display: flex;
`;
export const RowSection = styled.div`
  flex-direction: row;
  display: flex;
  margin-bottom: 24px;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;
export const CenterItems = styled.div`
  label {
    cursor: pointer;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
  }
`;

export const Label = styled.label``;

export const Header = styled.div`
  flex-direction: row;
  display: flex;
  margin-bottom: 24px;
  @media screen and (max-width: 480px) {
    margin-bottom: 20px;
  }
`;
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fcfdfd;
  border-radius: 16px;
  padding: 30px 24px 24px 35px;
  width: 100%;
  height: fit-content;
  flex-direction: column;
  font-family: Sora;
  @media screen and (max-width: 480px) {
    margin: 0;
    padding: 16px 5px 40px 5px;
  }
`;

export const ContentDisplay = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${RowSection}:first-child {
    margin-bottom: 31px;
    margin-left: 5px;
  }
  ${ColumnInputs}:first-child {
    margin-right: 24px;
    @media screen and (max-width: 480px) {
      margin-right: 0;
    }
  }

  ${InputTitle}:last-child {
    margin-top: 10px;
  }
`;

export const Text = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #171b1c;
  margin: 4px 0 15px 16px;
`;

export const GreenText = styled.button`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #5ecf59;
  margin-left: 20px;
  cursor: pointer;
  background: none;
  border: none;
  @media screen and (max-width: 480px) {
    margin-left: 0px;
  }
`;

export const GoBackText = styled.span`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #35403b;
  margin-left: 25px;
`;
export const IconInput = styled.button`
  width: 358px;
  height: 43px;
  background: #fafafa;
  border: 1px solid #35403b;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 22px;
  padding-left: 16px;
  justify-content: left;
  display: flex;
  text-align: left;

  button {
    background: transparent;
    border: none;
    margin-top: 4px;
    cursor: pointer;
  }

  input[type="file"] {
    visibility: none;
    display: none;
    opacity: 0;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;
export const AmountInput = styled.input`
  width: 340px;
  height: 48px;
  border: 1px solid #9da6a2;
  border-radius: 4px;
  padding-left: 16px;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;
export const StandarInput = styled.input`
  width: 400px;
  height: 48px;
  background: #fafafa;
  border: 1px solid #9da6a2;
  border-radius: 4px;
  padding-left: 16px;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;
export const NameInput = styled.input`
  width: 400px;
  height: 48px;
  background: #fafafa;
  border: 1px solid #9da6a2;
  border-radius: 4px;
  margin-bottom: 4px;
  padding-left: 16px;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;
export const Select = styled(SelectBase)`
  height: 48px;
  background: #fafafa;
  border: 1px solid #9da6a2;
  box-sizing: border-box;
  border-radius: 4px;
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 48px;
    align-items: center;
  }
`;
export const PriceTitle = styled.span`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
  margin: 32px 0 12px 0;
`;
export const Line = styled.div`
  margin: 8px 0 8px 0;
  border-bottom: 1px solid #9da6a2;
  width: 340px;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;
export const LabelText = styled.label`
  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #9da6a2;
  width: 380px;
  height: 100%;
  padding-top: 8px;
  cursor: pointer;
`;

export const LogoName = styled.label`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #35403b;
  height: 100%;
  padding-top: 8px;
  cursor: pointer;
`;

export const AmountText = styled.span`
  font-weight: normal;
  display: flex;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
  margin-left: 8px;
`;

export const ColumnType = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CenterRow = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const Row = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;

  img {
    width: 25px;
    margin-left: 8px;
  }
`;
export const AddButton = styled(ButtonBase)`
  padding: 12px 16px;
  width: 160px;
  height: 48px;
  :hover,
  :active,
  :focus {
    background: linear-gradient(32.62deg, #01c5b7 0%, #5ecf59 100%);
    color: #ffffff;
  }
  background: linear-gradient(32.62deg, #01c5b7 0%, #5ecf59 100%);
  border-radius: 8px;
  border: none;
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
`;
export const ButtonContainer = styled.div`
  margin-top: 11px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: right;
  @media screen and (max-width: 480px) {
    justify-content: center;
    margin-top: 20px;
  }
`;
export const SelectMultiple = styled(SelectBase)`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 48px;
  max-width: 825px;
  background: #fdfdfd;

  border: 1px solid #9da6a2;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 8px;

  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #111415;

  .ant-select-selector {
    width: 100%;
  }

  .ant-select-selection-item {
    border-radius: 14px;
    background: #f0f2f1;
    margin-right: 8px;
    display: flex;

    .ant-select-selection-item-remove {
      background: #6a736f;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      justify-self: center;
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        margin-top: 2px;
      }
      svg {
        fill: #fdfdfd;
      }
    }
  }
  .ant-select-selection-item-content {
    font-family: Sora;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6a736f;
  }
`;

export const PricesContainer = styled.div`
  flex-direction: column;
  display: flex;
`;

export const ErrorLabel = styled.label`
  color: red;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop};
`;
