import styled from "styled-components";

export const Container = styled.div`
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FileName = styled.a`
  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #35403b;
  :hover {
    color: #35403b;
  }
  margin: 0px;
  margin-right: 8px;
`;
