import apiResource from "..";

const estblishmentsUrl = (body) => {
  let url = "backoffice/businesses?";
  if (body.page) {
    url += `page=${body.page}`;
  }
  if (body.name) {
    url += `name=${body.name}&`;
  }
  if (body.expiration_date_start) {
    url += `&expiration_date_start=${body.expiration_date_start}`;
  }
  if (body.expiration_date_end) {
    url += `&expiration_date_end=${body.expiration_date_end}`;
  }
  if (body.paginate) {
    url += `&paginate=${body.paginate}`;
  }

  return url;
};

const establishmentsService = {
  getList: async ({
    page,
    expiration_date_start,
    expiration_date_end,
    name,
    paginate,
  }) => {
    const response = await apiResource.fetch(
      "GET",
      estblishmentsUrl({
        page,
        expiration_date_start,
        expiration_date_end,
        name,
        paginate
      })
    );
    return response;
  },

  getEstaAmounts: async () => {
    const response = await apiResource.fetch(
      "GET",
      "backoffice/businesses/actives"
    );
    return response.data;
  },

  changeStatus: async (code) => {
    const response = await apiResource.fetch(
      "PATCH",
      `backoffice/businesses/${code}/change-active`
    );
    return response.data;
  },
  getDetails: async (code) => {
    const response = await apiResource.fetch(
      "GET",
      `backoffice/businesses/show/${code}`
    );
    return response.data;
  },
};

export default establishmentsService;
