import apiResource from "..";

const userServices = {
  getMe: async () => {
    const response = apiResource.fetch("GET", "me");
    return response;
  },
  changePassword: async (body) => {
    const response = apiResource.fetch("POST", "profile/password/reset", body);
    return response;
  },
};

export default userServices;
