import apiResource from "..";

const businessTypesService = {
  getBusinessTypesList: async ({ page, paginate }) => {
    const response = await apiResource.fetch(
      "GET",
      `backoffice/business_types?page=${page}&paginate=${paginate}`
    );
    return response;
  },
  changeStatus: async (body) => {
    const response = await apiResource.fetch(
      "PATCH",
      `backoffice/business_types/${body.id}`,
      body
    );
    return response;
  },
  getDetails: async (id) => {
    const response = await apiResource.fetch(
      "GET",
      `backoffice/business_types/${id}`
    );
    return response.data;
  },

  postBusinessType: async (body) => {
    const response = await apiResource.fetch(
      "POST",
      "backoffice/business_types",
      body
    );
    return response.data;
  },
  putBusinessType: async (body) => {
    const id = body.get("id");
    const response = await apiResource.fetch(
      "POST",
      `backoffice/business_types/${id}`,
      body
    );
    return response.data;
  },
};

export default businessTypesService;
