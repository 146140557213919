import React from "react";
import { DenyIcon } from "../../../../../assets/icons";
import { useForm } from "react-hook-form";
import {
  Container,
  Body,
  Button,
  Title,
  InputContainer,
  InputLabel,
  Input,
  CancelButton,
  ErrorLabel,
} from "./styles";

const RequestCreated = ({ setIsModalVisible, declineBusiness, loading }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = ({ comment }) => {
    declineBusiness(comment);
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <DenyIcon />
      <Title>Solicitud denegada</Title>
      <Body>Se debe indicar el motivo de declinación de esta solicitud.</Body>
      <InputContainer>
        <InputLabel>Comentarios</InputLabel>
        <Input
          id="comment"
          name="comment"
          {...register("comment", { required: "Campo requerido" })}
        />
        {errors.comment && (
          <ErrorLabel htmlFor="comment">{errors.comment.message}</ErrorLabel>
        )}
      </InputContainer>
      <Button htmlType="submit" disabled={loading} loading={loading}>
        Enviar
      </Button>
      <CancelButton
        htmlType="button"
        onClick={() => setIsModalVisible(false)}
        disabled={loading}
      >
        Cancelar
      </CancelButton>
    </Container>
  );
};
export default RequestCreated;
