import React from "react";
import { Container, UserName, LogOut, Menu, ChangePassword } from "./styles";
import { CloseIcon, AvatarIcon } from "../../../../assets/icons";
import { connect } from "react-redux";
import { Dropdown } from "antd";
import { cleanUser } from "../../../../routes/protectedWrapper/store/actions";
import { useNavigate } from "react-router-dom";

function HeaderComponent({ userData, userClean }) {
  const navigate = useNavigate();

  const logOut = () => {
    window.localStorage.removeItem("token");
    userClean();
    navigate("/");
  };
  return (
    <Container>
      <UserName>{userData?.full_name}</UserName>
      <Dropdown
        trigger={["click"]}
        overlay={(
          <Menu>
            <ChangePassword onClick={() => navigate("/change-password")}>Cambiar Contraseña</ChangePassword>
            <LogOut onClick={() => logOut()}>Cerrar Sesión</LogOut>
          </Menu>
        )}
      >
        <span>
          <AvatarIcon />
        </span>
      </Dropdown>
      <a href="/" onClick={() => logOut()}>
        <CloseIcon />
      </a>
    </Container>
  );
}
const mapStateToProps = (state) => ({
  userData: state.user.userData,
});
const mapDispatchToProps = (dispatch) => ({
  userClean: () => dispatch(cleanUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
