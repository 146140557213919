import * as types from "../types";

const initialState = {
  isLoadingUserList: false,
  userListData: undefined,
  userListError: undefined,
  meta: undefined,

  isLoadingChangeStatus: false,
  statusChangeSuccess: undefined,
  statusChangeError: undefined,

  isLoadingPostUser: false,
  postUserSuccess: undefined,
  postUserError: undefined,

  isLoadingGetUser: false,
  userData: undefined,
  getUserError: undefined,
};

const userListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USERS_LIST:
      return {
        ...state,
        isLoadingUserList: true,
        userListData: undefined,
        userListError: undefined,
        meta: undefined,
      };

    case types.GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        isLoadingUserList: false,
        userListData: action.payload.data,
        meta: action.payload.meta,
        userListError: undefined,
      };

    case types.GET_USERS_LIST_ERROR:
      return {
        ...state,
        isLoadingUserList: false,
        userListError: action.payload.error,
        userListData: undefined,
        meta: undefined,
      };

    case types.CHANGE_USER_STATUS:
      return {
        ...state,
        isLoadingChangeStatus: true,
        statusChangeSuccess: undefined,
        statusChangeError: undefined,
      };
    case types.CHANGE_USER_STATUS_SUCCESS: {
      const changed = state.userListData.find(({ id }) => id === action.payload);
      changed.status = changed.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
      return {
        ...state,
        isLoadingChangeStatus: false,
        statusChangeSuccess: true,
        statusChangeError: undefined,
      };
    }
    case types.CHANGE_USER_STATUS_ERROR:
      return {
        ...state,
        isLoadingChangeStatus: false,
        statusChangeSuccess: undefined,
        statusChangeError: true,
      };

    case types.POST_USER:
      return {
        ...state,
        isLoadingPostUser: true,
        postUserSuccess: undefined,
        postUserError: undefined,
      };

    case types.POST_USER_SUCCESS:
      return {
        ...state,
        isLoadingPostUser: false,
        postUserSuccess: true,
        postUserError: undefined,
      };

    case types.POST_USER_ERROR:
      return {
        ...state,
        isLoadingPostUser: false,
        postUserSuccess: false,
        postUserError: action.payload.error,
      };

    case types.GET_USER:
      return {
        ...state,
        isLoadingGetUser: true,
        userData: undefined,
        getUserError: undefined,
      };

    case types.GET_USER_SUCCESS:
      return {
        ...state,
        isLoadingGetUser: false,
        userData: action.payload,
        getUserError: undefined,
      };

    case types.GET_USER_ERROR:
      return {
        ...state,
        isLoadingGetUser: false,
        userData: undefined,
        getUserError: action.payload.error,
      };

    case types.CLEAN_USERS:
      return initialState;

    default:
      return state;
  }
};

export default userListReducer;
