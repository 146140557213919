import apiResource from "..";

const usersUrl = (body) => {
  let url = "backoffice/users?";
  if (body.page) {
    url += `page=${body.page}`;
  }
  if (body.paginate) {
    url += `&paginate=${body.paginate}`;
  }
  if (body.name) {
    url += `&name=${body.name}`;
  }

  return url;
};

const usersService = {
  getList: async ({ page, paginate, name }) => {
    const response = await apiResource.fetch(
      "GET",
      usersUrl({ page, paginate, name })
    );
    return response;
  },
  postUser: async (body) => {
    const response = await apiResource.fetch("POST", "backoffice/users", body);
    return response;
  },
  postRole: async (body) => {
    const response = await apiResource.fetch("POST", "backoffice/roles", body);
    return response;
  },
  getUser: async (id) => {
    const response = await apiResource.fetch("GET", `backoffice/users/${id}`);
    return response;
  },
  getUsersByRoleID: async ({ role_id, page, paginate }) => {
    const response = await apiResource.fetch(
      "GET",
      `backoffice/roles/${role_id}/users?page=${page}&paginate=${paginate}`
    );
    return response;
  },
  getPriviByRoleID: async (role_id) => {
    const response = await apiResource.fetch(
      "GET",
      `backoffice/roles/${role_id}/privileges`
    );
    return response;
  },
  postPrivileges: async (data) => {
    const { body, role_id } = data;
    const response = await apiResource.fetch(
      "POST",
      `backoffice/roles/${role_id}/privileges`,
      body
    );
    return response;
  },
  changeRoleStatus: async (role_id) => {
    const response = await apiResource.fetch(
      "PATCH",
      `backoffice/roles/${role_id}/change-active`
    );
    return response;
  },
  changeUserStatus: async (user_id) => {
    const response = await apiResource.fetch(
      "PATCH",
      `backoffice/users/${user_id}/change-active`
    );
    return response;
  },
};

export default usersService;
