import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import BusinessTypeDetails from "../modules/businessTypes/details/index";
import BusinessConfiguration from "../modules/businessTypes/configuration/index";
import BusinessTypeNew from "../modules/businessTypes/new/index";
import BusinessTypeList from "../modules/businessTypes/list/index";
import EstablishmentsList from "../modules/establishments/list/index";
import LoginComponent from "../modules/Login/index";
import PendingApplications from "../modules/applications/pending";
import DeclinedApplications from "../modules/applications/declined";
import UpdateApplications from "../modules/applications/update";
import Error404Component from "../modules/404";
import SalesReport from "../modules/salesReport/list";
import SalesReportDetail from "../modules/salesReport/detail";
import GiftCardPaymentList from "../modules/giftCardPayments/list/index";
import GiftCardPaymentEstablishmentDetail from "../modules/giftCardPayments/establishmentDetail/index";
import GiftCardPaymentPayments from "../modules/giftCardPayments/paidDetail/index";
import EstablishmentsDetails from "../modules/establishments/details";
import ApplicationsPendingDetails from "../modules/applications/details";
import UserList from "../modules/users/userList";
import User from "../modules/users/userForm";
import RoleList from "../modules/users/rolesList";
import Role from "../modules/users/role";
import UserByRoleList from "../modules/users/userByRole";
import Permissions from "../modules/users/permissions";
import ProtectedWrapper from "./protectedWrapper";
import PublicWrapper from "./publicWrapper";
import CreatePassword from "../modules/createPassword";
import RecoverPassword from "../modules/recoverPassword";
import ChangePassowrd from "../modules/changePassword";

function RoutesComponent() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={(
            <PublicWrapper>
              <LoginComponent />
            </PublicWrapper>
          )}
        />
        <Route
          path="/create-password"
          element={(
            <PublicWrapper>
              <CreatePassword />
            </PublicWrapper>
          )}
        />
        <Route
          path="/change-password"
          element={(
            <ProtectedWrapper>
              <ChangePassowrd />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/recover-password"
          element={(
            <PublicWrapper>
              <RecoverPassword />
            </PublicWrapper>
          )}
        />
        <Route path="*" element={<Error404Component />} />
        <Route
          path="/dashboard/businessTypes/list"
          element={(
            <ProtectedWrapper>
              <BusinessTypeList />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/businessTypes/new"
          element={(
            <ProtectedWrapper>
              <BusinessTypeNew />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/businessTypes/edit/:id"
          element={(
            <ProtectedWrapper>
              <BusinessTypeNew />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/businessTypes/details/:id"
          element={(
            <ProtectedWrapper>
              <BusinessTypeDetails />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/establishments/list"
          element={(
            <ProtectedWrapper>
              <EstablishmentsList />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/establishments/details/:code"
          element={(
            <ProtectedWrapper>
              <EstablishmentsDetails />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/salesReport/list"
          element={(
            <ProtectedWrapper>
              <SalesReport />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/salesReport/detail"
          element={(
            <ProtectedWrapper>
              <SalesReportDetail />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/applications/pending"
          element={(
            <ProtectedWrapper>
              <PendingApplications />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/applications/detail/:id"
          element={(
            <ProtectedWrapper>
              <ApplicationsPendingDetails />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/applications/declined/detail/:id"
          element={(
            <ProtectedWrapper>
              <ApplicationsPendingDetails />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/applications/update/detail/:id"
          element={(
            <ProtectedWrapper>
              <ApplicationsPendingDetails />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/applications/declined"
          element={(
            <ProtectedWrapper>
              <DeclinedApplications />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/applications/update"
          element={(
            <ProtectedWrapper>
              <UpdateApplications />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/configuration"
          element={(
            <ProtectedWrapper>
              <BusinessConfiguration />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/giftCardPayments/list"
          element={(
            <ProtectedWrapper>
              <GiftCardPaymentList />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/giftCardPayments/establishment"
          element={(
            <ProtectedWrapper>
              <GiftCardPaymentEstablishmentDetail />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/giftCardPayments/payments"
          element={(
            <ProtectedWrapper>
              <GiftCardPaymentPayments />
            </ProtectedWrapper>
          )}
        />

        <Route
          path="/dashboard/users"
          element={(
            <ProtectedWrapper>
              <UserList />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/user/new"
          element={(
            <ProtectedWrapper>
              <User />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/user/detail/:user_id"
          element={(
            <ProtectedWrapper>
              <User />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/roles"
          element={(
            <ProtectedWrapper>
              <RoleList />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/role/new"
          element={(
            <ProtectedWrapper>
              <Role />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/role/:role_id"
          element={(
            <ProtectedWrapper>
              <UserByRoleList />
            </ProtectedWrapper>
          )}
        />
        <Route
          path="/dashboard/permissions"
          element={(
            <ProtectedWrapper>
              <Permissions />
            </ProtectedWrapper>
          )}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default RoutesComponent;
