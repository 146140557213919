import { call, takeEvery, put } from "redux-saga/effects";
import * as types from "../types";
import applicationsService from "../../../../apis/services/applicationsService";

function* getPendingListHandler({ payload }) {
  try {
    const result = yield call(
      applicationsService.getApplicationsPendgin,
      payload
    );

    const data = yield call(applicationsService.getPendingAmounts, payload);

    yield put({
      type: types.GET_APPLICATIONS_PENDING_SUCCESS,
      payload: { data: result.data, meta: result.meta, amounts: data },
    });
  } catch (error) {
    yield put({
      type: types.GET_APPLICATIONS_PENDING_ERROR,
      paload: { error },
    });
  }
}

function* getPendingAmountsHandler({ payload }) {
  try {
    const data = yield call(applicationsService.getPendingAmounts, payload);
    yield put({ type: types.GET_PENDING_AMOUNTS_SUCCESS, payload: { data } });
  } catch (error) {
    yield put({
      type: types.GET_PENDING_AMOUNTS_ERROR,
      payload: { error },
    });
  }
}

function* getPendingDetailsHandler({ payload }) {
  try {
    const data = yield call(applicationsService.getDetails, payload);

    yield put({
      type: types.GET_APPLICATIONS_PENDING_DETAIL_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      payload: { error },
      type: types.GET_APPLICATIONS_PENDING_DETAIL_ERROR,
    });
  }
}

function* getDeclinedListHandler({ payload }) {
  try {
    const result = yield call(applicationsService.getDeclinedList, payload);
    yield put({
      type: types.GET_APPLICATIONS_DECLINED_SUCCESS,
      payload: { data: result.data.data, meta: result.meta },
    });
  } catch (error) {
    yield put({
      type: types.GET_APPLICATIONS_DECLINED_ERROR,
      payload: { error },
    });
  }
}
function* getUpdateListHandler({ payload }) {
  try {
    const result = yield call(applicationsService.getUpdateList, payload);
    yield put({
      type: types.GET_APPLICATIONS_UPDATE_SUCCESS,
      payload: { data: result.data.data, meta: result.meta },
    });
  } catch (error) {
    yield put({
      type: types.GET_APPLICATIONS_UPDATE_ERROR,
      payload: { error },
    });
  }
}
function* putApplicationHandler({ payload }) {
  try {
    yield call(applicationsService.putApplication, payload);
    yield put({
      type: types.PUT_APPLICATION_SUCCESS,
      payload: {},
    });
  } catch (error) {
    yield put({
      type: types.PUT_APPLICATION_ERROR,
      payload: { error },
    });
  }
}

export default function* root() {
  yield takeEvery(types.GET_APPLICATIONS_PENDING, getPendingListHandler);
  yield takeEvery(types.GET_PENDING_AMOUNTS, getPendingAmountsHandler);
  yield takeEvery(
    types.GET_APPLICATIONS_PENDING_DETAIL,
    getPendingDetailsHandler
  );
  yield takeEvery(types.GET_APPLICATIONS_DECLINED, getDeclinedListHandler);
  yield takeEvery(types.GET_APPLICATIONS_UPDATE, getUpdateListHandler);
  yield takeEvery(types.PUT_APPLICATION, putApplicationHandler);
}
