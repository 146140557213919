import { connect } from "react-redux";
import React, { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Container,
  ContentDisplay,
  RowSection,
  GoBackText,
  InputTitle,
  Form,
  SelectMultiple,
  IconInput,
  NameInput,
  LogoName,
  Row,
  LabelText,
  AmountInput,
  StandarInput,
  Header,
  ButtonContainer,
  CenterRow,
  AmountTitle,
  Line,
  GreenText,
  ColumnInputs,
  CenterItems,
  Text,
  ColumnType,
  PriceTitle,
  Select,
  AddButton,
  AmountText,
  PricesContainer,
  ErrorLabel,
  InputContainer,
  Label,
} from "./styles";
import LoggedLayout from "../../../ui/components/layout/index";
import { LeftArrowIcon, AddLogoIcon, CloseIcon } from "../../../assets/icons";

import {
  cleanState,
  getBusinessTypeDetails,
  getServicesList,
  postBusinessType,
  putBusinessType,
} from "../store/actions";
import openNotification from "../../components/notification";
import LoadingComponent from "../../components/spinner";

function BusinessTypeNew({
  isLoadingAdding,
  addedSuccess,
  addedError,
  postType,
  getServices,

  isLoadingServices,
  servicesList,
  servicesError,

  isLoadingDetails,
  detailsData,
  detailsError,
  getDetails,
  putType,

  clean,
  newID,
}) {
  const { id } = useParams();

  useEffect(() => {
    if (id && detailsError) {
      openNotification("Error", "Error getting details, try again", "error");
    }
  }, [detailsError, id]);
  const navigate = useNavigate();
  const [logo, setLogo] = useState(undefined);
  const [inactiveLogo, setInactiveLogo] = useState(undefined);
  const [amounts, setAmounts] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      status: "ON",
    },
  });

  const reset = useCallback(() => {
    setInputValue("");
    setAmounts([]);
    setLogo(undefined);
    const fields = ["active_logo", "code", "status", "moneyValues", "services"];
    fields.forEach((ele) => setValue(ele, undefined));
  }, [setValue]);

  useEffect(() => {
    if (id) {
      getDetails(id);
    } else {
      clean();
      reset();
    }
  }, [getDetails, id, reset, clean]);

  useEffect(() => {
    if (id && detailsData) {
      const currentAmounts = detailsData.amount_availables.map(
        ({ amount }) => amount
      );

      setAmounts(currentAmounts);
      const fieldsFilled = {
        name: detailsData.name,
        code: detailsData.code,
        status: detailsData.status,
        services: detailsData.service_availables.map((ele) => ele.service_id),
        moneyValues: currentAmounts,
      };

      const keys = Object.keys(fieldsFilled);

      keys.forEach((key) => {
        setValue(key, fieldsFilled[key], { shouldValidate: true });
      });
    }
  }, [detailsData, reset, setValue, id]);

  useEffect(() => {
    register("active_logo", { required: !id ? "Campo Requerido" : false });
    register("inactive_logo", { required: !id ? "Campo Requerido" : false });
  }, [register, id]);

  useEffect(() => {
    getServices();
  }, [getServices]);

  useEffect(() => {
    if (servicesError) {
      openNotification(
        "Error",
        "Error obteniendo servicios, intente nuevamente",
        "error"
      );
    }
  }, [servicesError]);

  const showErrors = useCallback(
    (errorsObj) => {
      const fields = Object.keys(errorsObj);
      const messages = Object.values(errorsObj).toString();
      fields.map((element, index) =>
        setError(element, {
          type: "manual",
          message: messages.split(",")[index],
        })
      );
    },
    [setError]
  );

  useEffect(() => {
    if (addedError) {
      if (addedError.response.data.errors) {
        showErrors(addedError.response.data.errors);
      }
      openNotification(
        "Error",
        "Error agregando tipo de negocio, intente de nuevo",
        "error"
      );
    }
    if (addedSuccess) {
      openNotification(
        "¡Éxito!",
        id
          ? "Tipo de negocio actualizado exitosamente"
          : "Tipo de negocio agregado exitosamente",
        "success"
      );
      setLogo(undefined);
      setValue("name", null, { shouldValidate: false });
      setValue("code", null, { shouldValidate: false });
      setValue("services", null, { shouldValidate: false });
      setAmounts([]);
      navigate(`/dashboard/businessTypes/details/${newID}`);
      reset();
    }

    return clean();
  }, [
    addedError,
    addedSuccess,
    clean,
    navigate,
    newID,
    reset,
    setValue,
    showErrors,
    id,
  ]);

  const onSubmit = (data) => {
    if (id) {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("code", data.code);
      logo && formData.append("icon_active", logo);
      inactiveLogo && formData.append("icon_inactive", inactiveLogo);
      formData.append("status", data.status);
      formData.append("id", id);

      data?.services?.forEach((ele, idx) =>
        formData.append(`services[${idx}]`, ele)
      );

      data?.moneyValues?.forEach((ele, idx) =>
        formData.append(`amounts[${idx}]`, ele)
      );

      putType(formData);
    } else {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("code", data.code);
      formData.append("icon_active", logo);
      formData.append("icon_inactive", inactiveLogo);
      formData.append("status", data.status);

      data?.services?.forEach((ele, idx) =>
        formData.append(`services[${idx}]`, ele)
      );

      data?.moneyValues?.forEach((ele, idx) =>
        formData.append(`amounts[${idx}]`, ele)
      );

      postType(formData);
    }
  };

  const addPrice = () => {
    setAmounts([...amounts, inputValue]);
    setValue("moneyValues", [...amounts, inputValue], { shouldValidate: true });
    setInputValue("");
  };

  const handleLogoChange = (event) => {
    setValue("active_logo", event.target.files?.[0].name, {
      shouldValidate: true,
    });
    setLogo(event.target.files?.[0]);
  };
  const handleInactiveLogoChange = (event) => {
    setValue("inactive_logo", event.target.files?.[0].name, {
      shouldValidate: true,
    });
    setInactiveLogo(event.target.files?.[0]);
  };

  return (
    <LoggedLayout>
      <Container>
        {id && isLoadingDetails ? (
          <LoadingComponent />
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContentDisplay>
              <Header>
                <Link to="/dashboard/businessTypes/list">
                  <LeftArrowIcon />
                </Link>
                <GoBackText>Tipos de negocios</GoBackText>
              </Header>
              <RowSection>
                <ColumnInputs>
                  <InputContainer>
                    <InputTitle>Icono del tipo de negocio</InputTitle>
                    <CenterRow>
                      <IconInput name="businessLogo" type="button">
                        {logo ? (
                          <Row>
                            <LogoName>{logo?.name}</LogoName>
                            <img
                              src={window.URL.createObjectURL(logo)}
                              alt={logo?.name}
                            />
                            <button
                              type="button"
                              onClick={() => {
                                setLogo(undefined);
                                setValue("active_logo", null);
                              }}
                            >
                              <CloseIcon />
                            </button>
                          </Row>
                        ) : (
                          <LabelText htmlFor="btn-file">
                            No has seleccionado ningun archivo
                          </LabelText>
                        )}
                        <input
                          type="file"
                          id="btn-file"
                          onChange={(event) => handleLogoChange(event)}
                        />
                      </IconInput>
                      <CenterItems>
                        <Label htmlFor="btn-file">
                          <AddLogoIcon />
                        </Label>
                      </CenterItems>
                    </CenterRow>

                    {errors?.active_logo && (
                      <ErrorLabel>{errors.active_logo.message}</ErrorLabel>
                    )}
                    <Text>El máximo tamaño permitido del archivo es 512kb</Text>
                  </InputContainer>
                  <InputContainer>
                    <InputTitle>Icono Inactivo del tipo de negocio</InputTitle>
                    <CenterRow>
                      <IconInput name="businessLogo" type="button">
                        {inactiveLogo ? (
                          <Row>
                            <LogoName>{inactiveLogo?.name}</LogoName>
                            <img
                              src={window.URL.createObjectURL(inactiveLogo)}
                              alt={inactiveLogo?.name}
                            />
                            <button
                              type="button"
                              onClick={() => {
                                setInactiveLogo(undefined);
                                setValue("inactive_logo", null);
                              }}
                            >
                              <CloseIcon />
                            </button>
                          </Row>
                        ) : (
                          <LabelText htmlFor="btn-file2">
                            No has seleccionado ningun archivo
                          </LabelText>
                        )}
                        <input
                          type="file"
                          id="btn-file2"
                          onChange={(event) => handleInactiveLogoChange(event)}
                        />
                      </IconInput>
                      <CenterItems>
                        <Label htmlFor="btn-file2">
                          <AddLogoIcon />
                        </Label>
                      </CenterItems>
                    </CenterRow>

                    {errors?.inactive_logo && (
                      <ErrorLabel>{errors.inactive_logo.message}</ErrorLabel>
                    )}
                    <Text>El máximo tamaño permitido del archivo es 512kb</Text>
                  </InputContainer>
                  <InputContainer>
                    <InputTitle>Código</InputTitle>
                    <StandarInput
                      placeholder="Añadir codigo"
                      inputMode="decimal"
                      type="numeric"
                      {...register("code", { required: "Campo requerido" })}
                    />
                    {errors?.code && (
                      <ErrorLabel>{errors.code.message}</ErrorLabel>
                    )}
                  </InputContainer>
                </ColumnInputs>
                <ColumnInputs>
                  <InputContainer>
                    <InputTitle>Nombre del tipo negocio</InputTitle>
                    <NameInput
                      placeholder="Añadir nombre"
                      type="text"
                      {...register("name", { required: "Campo requerido" })}
                    />
                    {errors?.name && (
                      <ErrorLabel>{errors.name.message}</ErrorLabel>
                    )}
                  </InputContainer>
                  <InputContainer marginTop="25px">
                    <InputTitle>Estatus</InputTitle>
                    <Select
                      bordered={false}
                      value={watch("status")}
                      getPopupContainer={(trigger) => trigger}
                      {...register("status", { required: "Campo requerido" })}
                      onChange={(value) =>
                        setValue("status", value, { shouldValidate: true })
                      }
                    >
                      <Select.Option value="ON">Activo</Select.Option>
                      <Select.Option value="OFF">Inactivo</Select.Option>
                    </Select>
                  </InputContainer>
                </ColumnInputs>
              </RowSection>
              <InputContainer>
                <InputTitle>Servicios</InputTitle>
                <SelectMultiple
                  loading={isLoadingServices}
                  mode="multiple"
                  placeholder="Selecciona los servicios"
                  bordered={false}
                  value={watch("services")}
                  {...register("services", { required: "Campo requrido" })}
                  onChange={(value) =>
                    value &&
                    setValue("services", value, {
                      shouldValidate: true,
                    })
                  }
                >
                  {servicesList?.map((ele) => (
                    <SelectMultiple.Option value={ele.id}>
                      {ele.business_type_name}
                    </SelectMultiple.Option>
                  ))}
                </SelectMultiple>
                {errors?.services && (
                  <ErrorLabel>{errors.services.message}</ErrorLabel>
                )}
              </InputContainer>
              <PricesContainer>
                <InputContainer>
                  <PriceTitle>Precios GiftCards</PriceTitle>
                  {amounts?.map((element, key) => (
                    <ColumnType key={key.toString()}>
                      <AmountText> {`$ ${element}`}</AmountText>
                      <Line />
                    </ColumnType>
                  ))}
                </InputContainer>
                <AmountTitle>Monto</AmountTitle>
                <CenterRow>
                  <InputContainer>
                    <AmountInput
                      placeholder="Añadir monto"
                      inputMode="decimal"
                      {...register("moneyValues", {
                        required: "Campo requerido",
                      })}
                      onChange={(event) => setInputValue(event.target.value)}
                      value={inputValue}
                    />
                    {errors?.moneyValues && (
                      <ErrorLabel>{errors.moneyValues.message}</ErrorLabel>
                    )}
                  </InputContainer>
                  <CenterItems>
                    <GreenText
                      type="button"
                      onClick={() => inputValue && addPrice()}
                    >
                      Añadir
                    </GreenText>
                  </CenterItems>
                </CenterRow>
              </PricesContainer>
              <ButtonContainer>
                <AddButton
                  htmlType="submit"
                  disabled={isLoadingAdding}
                  loading={isLoadingAdding}
                >
                  {id ? "Editar Negocio" : "Añadir negocio"}
                </AddButton>
              </ButtonContainer>
            </ContentDisplay>
          </Form>
        )}
      </Container>
    </LoggedLayout>
  );
}
const mapStateToProps = (state) => ({
  isLoadingAdding: state.newBusinessType.isLoadingAdding,
  addedSuccess: state.newBusinessType.addedSuccess,
  addedError: state.newBusinessType.addedError,
  newID: state.newBusinessType.newID,

  isLoadingServices: state.newBusinessType.isLoadingServices,
  servicesList: state.newBusinessType.servicesList,
  servicesError: state.newBusinessType.servicesError,

  isLoadingDetails: state.businessTypesDetails.isLoadingDetails,
  detailsData: state.businessTypesDetails.detailsData,
  detailsError: state.businessTypesDetails.detailsError,
});
const mapDispatchToProps = (dispatch) => ({
  postType: (body) => dispatch(postBusinessType(body)),
  putType: (body) => dispatch(putBusinessType(body)),
  getServices: () => dispatch(getServicesList()),
  getDetails: (id) => dispatch(getBusinessTypeDetails(id)),
  clean: () => dispatch(cleanState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessTypeNew);
