import { Select as SelectBase } from "antd";

import styled from "styled-components";

export const SecondContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 40px 0;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  color: #35403b;
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
`;

export const SubTitle = styled.h1`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #35403b;
`;

export const AddCoinText = styled.span`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #5ecf59;
  margin-top: 12px;
  cursor: pointer;
`;

export const OptionCoinText = styled.span`
  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #35403b;
`;

export const LabelText = styled.label`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
  position: relative;
  top: 8px;
  left: 8px;
  z-index: 1;
  width: fit-content;
  padding: 0 4px;
  margin-top: -8px;
  background: #fdfdfd;
`;

export const Input = styled.input`
  width: 180px;
  height: 48px;
  padding-left: 16px;
  background: #fafafa;
  border: 1px solid #35403b;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 24px;
  font-size: 16px;
  line-height: 24px;
`;

export const InfoContainer = styled.div`
  display: flex;
  width: 193px;
  height: 48px;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid #b1f5f0;
`;

export const IconContainer = styled.div`
  width: auto;
  height: auto;
  cursor: pointer;
`;

export const TextInfo = styled.p`
  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
  margin-left: 9.33px;
`;

export const SelectsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
`;

export const SelectCountry = styled(SelectBase)`
  margin: 0 24px 0 0;
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    width: 88px;
    height: 48px;
    border: 1px solid #6a736f;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 16px;
  }
  &.ant-select .ant-select-arrow {
    color: #323232;
    font-size: 15px;
    right: 20px;
  }
`;

export const SelectCoin = styled(SelectBase)`
  margin: 0 21px 0 0;
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    width: 318px;
    height: 48px;
    border: 1px solid #6a736f;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.ant-select .ant-select-arrow {
    color: #323232;
    font-size: 15px;
    right: 20px;
  }
`;
