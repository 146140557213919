import * as types from "../types";

export const setUser = (userData) => ({
  payload: { userData },
  type: types.SET_USER,
});
export const cleanUser = () => ({
  payload: { userData: undefined },
  type: types.CLEAN_USER,
});
