import React from "react";
import { Container, Question, SwitchContainer, Option, Switch } from "./styles";

const Input = ({ question }) => (
  <Container>
    <Question>{question}</Question>
    <SwitchContainer>
      <Option>No</Option>
      <Switch disabled defaultChecked />
      <Option active>Yes</Option>
    </SwitchContainer>
  </Container>
);

export default Input;
