import { Button as ButtonBase } from "antd";
import styled from "styled-components";

export const Container = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Body = styled.label`
  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #35403b;
  margin: 24px 0px;
  text-align: center;
`;

export const Button = styled(ButtonBase)`
  height: 48px;
  border-radius: 8px;
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #fcfdfd;
  background: linear-gradient(45deg, #01c5b7 0%, #5ecf59 100%);
  :hover,
  :focus,
  :active {
    color: #fcfdfd;
    background: linear-gradient(45deg, #01c5b7 0%, #5ecf59 100%);
  }
  border: none;
  margin-top: 40px;

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    background: #d2d9d6;
    color: #9da6a2;
  }
`;
export const Title = styled.label`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #35403b;
  margin-top: 24px;
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InputLabel = styled.label`
  width: fit-content;
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
  background: #fdfdfd;
  position: relative;
  top: 8px;
  margin-left: 16px;
  margin-top: -8px;
  padding: 0 4px;
  z-index: 1;
`;

export const Input = styled.input`
  width: 100%;
  height: 48px;
  background: #fdfdfd;
  color: #111415;
  border: 1px solid #9da6a2;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0px 0px 0px 16px;
  outline: none;
  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;

export const CancelButton = styled(Button)`
  margin-top: 8px;
  background: none;
  border: 1px solid grey;
  color: grey;

  :hover,
  :active,
  :focus {
    background: none;
    border: 1px solid grey;
    color: grey;
  }
`;

export const ErrorLabel = styled.label`
  font-family: "Mulish";
  font-style: italic;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #f35726;
`;
