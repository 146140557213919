import React, { useEffect } from "react";

import {
  Container,
  HeaderBack,
  HeaderBackContent,
  HeaderText,
  BusinessName,
  Box,
  SubTitle,
  InputContainer,
  TimeTitle,
  DeclarationTitle,
  QuesionsContainer,
  ImageContainer,
  FilesBox,
  ButtonsContainer,
  CancelBtn,
  ContinueBtn,
  Modal,
  Table,
} from "./styles";

import { ArrowIcon } from "../../../assets/icons";
import Input from "./components/Input";
import InputServices from "./components/InputServices";
import Question from "./components/Questions";
import Images from "./components/Images";
import Files from "./components/Files";
import ModalContent from "./components/Modal";
import {
  handleModal,
  putBusinessApplication,
  cleanPutReducer,
} from "../../applications/store/actions/index";
import { connect } from "react-redux";
import openNotification from "../notification/index";
import { useNavigate } from "react-router-dom";

const BusinessView = ({
  setShowInfo,
  title = "",
  icon,
  showButtons = false,
  showQuestions = true,
  showPaymentInfo = true,
  dataToShow,
  isLoadingPut,
  putSuccess,
  putError,
  putApplication,
  handleModalFn,
  isModalOpen,
  clean,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (putError) {
      openNotification("Error", "Error sending request, try again", "error");
    }
    return clean();
  }, [putError, clean]);

  useEffect(() => {
    if (putSuccess) {
      // navigate(`/dashboard/establishments/details/${dataToShow.id}`);
      navigate(-1);
      openNotification(
        "¡Éxito!",
        "Se realizó la operación exitosamente",
        "success"
      );
    }
    return clean();
  }, [putSuccess, navigate, clean]);

  const approveBusiness = (comment) => {
    const body = {
      status: comment ? "DECLINED" : "APPROVED",
      id: dataToShow.id,
      comment,
    };
    putApplication(body);
  };

  return (
    <Container>
      <Modal
        visible={isModalOpen}
        footer={null}
        destroyOnClose
        centered
        onCancel={() => !isLoadingPut && handleModalFn(false)}
      >
        <ModalContent
          loading={isLoadingPut}
          declineBusiness={(comment) => approveBusiness(comment)}
          setIsModalVisible={(value) => handleModalFn(value)}
        />
      </Modal>
      <HeaderBack onClick={() => setShowInfo(false)}>
        <ArrowIcon />
        <HeaderBackContent>
          {icon}
          <HeaderText>{title}</HeaderText>
        </HeaderBackContent>
      </HeaderBack>
      <BusinessName>{dataToShow?.business?.name}</BusinessName>
      <Box>
        <SubTitle>Datos de usuario</SubTitle>
        <InputContainer>
          <Input label="Correo electrónico" value={dataToShow?.user?.email} />
          <Input
            label="Nombres y Apellidos"
            value={dataToShow?.user?.full_name}
          />
          <Input label="Celular" value={dataToShow?.user?.phone} />
        </InputContainer>
      </Box>
      <Box>
        <SubTitle>Datos Empresariales</SubTitle>
        <InputContainer>
          <Input
            width="20%"
            label="Tipo de negocio"
            value={dataToShow?.business?.type}
          />
          <Input
            width="20%"
            label="País"
            value={dataToShow?.business?.country}
          />
          <Input
            width="20%"
            label="Estado"
            value={dataToShow?.business?.state}
          />
          <Input
            width="40%"
            label="Ciudad"
            value={dataToShow?.business?.city}
          />
        </InputContainer>
        <InputContainer>
          <Input
            width="20%"
            label="Código Postal"
            value={dataToShow?.business?.zip}
          />
          <Input
            width="20%"
            label="Teléfono del negocio"
            value={dataToShow?.business?.phone}
          />
          <Input
            width="60%"
            label="Correo del negocio"
            value={dataToShow?.business?.email}
          />
        </InputContainer>
        <InputContainer>
          <Input
            width="20%"
            label="Tipo de Identificación"
            value={dataToShow?.business?.document_type}
          />
          <Input
            width="20%"
            label="Número de Id"
            value={dataToShow?.business?.document_number}
          />
          <Input
            width="20%"
            label="Fecha de Expiración "
            value={dataToShow?.business?.document_expiration}
          />
          <InputServices
            width="40%"
            services={dataToShow?.business?.services}
            label="Servicios disponibles"
          />
        </InputContainer>
        <InputContainer>
          <Input
            width="100%"
            label="Dirección"
            value={dataToShow?.business?.address}
          />
        </InputContainer>
        <TimeTitle>Horario</TimeTitle>
        {dataToShow?.business?.hours?.map(
          ({ init_day, end_day, start_time, end_time }, index) => (
            <InputContainer key={index.toString()}>
              <Input maxWidth="168px" label="De" value={init_day} />
              <Input maxWidth="168px" label="A" value={end_day} />
              <Input
                maxWidth="168px"
                label="Hora"
                value={`${start_time} - ${end_time}`}
              />
            </InputContainer>
          )
        )}
        <InputContainer>
          <Input
            width="100%"
            label="Descripción "
            value={dataToShow?.business?.description}
          />
        </InputContainer>
      </Box>
      <ImageContainer>
        <Images
          logo={dataToShow?.business?.logo}
          pictures={dataToShow?.business?.images}
        />
      </ImageContainer>
      {showPaymentInfo ? (
        <Box>
          <SubTitle>Información de Bancaria</SubTitle>
          <InputContainer>
            <Input
              label="Nombre del dueño de la cuenta"
              value={dataToShow?.business?.bank_data?.name}
            />
            <Input
              label="Moneda"
              value={dataToShow?.business?.bank_data?.currency}
            />
            <Input
              label="Banco"
              value={dataToShow?.business?.bank_data?.bank}
            />
          </InputContainer>
          <InputContainer>
            <Input
              width="32%"
              label="Tipo de Cuenta"
              value={dataToShow?.business?.bank_data?.type}
            />
            <Input
              width="32%"
              label="Número de cuenta"
              value={dataToShow?.business?.bank_data?.account_number}
            />
          </InputContainer>
        </Box>
      ) : null}

      <Box>
        <SubTitle>Adjuntos</SubTitle>
        <FilesBox>
          {dataToShow?.business?.documents?.map((document) => (
            <Files file={document} />
          ))}
        </FilesBox>
      </Box>
      {showQuestions ? (
        <Box>
          <DeclarationTitle>Declaración Jurada</DeclarationTitle>
          <SubTitle>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Odio sed
            convallis habitasse ut.
          </SubTitle>
          <QuesionsContainer>
            {dataToShow?.business?.questions?.map(({ question, id }) => (
              <Question key={id} question={question} />
            ))}
          </QuesionsContainer>
        </Box>
      ) : null}
      {showButtons ? (
        <ButtonsContainer>
          <CancelBtn
            disabled={isLoadingPut}
            onClick={() => handleModalFn(true)}
          >
            Declinar
          </CancelBtn>
          <ContinueBtn
            disabled={isLoadingPut}
            onClick={() => approveBusiness()}
          >
            Aprobar
          </ContinueBtn>
        </ButtonsContainer>
      ) : null}
      {dataToShow?.business?.status === "DECLINED" && (
        <>
          <Box>
            <SubTitle>Declined</SubTitle>
          </Box>
          <Table>
            <thead>
              <tr>
                <th>Motivo</th>
                <th>Usuario</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{dataToShow?.business?.comment}</td>
                <td>Cashship Business</td>
              </tr>
              <tr>
                <td>{dataToShow?.business?.fincimex_message}</td>
                <td>Fincimex</td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  isLoadingPut: state.approveDecline.isLoadingPut,
  putSuccess: state.approveDecline.putSuccess,
  putError: state.approveDecline.putError,
  isModalOpen: state.approveDecline.isModalOpen,
});

const mapDispatchToProps = (dispatch) => ({
  putApplication: (body) => dispatch(putBusinessApplication(body)),
  handleModalFn: (openOrNot) => dispatch(handleModal(openOrNot)),
  clean: (openOrNot) => dispatch(cleanPutReducer(openOrNot)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessView);
