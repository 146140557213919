import styled from "styled-components";

export const Container = styled.div`
  background: #ebfaf9;
`;

export const Header = styled.div`
  padding: 32px 0;
  background: #ebfaf9;
`;
