import apiResources from "..";

const salesURL = (body) => {
  let url = "backoffice/sales?";

  if (body.name) {
    url += `name=${body.name}&`;
  }
  if (body.from) {
    url += `&from=${body.from}`;
  }
  if (body.to) {
    url += `&to=${body.to}`;
  }
  if (body.paginate) {
    url += `&paginate=${body.paginate}`;
  }
  if (body.page) {
    url += `&page=${body.page}`;
  }
  if (body.status) {
    url += `&status=${body.status}`;
  }

  return url;
};

const reportSalesServices = {
  getSalesReports: async (body) => {
    const response = await apiResources.fetch("GET", salesURL(body));
    return response;
  },
  getAmounts: async () => {
    const response = await apiResources.fetch(
      "GET",
      "backoffice/sales/status/count"
    );
    return response.data;
  },
};

export default reportSalesServices;
