import React, { useEffect } from "react";
import LoggedLayout from "../../ui/components/layout";
import { useForm } from "react-hook-form";
import {
  Container,
  Title,
  InputsContainer,
  InputContainer,
  InputLabel,
  ButtonsContainer,
  Input,
  CancelBtn,
  SubmitBtn,
  Content,
  ErrorLabel,
} from "./styles";
import { changePassword, resetPassword } from "./store/actions";
import { connect } from "react-redux";
import openNotification from "../components/notification";

function ChangePassowrd({
  sendData,
  isLoadingChangePassword,
  changePasswordSuccess,
  changePasswordError,
  clean,
  token,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  useEffect(() => {
    if (changePasswordError?.response?.data?.message) {
      openNotification(
        "Error",
        changePasswordError.response.data.message,
        "error"
      );
    } else if (changePasswordError) {
      openNotification(
        "Error",
        "Error en cambio de contraseña, inténten nuevamente",
        "error"
      );
    }
  }, [changePasswordError]);

  useEffect(() => {
    if (changePasswordSuccess) {
      reset();
      window.localStorage.setItem("token", token);
      openNotification(
        "¡Éxito!",
        "Contraseña cambiada con exitosamente",
        "success"
      );
    }
    return () => clean();
  }, [changePasswordSuccess, reset, clean, token]);

  const onSubmit = (data) => {
    sendData(data);
  };

  return (
    <LoggedLayout background="transparent" padding="0">
      <Container onSubmit={handleSubmit(onSubmit)}>
        <Content>
          <Title>Cambiar Contraseña</Title>
          <InputsContainer>
            <InputContainer>
              <InputLabel>Contraseña Actual</InputLabel>
              <Input
                type="password"
                placeholder="Contraseña Actual"
                {...register("current_password", {
                  required: "Campo requerido",
                })}
              />
              {errors.current_password && (
                <ErrorLabel>{errors?.current_password.message}</ErrorLabel>
              )}
            </InputContainer>
            <InputContainer>
              <InputLabel>Nueva Contraseña</InputLabel>
              <Input
                type="password"
                placeholder="Nueva contraseña"
                {...register("password", { required: "Campo requerido" })}
              />
              {errors.password && (
                <ErrorLabel>{errors?.password.message}</ErrorLabel>
              )}
            </InputContainer>
            <InputContainer>
              <InputLabel>Confirmar Contraseña</InputLabel>
              <Input
                type="password"
                placeholder="Confirmar Contraseña"
                {...register("password_confirmation", {
                  validate: (value) =>
                    value === watch("password") || "Contraseña no coincide",
                })}
              />
              {errors.password_confirmation && (
                <ErrorLabel>{errors?.password_confirmation.message}</ErrorLabel>
              )}
            </InputContainer>
          </InputsContainer>
        </Content>
        <ButtonsContainer>
          <CancelBtn type="button">Cancelar</CancelBtn>
          <SubmitBtn htmlType="submit" loading={isLoadingChangePassword}>
            Cambiar Contraseña
          </SubmitBtn>
        </ButtonsContainer>
      </Container>
    </LoggedLayout>
  );
}

const mapStateToProps = (state) => ({
  isLoadingChangePassword: state.password.isLoadingChangePassword,
  changePasswordSuccess: state.password.changePasswordSuccess,
  changePasswordError: state.password.changePasswordError,
  token: state.password.token,
});
const mapDispatchToProps = (dispatch) => ({
  sendData: (body) => {
    dispatch(changePassword(body));
  },
  clean: (body) => {
    dispatch(resetPassword(body));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassowrd);
