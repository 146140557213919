import * as types from "../types";

const initialState = {
  isLoadingPut: false,
  putSuccess: undefined,
  putError: undefined,
  isModalOpen: false,
};

const approveDeclineReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PUT_APPLICATION:
      return {
        ...state,
        isLoadingPut: true,
        putSuccess: undefined,
        putError: undefined,
      };
    case types.PUT_APPLICATION_SUCCESS:
      return {
        isLoadingPut: false,
        putSuccess: true,
        putError: undefined,
        isModalOpen: false,
      };
    case types.PUT_APPLICATION_ERROR:
      return {
        ...state,
        isLoadingPut: false,
        putSuccess: undefined,
        putError: true,
      };
    case types.HANDLE_MODAL:
      return {
        ...state,
        isModalOpen: action.payload,
      };
    case types.CLEAN_PUT:
      return {
        ...state,
        isLoadingPut: false,
        putSuccess: undefined,
        putError: undefined,
      };

    default:
      return state;
  }
};
export default approveDeclineReducer;
