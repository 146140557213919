import * as types from "../types";

const initialState = {
  isLoadingAmounts: false,
  amounts: undefined,
};
const giftsCardsAmountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_GIFT_CARDS_AMOUNTS:
      return {
        ...state,
        isLoadingAmounts: true,
      };

    case types.GET_GIFT_CARDS_AMOUNTS_SUCCESS:
      return {
        isLoadingAmounts: false,
        amounts: action.payload.amounts,
      };
    case types.GET_GIFT_CARDS_AMOUNTS_ERROR:
      return {
        isLoadingAmounts: false,
        amounts: action.payload.amounts,
      };
    default:
      return state;
  }
};

export default giftsCardsAmountsReducer;
