import * as types from "../types";

const initialState = {
  isLoadingUpdateList: false,
  meta: undefined,
  updateList: undefined,
  updateError: undefined,
};

const applicationUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_APPLICATIONS_UPDATE:
      return {
        ...state,
        isLoadingUpdateList: true,
      };
    case types.GET_APPLICATIONS_UPDATE_SUCCESS:
      return {
        isLoadingUpdateList: false,
        meta: action.payload.meta,
        updateList: action.payload.data,
        updateError: undefined,
      };

    case types.GET_APPLICATIONS_UPDATE_ERROR:
      return {
        ...state,
        isLoadingUpdateList: false,
        meta: undefined,
        updateList: undefined,
        updateError: action.payload.error,
      };

    default:
      return state;
  }
};

export default applicationUpdateReducer;
