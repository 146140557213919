import * as types from "../types";

const initialState = {
  isLoading: false,
  loginError: undefined,
  loginSuccess: undefined,
  isSignedIn: false,
  logoutError: undefined,
  logoutSuccess: undefined,
  token: undefined,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_LOGIN:
      return {
        ...state,
        isLoading: true,
      };
    case types.AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        loginError: undefined,
        loginSuccess: true,
        isSignedIn: true,
        isLoading: false,
        token: action.payload.token,
      };
    case types.AUTH_LOGIN_ERROR:
      return {
        loginError: action.payload.error,
        loginSuccess: undefined,
        isSignedIn: false,
        isLoading: false,
        token: undefined,
      };
    case types.CLEAN_TOKEN:
      return initialState;

    default:
      return state;
  }
};

export default loginReducer;
