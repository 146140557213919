import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import LoggedLayout from "../../../ui/components/layout/index";
import openNotification from "../../components/notification";
import PaginationComponent from "../../components/pagination";
import {
  changeUserStatus,
  cleanUsersState,
  getUsersList,
} from "../store/actions";
import {
  Container,
  Table,
  Header,
  Title,
  GlobalText,
  InputContainer,
  InputsContainer,
  Input,
  InputLabel,
  TableContentContainer,
  Button,
  ParentContainer,
  StatusContainer,
  Switch,
  ActionOption,
  Text,
} from "./styles";

function UserList({
  getListData,
  changeStatus,
  isLoadingUserList,
  userListData,
  userListError,
  meta,
  isLoadingChangeStatus,
  statusChangeSuccess,
  statusChangeError,
  clean,
}) {
  const [name, setName] = useState();
  useEffect(() => {
    if (statusChangeSuccess) {
      openNotification(
        "¡Éxito!",
        "Se cambió el estatus exitosamente",
        "success"
      );
    } else if (statusChangeError) {
      openNotification(
        "Error",
        "No se realizó el cambio de estatus, intente nuevamente",
        "error"
      );
    }
  }, [statusChangeError, statusChangeSuccess]);

  useEffect(() => {
    if (userListError) {
      openNotification(
        "Error",
        "Error cargando lista de usuario, intente nuevamente",
        "error"
      );
    }
  }, [userListError]);

  useEffect(() => {
    const body = { page: 1, paginate: 15 };
    getListData(body);
    return () => clean();
  }, [clean, getListData]);

  const handleSearch = () => {
    getListData({ name, page: 1 });
  };

  const getListDataFn = (bdy) => {
    const body = { ...bdy, name };
    getListData(body);
  };

  const navigate = useNavigate();

  const columns = [
    {
      title: "Nombre y Apellido",
      dataIndex: "first_name",
      width: "40%",
      render: (first_name, allData) => (
        <ParentContainer
          onClick={() => navigate(`/dashboard/user/detail/${allData.id}`)}
        >
          <StatusContainer>
            <Text>
              {first_name} {allData.last_name}
            </Text>
          </StatusContainer>
        </ParentContainer>
      ),
    },
    {
      title: "Correo",
      dataIndex: "email",
      width: "20%",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "30%",
      render: (statusData) => (
        <ParentContainer>
          <StatusContainer>
            <GlobalText>{statusData}</GlobalText>
          </StatusContainer>
        </ParentContainer>
      ),
    },
    {
      title: "Rol",
      dataIndex: "role",
      width: "15%",
    },
    {
      title: "Acciones",
      width: "5%",
      dataIndex: "status",
      render: (status, allData) => (
        <ActionOption>
          <Switch
            disabled={isLoadingChangeStatus}
            checked={status === "ACTIVE"}
            onClick={() => changeStatus(allData.id)}
          />
        </ActionOption>
      ),
    },
  ];

  return (
    <LoggedLayout>
      <Container>
        <Header>
          <Title>Usuarios</Title>
          <Button margin="0 8px 0 0">
            <Link to="/dashboard/user/new">Crear</Link>
          </Button>
        </Header>

        <InputsContainer>
          <InputContainer>
            <InputLabel>Buscador</InputLabel>
            <Input
              placeholder="Nombre, Apellido, Correo"
              value={name}
              onChange={(event) => setName(event.currentTarget.value)}
            />
          </InputContainer>
          <Button
            disabled={isLoadingUserList}
            alignSelf="flex-end"
            margin="0 0 0 40px"
            onClick={() => handleSearch()}
          >
            Buscar
          </Button>
        </InputsContainer>

        <TableContentContainer>
          <Table
            bordered
            loading={isLoadingUserList}
            dataSource={userListData}
            columns={columns}
            scroll={{ x: "max-content" }}
            pagination={false}
          />
        </TableContentContainer>
        {meta && <PaginationComponent meta={meta} changePage={getListDataFn} />}
      </Container>
    </LoggedLayout>
  );
}
const mapStateToProps = (state) => ({
  isLoadingUserList: state.users.isLoadingUserList,
  userListData: state.users.userListData,
  userListError: state.users.userListError,
  meta: state.users.meta,

  isLoadingChangeStatus: state.users.isLoadingChangeStatus,
  statusChangeSuccess: state.users.statusChangeSuccess,
  statusChangeError: state.users.statusChangeError,
});
const mapDispatchToProps = (dispatch) => ({
  getListData: (body) => dispatch(getUsersList(body)),
  changeStatus: (id) => dispatch(changeUserStatus(id)),
  clean: () => dispatch(cleanUsersState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
