import * as types from "../types";

export const getList = (body) => ({
  payload: body,
  type: types.GET_LIST,
});

export const changeStatus = (code) => ({
  payload: code,
  type: types.CHANGE_STATUS,
});

export const getDetails = (code) => ({
  payload: code,
  type: types.GET_DETAILS,
});

export const cleanState = () => ({
  payload: {},
  type: types.CLEAN_ESTABLISHMENTS,
});
