import * as types from "../types";

const initialState = {
  isLoadingPaids: false,
  paidsGiftsCards: undefined,
  meta: undefined,
  error: undefined,
};
const giftsCardsPaidsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PAID_GIFT_CARDS:
      return {
        isLoadingPaids: true,
        paidsGiftsCards: undefined,
        meta: undefined,
        error: undefined,
      };

    case types.GET_PAID_GIFT_CARDS_SUCCESS:
      return {
        isLoadingPaids: false,
        paidsGiftsCards: action.payload.data,
        meta: action.payload.meta,
        error: undefined,
      };
    case types.GET_PAID_GIFT_CARDS_ERROR:
      return {
        isLoadingPaids: false,
        paidsGiftsCards: undefined,
        meta: undefined,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default giftsCardsPaidsReducer;
