import * as types from "../types";

export const login = ({ email, password }) => ({
  payload: { email, password },
  type: types.AUTH_LOGIN,
});

export const loginSuccess = () => ({
  payload: {},
  type: types.AUTH_LOGIN_SUCCESS,
});

export const loginError = ({ error }) => ({
  payload: { error },
  type: types.AUTH_LOGIN_ERROR,
});

export const cleanToken = () => ({
  payload: {},
  type: types.CLEAN_TOKEN,
});
