import styled from "styled-components";
import { Button as ButtonBase, Select as SelectBase } from "antd";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 8px;
  font-family: Sora;
  background: transparent;

  @media screen and (max-width: 480px) {
    margin-left: 0;
  }
`;

export const Button = styled(ButtonBase)`
  :hover,
  :active,
  :focus {
    background: ${(props) =>
    (props.disabled
      ? "linear-gradient(45deg, #01C5B7 0%, #5ECF59 100%)"
      : "linear-gradient(45deg, #01C5B7 0%, #5ECF59 100%)")};
    color: #ffffff;
  }

  background: ${(props) =>
    (props.disabled
      ? "linear-gradient(45deg, #01C5B7 0%, #5ECF59 100%)"
      : "linear-gradient(45deg, #01C5B7 0%, #5ECF59 100%)")};
  border-radius: 8px;
  border: none;
  font-family: Sora;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 16px;

  min-width: 117px;
  height: 48px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: ${(props) => (props.margin ? props.margin : "0")};

  ${(props) => props.alignSelf && `align-self: ${props.alignSelf}`};

  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 16px 0 8px 0;
  }
`;

export const CancelButton = styled(ButtonBase)`
  :hover,
  :active,
  :focus {
    background: transparent;
    color: #ffffff;
  }

  background: transparent;
  border-radius: 8px;
  border: none;
  font-family: Sora;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 16px;
  min-width: 105px;
  height: 48px;
  color: #fcfdfd;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;

  margin: ${(props) => (props.margin ? props.margin : "0")};

  ${(props) => props.alignSelf && `align-self: ${props.alignSelf}`};

  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 16px 0 8px 0;
  }
`;

export const ButtonText = styled.span`
  background: linear-gradient(45deg, #01c5b7 0%, #5ecf59 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

export const ButtonsContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 46px;
  margin-right: 110px;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    margin-top: 0;
    margin-right: 0;
  }
`;

export const InputLabel = styled.label`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
  background: ${(props) => (props.disabled ? "#F0F2F1" : "#fdfdfd")};
  padding: 0 4px;
  margin: 0 12px;
  position: relative;
  top: 10px;
  width: max-content;
  z-index: 1;
`;

export const InputContainer = styled.div`
  ${(props) => props.width && `width: ${props.width}`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`};

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  align-items: center;

  svg {
    margin-right: 16px;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin: 0 0 24px 0;
  width: auto;
  flex-wrap: wrap;
  ${(props) => props.gap && `gap: ${props.gap}`};

  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 0;
    margin: 0;
  }
`;

export const Input = styled.input`
  height: 48px;
  border-radius: 4px;
  width: 100%;
  padding: 4px 12px;
  outline: none;

  border: ${(props) => props.border ?? "1px solid #d9d9d9"};

  :hover,
  :focus {
    box-shadow: none;
    border: ${(props) => props.border ?? "1px solid #d9d9d9"};
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    max-width: 100%;
  }
  :disabled {
    background: #f0f2f1;
    cursor: not-allowed;
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 32px;

  @media screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 24px;
  }
`;

export const Title = styled.h1`
  margin: 0 0 6px 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #35403b;
  display: flex;
  align-self: flex-start;
  margin-left: 24px;
`;

export const Select = styled(SelectBase)`
  width: ${(props) => props.width ?? "100%"};
  height: 48px;

  background: ${(props) => (props.disabled ? "#f0f2f1" : "#fdfdfd")};

  border: ${(props) => props.border ?? "1px solid #9da6a2"};
  box-sizing: border-box;
  border-radius: 4px;

  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #111415;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const ErrorLabel = styled.label`
  color: red;
`;
