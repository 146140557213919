import * as types from "../types";

export const getSalesReports = (body) => ({
  type: types.GET_SALES_LIST,
  payload: body,
});
export const cleanState = () => ({
  type: types.CLEAN_STATE,
  payload: {},
});
