import { call, takeEvery, put } from "redux-saga/effects";
import establishmentsService from "../../../../apis/services/establishmentsServices";
import * as types from "../types";

function* getDataHandler({ payload }) {
  try {
    const body = {
      page: payload.page,
      name: payload.name,
      expiration_date_start: payload.expiration_date_start,
      expiration_date_end: payload.expiration_date_end,
      paginate: payload.paginate,
    };

    const data = yield call(establishmentsService.getList, body);
    const amounts = yield call(establishmentsService.getEstaAmounts);

    yield put({
      type: types.GET_LIST_SUCCESS,
      payload: { data },
    });

    yield put({
      type: types.GET_AMOUNTS_SUCCESS,
      payload: { data: amounts },
    });
  } catch (error) {
    yield put({
      type: types.GET_LIST_ERROR,
      payload: { error },
    });
  }
}

function* changeStatusHandler({ payload }) {
  try {
    yield call(establishmentsService.changeStatus, payload);

    yield put({
      type: types.CHANGE_STATUS_SUCCESS,
      payload: { code: payload },
    });
    const amounts = yield call(establishmentsService.getEstaAmounts);
    yield put({
      type: types.GET_AMOUNTS_SUCCESS,
      payload: { data: amounts },
    });
  } catch (error) {
    yield put({
      type: types.CHANGE_STATUS_ERROR,
      payload: { error },
    });
  }
}

function* getDetailsHandler({ payload }) {
  try {
    const data = yield call(establishmentsService.getDetails, payload);
    yield put({ type: types.GET_DETAILS_SUCCESS, payload: { data } });
  } catch (error) {
    yield put({
      type: types.GET_DETAILS_ERROR,
      payload: { error },
    });
  }
}

export default function* root() {
  yield takeEvery(types.GET_LIST, getDataHandler);
  yield takeEvery(types.CHANGE_STATUS, changeStatusHandler);
  yield takeEvery(types.GET_DETAILS, getDetailsHandler);
}
