import styled from "styled-components";
import { Table as TableBase, Switch as SwitchBase } from "antd";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 8px;
  font-family: Sora;
  background: transparent;

  @media screen and (max-width: 480px) {
    margin-left: 0;
  }
`;

export const Title = styled.h1`
  width: auto;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #35403b;
  display: flex;
  align-self: flex-start;
  margin: 0;

  @media screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 0;
    align-self: unset;
    justify-content: center;
    margin-top: 0;
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background: #fcfcfc;
  margin-bottom: 80px;

  svg {
    margin-right: 24px;
  }

  @media screen and (max-width: 480px) {
    gap: 0;
    margin-bottom: 40px;
    ${Title} {
      justify-content: flex-start;
    }
  }
`;

export const Table = styled(TableBase)`
  width: 100%;
  .ant-table-thead > tr > th {
    font-family: Sora;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    color: #9da6a2;
    padding: 0;
    border: none;
    background: none;
    padding-left: 8px;
  }
  .ant-table-tbody > tr > td {
    padding: 8px 0 8px 8px;
  }

  &.ant-table.ant-table-bordered
    > &.ant-table-container
    > &.ant-table-content
    > table
    > tbody
    > tr
    > td:first-child {
    background: red;
    text-align: left;
  }

  .ant-table table,
  .ant-table-thead > tr > th:not(:first-child) {
    text-align: center;
  }
  .ant-pagination-options {
    .ant-select {
      background: #fcfcfc;
      border: 1px solid #c7ced1;
      box-sizing: border-box;
      border-radius: 4px;

      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;

      font-family: Sora;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #333333;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      box-shadow: none;
      border: none;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
    }
  }

  .ant-pagination-options {
    order: -1;
    margin-left: 0;
    margin-right: 16px;
    @media screen and (max-width: 480px) {
      display: inline-block;
    }
  }

  .ant-pagination-item {
    width: 58px;
    height: 56px;
    background: #fcfcfc;
    border: none;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    display: flex;
    a {
      font-family: Sora;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #9daaae;
    }
  }
  .ant-pagination-item-active {
    background: #44b641;
    a {
      color: #fcfcfc;
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    width: 58px;
    height: 56px;
    background: #fcfcfc;
  }
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    outline: 0;
    width: 58px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a.ant-pagination-item-link {
    width: inherit;
  }

  .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #44b641;
  }

  .ant-table-pagination.ant-pagination {
    margin: 61px 0px 0px 0px;
  }

  .ant-table-container table > thead > tr:first-child {
    text-align: end;
    padding-right: 8px;
  }

  td:nth-child(1) {
    display: flex;
    justify-content: flex-start;

    div {
      background: transparent;
    }
  }
  .ant-table-thead > tr > th::before {
    display: none;
  }
  .ant-pagination-prev:focus-visible .ant-pagination-item-link,
  .ant-pagination-next:focus-visible .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: #44b641;
    border: none;
  }
  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
    color: #9daaae;
    border: none;
    cursor: not-allowed;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: none;
  }

  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table {
    border-top: none;
  }
  .ant-table.ant-table-bordered > .ant-table-container {
    border-left: none;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    border-right: none;
  }
  .ant-table-tbody > tr > td {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #35403b;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    border-right: none;
    padding-bottom: 4px;
  }
  .ant-table-pagination.ant-pagination {
    margin: 11px 0px 0px 0px;
    justify-content: center;
  }
  .ant-pagination-options .ant-select {
    width: 107px;
    height: 56px;
    @media screen and (max-width: 480px) {
      margin: 15px 200px 15px 15px;
    }
  }
  .ant-select-arrow,
  .anticon {
    color: #333333;
  }
  .ant-pagination.mini .ant-pagination-prev,
  .ant-pagination.mini .ant-pagination-next {
    @media screen and (max-width: 480px) {
      width: 30px;
    }
  }
  @media screen and (max-width: 480px) {
    td:nth-child(1) {
      display: flex;
      justify-content: flex-start;
      text-align: left;
    }
  }
`;

export const TableContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fcfdfd;
  border-radius: ${(props) => props.borderRadius || "16px"};
  width: 100%;
  margin-top: 40px;
`;

export const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const GlobalText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
`;
export const StatusContainer = styled.div`
  padding: 4px 8px;
  height: fit-content;
  background: #f0f2f1;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  width: fit-content;
`;

export const Switch = styled(SwitchBase)`
  &.ant-switch-checked {
    &.ant-switch {
      border: none;
      background-color: #000000;
    }

    .ant-switch-handle::before {
      background-color: #ffffff;
    }
    :disabled {
      &.ant-switch {
        border: 1px solid #6a736f;
      }
      .ant-switch-handle::before {
        background-color: #6a736f;
      }
    }
  }
  &.ant-switch-checked:focus {
    box-shadow: 0 0 0 2px rgba(68, 182, 65, 0.18);
  }

  &.ant-switch {
    background-color: #fcfdfd;
    border: 1px solid #000000;
    height: 8px;
    width: 15px;
    min-width: 0;
  }
  &.ant-switch-checked .ant-switch-handle {
    height: 5px;
    left: calc(100% - 4px - 2px);
    top: 1.6px;
    width: 5px;
  }
  .ant-switch-handle::before {
    background-color: #000000;
  }
  .ant-switch-handle {
    top: 0.6px;
    left: 0.6px;
    width: 35%;
    height: 80%;
    @media screen and (max-width: 480px) {
      left: 0.8px;
    }
  }
  margin: 0 8px;
`;

export const ActionOption = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;

export const Text = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
`;
