import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const apiResources = {
  fetch: async (method, url, body = null) => {
    const token = window.localStorage.getItem("token");

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    let response;
    switch (method) {
      case "GET":
        response = await axios.get(`${apiUrl}/${url}`, config);
        return response.data;
      case "POST":
        response = await axios.post(`${apiUrl}/${url}`, body, config);
        return response.data;
      case "PUT":
        response = await axios.put(`${apiUrl}/${url}`, body, config);
        return response.data;
      case "PATCH":
        response = await axios.patch(`${apiUrl}/${url}`, body, config);
        return response.data;
      case "DELETE":
        response = await axios.delete(`${apiUrl}/${url}`, config);
        return response.data;
      default:
        response = await axios.get(`${apiUrl}/${url}`, config);
        return response.data;
    }
  },
};

export default apiResources;
