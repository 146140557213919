import { connect } from "react-redux";
import React, { useEffect } from "react";
import { FromDateIcon } from "../../../assets/icons";
import LoggedLayout from "../../../ui/components/layout/index";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import openNotification from "../../components/notification/index";
import {
  getList,
  changeStatus as changeStatusFn,
  getDetails as getDetailsFn,
  cleanState,
} from "../store/actions/index";

import {
  Container,
  HeadSection,
  Title,
  GiftCardData,
  GreenBlock,
  InputSection,
  GeneralText,
  ActionOption,
  Switch,
  NumberGiftCard,
  Input,
  TableContainer,
  Table,
  LeftContainer,
  NameText,
  GlobalText,
  ParentContainer,
  StatusContainer,
  DatePicker,
  InputTitle,
  ColumnContent,
  Button,
} from "./styles";
import PaginationComponent from "../../components/pagination";

function EstablishmentsList({
  getEstablishsments,
  changeStatus,

  isLoadingList,
  listError,
  listData,
  meta,

  /* isLoadingAmounts, */
  amountsData,
  amountsError,

  isLoadingChange,
  changeSuccess,
  changeError,
  cleanStateFn,
}) {
  const { handleSubmit, register, setValue, watch } = useForm();
  const giftCardData = [
    {
      key: 1,
      title: "Establecimientos Activos",
      number: isLoadingChange ? "Loading..." : amountsData?.active,
    },
    {
      key: 2,
      title: "Establecimientos Inactivos",
      number: isLoadingChange ? "Loading..." : amountsData?.inactive,
    },
  ];
  useEffect(() => {
    const body = {
      page: 1,
      expiration_date_start: undefined,
      expiration_date_end: undefined,
      name: undefined,
      paginate: 15,
    };
    getEstablishsments(body);
  }, [getEstablishsments]);

  const handleStatusChange = (code) => {
    changeStatus(code);
  };

  useEffect(() => {
    if (changeSuccess) {
      openNotification("Success", "Status updated successfully", "success");
    }
    if (changeError) {
      openNotification("Error", "Error updating status", "error");
    }
    if (listError) {
      openNotification("Error", "Error getting list", "error");
    }
    if (amountsError) {
      openNotification("Error", "Error getting amounts", "error");
    }
    return cleanStateFn();
  }, [changeError, changeSuccess, listError, amountsError, cleanStateFn]);

  const columns = [
    {
      title: "Establecimiento",
      dataIndex: "name",
      width: "50%",
      key: "name",
      render: (nameData, fullData) => (
        <Link to={`/dashboard/establishments/details/${fullData.code}`}>
          <LeftContainer>
            <NameText>{nameData}</NameText>
          </LeftContainer>
        </Link>
      ),
    },
    {
      title: "Código",
      dataIndex: "code",
      width: "14%",
      key: "code",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "14%",
      key: "status",
      render: (statusData) => (
        <ParentContainer>
          <StatusContainer>
            <GlobalText>{statusData}</GlobalText>
          </StatusContainer>
        </ParentContainer>
      ),
    },
    {
      title: "Ciudad",
      dataIndex: "city",
      width: "14%",
      key: "city",
    },
    {
      title: "Acciones",
      dataIndex: "active",
      width: "8%",
      key: "active",
      render: (status, fullData) => (
        <ActionOption>
          <Switch
            disabled={isLoadingChange}
            defaultChecked={status}
            checked={status}
            onClick={() => handleStatusChange(fullData.code)}
          />
        </ActionOption>
      ),
    },
  ];

  const onSubmit = (body) => {
    getEstablishsments(body);
  };
  const getListFn = (bdy) => {
    const body = {
      ...bdy,
      name: watch("name"),
      from: watch("from"),
      to: watch("to"),
    };
    getEstablishsments(body);
  };

  return (
    <LoggedLayout>
      <Container>
        <HeadSection>
          <Title>Establecimientos</Title>
          <GiftCardData>
            {giftCardData.map(({ title, number, key }) => (
              <GreenBlock key={key}>
                <GeneralText>{title}</GeneralText>
                <NumberGiftCard>{number}</NumberGiftCard>
              </GreenBlock>
            ))}
          </GiftCardData>
        </HeadSection>
        <InputSection onSubmit={handleSubmit(onSubmit)}>
          <ColumnContent>
            <InputTitle>Establecimientos</InputTitle>
            <Input
              placeholder="Ej: Floridita"
              {...register("name")}
              disabled={isLoadingList}
            />
          </ColumnContent>
          <ColumnContent>
            <InputTitle> Desde - Hasta</InputTitle>
            <DatePicker
              disabled={isLoadingList}
              placeholder={["dd/mm/aaaa", "dd/mm/aaaa"]}
              suffixIcon={<FromDateIcon />}
              getPopupContainer={(trigger) => trigger}
              format="yyyy/MM/DD"
              {...register("expiration_date_start")}
              {...register("expiration_date_end")}
              onChange={(_, value) => {
                setValue("expiration_date_start", value[0]);
                setValue("expiration_date_end", value[1]);
              }}
            />
          </ColumnContent>
          <ColumnContent>
            <Button htmlType="submit" disabled={isLoadingList}>
              Buscar
            </Button>
          </ColumnContent>
        </InputSection>
        <TableContainer>
          <Table
            loading={isLoadingList}
            dataSource={listData?.data}
            rowKey="code"
            columns={columns}
            bordered
            rowClassName="editable-row"
            pagination={false}
          />
        </TableContainer>
        {meta && <PaginationComponent meta={meta} changePage={getListFn} />}
      </Container>
    </LoggedLayout>
  );
}

const mapStateToProps = (state) => ({
  isLoadingList: state.establishment.isLoadingList,
  listData: state.establishment.listData,
  meta: state.establishment.meta,
  listError: state.establishment.listError,

  isLoadingAmounts: state.establishment.isLoadingAmounts,
  amountsData: state.establishment.amountsData,
  amountsError: state.establishment.amountsError,

  isLoadingChange: state.establishment.isLoadingChange,
  changeSuccess: state.establishment.changeSuccess,
  changeError: state.establishment.changeError,

  isLoadingDetails: state.establishment.isLoadingDetails,
  detailsSuccess: state.establishment.detailsSuccess,
  detailsError: state.establishment.detailsError,
});
const mapDispatchToProps = (dispatch) => ({
  getEstablishsments: (body) => {
    dispatch(getList(body));
  },
  changeStatus: (code) => {
    dispatch(changeStatusFn(code));
  },
  getDetails: (code) => {
    dispatch(getDetailsFn(code));
  },
  cleanStateFn: () => {
    dispatch(cleanState());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EstablishmentsList);
