import { call, takeEvery, put } from "redux-saga/effects";
import reportSalesServices from "../../../../apis/services/salesReportsServices";

import * as types from "../types";

function* getSalesReportHandler({ payload }) {
  try {
    const body = {
      paginate: payload.paginate,
      page: payload.page,
      name: payload.name,
      from: payload.from,
      to: payload.to,
      status: payload.status,
    };

    const data = yield call(reportSalesServices.getSalesReports, body);
    const amounts = yield call(reportSalesServices.getAmounts);

    yield put({
      type: types.GET_SALES_LIST_SUCCESS,
      payload: { data: data.data.data, meta: data.meta },
    });
    yield put({
      type: types.GET_AMOUTNS_SUCCESS,
      payload: { amounts },
    });
  } catch (error) {
    yield put({ type: types.GET_SALES_LIST_ERROR, payload: { error } });
  }
}
export default function* root() {
  yield takeEvery(types.GET_SALES_LIST, getSalesReportHandler);
}
