import styled from "styled-components";
import {
  Button as ButtonBase,
  Select as SelectBase,
  Checkbox as CheckboxBase,
} from "antd";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 24px;
  font-family: Sora;
  background: #fcfdfd;
  border-radius: 16px;

  @media screen and (max-width: 480px) {
    margin-left: 0;
  }
`;

export const Button = styled(ButtonBase)`
  :hover,
  :active,
  :focus {
    background: linear-gradient(45deg, #01c5b7 0%, #5ecf59 100%);
    color: #ffffff;
  }

  background: linear-gradient(45deg, #01c5b7 0%, #5ecf59 100%);
  border-radius: 8px;
  border: none;
  font-family: Sora;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 16px;

  min-width: 117px;
  height: 48px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => (props.margin ? props.margin : "0")};

  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 16px 0 8px 0;
  }
`;

export const CancelButton = styled(ButtonBase)`
  :hover,
  :active,
  :focus {
    background: transparent;
    color: #ffffff;
  }

  background: transparent;
  border-radius: 8px;
  border: none;
  font-family: Sora;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 16px;
  min-width: 105px;
  height: 48px;
  color: #fcfdfd;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;

  margin: ${(props) => (props.margin ? props.margin : "0")};

  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 16px 0 8px 0;
  }
`;

export const ButtonText = styled.span`
  background: linear-gradient(45deg, #01c5b7 0%, #5ecf59 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

export const ButtonsContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
  margin-right: 110px;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    margin-top: 0;
    margin-right: 0;
  }
`;

export const InputLabel = styled.label`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
  background: #fdfdfd;
  padding: 0 4px;
  margin: 0 12px;
  position: relative;
  top: 10px;
  width: max-content;
  z-index: 1;
`;

export const InputContainer = styled.div`
  ${(props) => props.width && `width: ${props.width}`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`};

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin: 0 0 24px 0;
  width: auto;
  flex-wrap: wrap;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 32px;

  @media screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 24px;
    justify-content: center;
  }
`;

export const Title = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #35403b;
  display: flex;
  align-self: flex-start;
`;

export const Select = styled(SelectBase)`
  width: ${(props) => props.width ?? "100%"};
  height: 48px;
  background: #fdfdfd;
  border: 1px solid #9da6a2;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #111415;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const PermissionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  width: auto;
  margin-left: 24px;
  max-height: 375px;
  overflow-y: scroll;

  @media screen and (max-width: 480px) {
    margin-left: 0;
  }
`;

export const Checkbox = styled(CheckboxBase)`
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;

  margin: 18px 0 8px 24px;
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #64ce5f;
  }
  .ant-checkbox-checked::after {
    border: 1px solid #64ce5f;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #64ce5f;
    border: solid #64ce5f;
  }
`;

export const CheckLabel = styled.label`
  font-family: "Sora";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
`;

export const PermissionSection = styled.div`
  margin-bottom: 16px;
`;
