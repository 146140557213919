import React, { useState } from "react";

import LoggedLayout from "../../../ui/components/layout/index";
import {
  Container,
  FooterGreenBox,
  GreenBoxLabel,
  GreenBoxAmount,
  GreenBoxContainer,
  Title,
  Header,
  Text,
  Content,
  Table,
  FinalLabel,
  Footer,
  ButtonsContainer,
  CancelButton,
  Button,
  TableContainer,
  InlineTextContainer,
  TableBottom,
} from "./styles";
import { useNavigate } from "react-router-dom";

const originData = [];

for (let i = 0; i < 10; i += 1) {
  originData.push({
    key: i.toString(),
    code: `0000${i}`,
    type: "Tipo de GiftCard",
    usedDate: "6/21/19",
    unitaryPrice: "$ 120",
    comission: "$ 120",
    amount: "$ 120",
  });
}

export default function GiftCardPaymentPayments() {
  const [data] = useState(originData);
  const navigate = useNavigate();

  const columnsPendingPayment = [
    {
      title: "Código de GiftCard",
      dataIndex: "code",
      width: "25%",
    },
    {
      title: "Tipo de GiftCard",
      dataIndex: "type",
      width: "15%",
    },
    {
      title: "Fecha Usado",
      dataIndex: "usedDate",
      width: "15%",
    },
    {
      title: "Precio Unitario",
      dataIndex: "unitaryPrice",
      width: "15%",
    },
    {
      title: "Comisión",
      dataIndex: "comission",
      width: "15%",
    },
    {
      title: "Monto a Pagar",
      dataIndex: "amount",
      width: "15%",
    },
  ];

  const tablesData = [
    {
      id: 1,
      name: "Nombre de establecimiento 1 - ",
      ref: " No. Referencia 000012834",
      tableData: data,
      column: columnsPendingPayment,
      paymentMethod: "Cuenta Bancaria",
      accountHolder: "John Doe",
      bank: "Scotianbank",
      accountType: "Corriente",
      currency: "USD",
      accountNumber: "123456787",
    },
    {
      id: 2,
      name: "Nombre de establecimiento 2 - ",
      ref: " No. Referencia 000012835",
      tableData: originData,
      column: columnsPendingPayment,
      paymentMethod: "Cuenta Bancaria",
      accountHolder: "John Doe",
      bank: "Scotianbank",
      accountType: "Corriente",
      currency: "USD",
      accountNumber: "123456787",
    },
  ];

  const tableBottomInfo = [
    { key: "Método de Pago", value: "Cuenta Bancaria" },
    { key: "Tipo de Cuenta", value: "Corriente" },
    { key: "Nombre del dueño", value: "John Doe" },
    { key: "Moneda", value: "USD" },
    { key: "Banco", value: "Scotianbank" },
    { key: "Número de cuenta", value: "123456787" },
  ];

  return (
    <LoggedLayout>
      <Container>
        <Header>
          <Title>Pagos de GiftCard</Title>
          <Text marginTop="6px">No. de Lote: 00012</Text>
          <InlineTextContainer justifyContent="flex-start" marginTop="6px">
            <Text>Fecha del día: </Text>
            <Text fontWeight="bold">15 de marzo 2022</Text>
          </InlineTextContainer>
        </Header>

        <Content>
          {tablesData.map(({ id, name, ref, tableData, column }) => (
            <TableContainer key={id}>
              <InlineTextContainer
                justifyContent="flex-start"
                className="responsive-title"
              >
                <Title>{name}</Title>
                <Title color="#6A736F">{ref}</Title>
              </InlineTextContainer>
              <Table
                bordered
                dataSource={tableData}
                columns={column}
                pagination={{
                  pageSizeOptions: ["5", "10", "20", "50"],
                  responsive: true,
                  locale: { items_per_page: "" },
                  showLessItems: true,
                }}
              />
              <FinalLabel>Showing: 1 - 4 of 4</FinalLabel>

              <TableBottom>
                {tableBottomInfo.map(({ key, value }) => (
                  <InlineTextContainer justifyContent="space-between" key={key}>
                    <Text>{key}</Text>
                    <Text>{value} </Text>
                  </InlineTextContainer>
                ))}
              </TableBottom>

              <GreenBoxContainer marginTop="30px">
                <FooterGreenBox>
                  <GreenBoxLabel>Total Pendiente</GreenBoxLabel>
                  <GreenBoxAmount>$ 6,200.00</GreenBoxAmount>
                </FooterGreenBox>
              </GreenBoxContainer>
            </TableContainer>
          ))}
        </Content>

        <Footer>
          <GreenBoxContainer marginTop="68px">
            <FooterGreenBox>
              <GreenBoxLabel>Total a Pagar</GreenBoxLabel>
              <GreenBoxAmount>$ 6,200.00</GreenBoxAmount>
            </FooterGreenBox>
          </GreenBoxContainer>

          <ButtonsContainer>
            <CancelButton onClick={() => navigate(-1)}>Cancelar</CancelButton>
            <Button>Pagar</Button>
          </ButtonsContainer>
        </Footer>
      </Container>
    </LoggedLayout>
  );
}
