import * as types from "../types";

const initialState = {
  isLoadingGetPendingList: false,
  getPendingListSuccess: false,
  getPendingListError: undefined,
  pendingList: undefined,
  meta: undefined,

  pendingAmounts: undefined,
};

const applicationsPendingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_APPLICATIONS_PENDING:
      return {
        isLoadingGetPendingList: true,
        getPendingListSuccess: undefined,
        getPendingListError: undefined,
        pendingList: undefined,
        meta: undefined,
        pendingAmounts: undefined,
      };
    case types.GET_APPLICATIONS_PENDING_SUCCESS:
      return {
        isLoadingGetPendingList: false,
        getPendingListSuccess: true,
        getPendingListError: undefined,
        pendingList: action.payload.data,
        meta: action.payload.meta,
        pendingAmounts: action.payload.amounts,
      };
    case types.GET_APPLICATIONS_PENDING_ERROR:
      return {
        isLoadingGetPendingList: false,
        getPendingListSuccess: false,
        getPendingListError: action.payload.error,
        pendingList: undefined,
        meta: undefined,
        pendingAmounts: undefined,
      };

    default:
      return state;
  }
};

export default applicationsPendingReducer;
