import React from "react";
import { Container, InputLabel, InputValue, Value } from "./styles";

const Input = ({ label, value, width, maxWidth }) => (
  <Container width={width} maxWidth={maxWidth}>
    <InputLabel>{label}</InputLabel>
    <InputValue>
      <Value>{value}</Value>
    </InputValue>
  </Container>
);

export default Input;
