import styled from "styled-components";

export const AmountText = styled.span`
  font-weight: normal;
  display: flex;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
  margin-left: 8px;
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;
export const Line = styled.div`
  margin: 8px 0 8px 0;
  border-bottom: 1px solid #9da6a2;
  width: 340px;
  @media screen and (max-width: 480px) {
    width: 250px;
  }
`;
export const Container = styled.div`
  display: flex;
  border-radius: 16px;
  padding: 30px 0 24px 12px;
  width: 100%;
  font-family: Sora;
  height: fit-content;
  flex-direction: column;
  @media screen and (max-width: 480px) {
    padding: 30px 14px 40px 20px;
    min-height: 70%;
  }
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 41px;
  ${Column}:first-child {
    margin-right: 12px;
  }
  ${Column}:last-child {
    margin-left: 44px;
  }
`;
export const AmountContainer = styled.div`
  margin-top: 16px;
`;

export const TitleText = styled.label`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #35403b;
  margin-bottom: 31px;
  display: flex;
`;
export const ContentDisplay = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${Row}:first-child {
    align-items: center;
    margin: 0 0 30px 8px;
  }
`;
export const GoBackText = styled.span`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #35403b;
  margin-left: 25px;
`;
export const Label = styled.span`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
`;
export const Text = styled.span`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #111415;
`;
export const TypeImg = styled.img`
  max-width: 30px;
`;
