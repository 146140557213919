import { Result } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import {
  BusinessDeclinedIcon,
  BusinessIcon,
  BusinessPendingIcon,
} from "../../../assets/icons";
import LoggedLayout from "../../../ui/components/layout";
import BusinessView from "../../components/businessInfoView";
import LoadingComponent from "../../components/spinner";
import { getApplicationPendingDetails } from "../store/actions";

function ApplicationsPendingDetails({
  isLoadingDetails,
  detailsData,
  detailsError,
  getDetails,
}) {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getDetails(id);
  }, [getDetails, id]);

  const sampleLocation = useLocation();

  const isDeclined = sampleLocation?.pathname.includes("declined");
  const isUpdate = sampleLocation?.pathname.includes("update");

  const formatdate = (date) =>
    new Date(date).toLocaleDateString("en-US", { timeZone: "UTC" });

  const dataToDetails = {
    user: {
      email: detailsData?.user?.email,
      full_name: detailsData?.user?.full_name,
      phone: detailsData?.user?.phone,
    },
    id: detailsData?.business_id,
    business: {
      name: detailsData?.business_name,
      type: detailsData?.business_type_name,
      country: detailsData?.business_country_name,
      state: detailsData?.business_state_name,
      city: detailsData?.business_city_name,
      zip: detailsData?.business_postal_code,
      phone: `+${detailsData?.business_prefix} ${detailsData?.business_phone}`,
      email: detailsData?.business_email,
      document_type: detailsData?.business_document_type,
      document_number: detailsData?.business_id_number,
      document_expiration: formatdate(detailsData?.business_expiration_date),
      services: detailsData?.business_services,
      address: detailsData?.business_address,
      hours: detailsData?.business_hours,
      description: detailsData?.business_description,
      logo: detailsData?.business_image_path,
      images: detailsData?.business_images,
      documents: detailsData?.business_documentations,
      questions: detailsData?.business_questionnary,
      status: detailsData?.fincimex_status,
      fincimex_message: detailsData?.fincimex_message,
      comment: detailsData?.enforcement_comment,
      bank_data: {
        name: detailsData?.business_bank?.name_owner,
        currency: detailsData?.business_bank?.currency,
        bank: detailsData?.business_bank?.bank,
        type: detailsData?.business_bank?.type_account,
        account_number: detailsData?.business_bank?.account_number,
      },
    },
  };
  return (
    <LoggedLayout>
      {isLoadingDetails ? (
        <LoadingComponent />
      ) : detailsError ? (
        <Result
          status="404"
          title="Error obteniendo details"
          subTitle="Sorry, the business type you visited does not exist, try again"
          extra={(
            <Link to="/dashboard/applications/pending">
              <button>Back to List</button>
            </Link>
          )}
        />
      ) : (
        <BusinessView
          isDeclined={isDeclined}
          showButtons={isUpdate || !isDeclined}
          dataToShow={dataToDetails}
          setShowInfo={() => {
            if (isDeclined) {
              navigate("/dashboard/applications/declined");
            } else if (isUpdate) {
              navigate("/dashboard/applications/update");
            } else {
              navigate("/dashboard/applications/pending");
            }
          }}
          title={
            isDeclined
              ? "Establecimientos declinados"
              : isUpdate
                ? "Actualización de datos"
                : "Solicitudes"
          }
          icon={
            isDeclined ? (
              <BusinessDeclinedIcon />
            ) : isUpdate ? (
              <BusinessIcon />
            ) : (
              <BusinessPendingIcon />
            )
          }
        />
      )}
    </LoggedLayout>
  );
}

const mapStateToProps = (state) => ({
  isLoadingDetails: state.applicationsPendingDetails.isLoadingDetails,
  detailsData: state.applicationsPendingDetails.detailsData,
  detailsError: state.applicationsPendingDetails.detailsError,
});

const mapDispatchToProps = (dispatch) => ({
  getDetails: (id) => dispatch(getApplicationPendingDetails(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationsPendingDetails);
