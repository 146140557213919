import React, { useEffect, useCallback } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { LeftArrowIcon, AddressIcon } from "../../../assets/icons";
import { useForm } from "react-hook-form";

import LoggedLayout from "../../../ui/components/layout/index";
import {
  Container,
  Button,
  CancelButton,
  ButtonText,
  ButtonsContainer,
  Input as InputTag,
  InputLabel,
  InputContainer,
  InputsContainer,
  Header,
  Title,
  Select,
  RowContainer,
  ErrorLabel,
} from "./styles";

import openNotification from "../../components/notification";
import { connect } from "react-redux";
import { Result } from "antd";
import {
  clean,
  cleanUsersState,
  getCities,
  getCountries,
  getPrefixes,
  getRoles,
  getStates,
  getUser,
  postUser,
} from "../store/actions";
import LoadingComponent from "../../components/spinner";

function NewUserForm({
  getCountriesFn,
  getPrefixesFn,
  getStatesFn,
  getCitiesFn,
  getRolesFn,
  getUserFn,
  loadingCountries,
  countriesData,
  countriesError,
  loadingPrefixes,
  prefixesData,
  prefixesError,
  loadingStates,
  statesData,
  statesError,
  loadingCities,
  citiesData,
  citiesError,
  loadingRoles,
  rolesData,
  rolesError,
  cleanFn,
  postUserFn,
  cleanUsersStateFn,
  isLoadingPostUser,
  postUserSuccess,
  postUserError,
  isLoadingGetUser,
  userData,
  getUserError,
}) {
  const { user_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const isNew = location.pathname === "/dashboard/user/new";
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
    setError,
  } = useForm({ defaultValues: { country_id: 59, prefix_id: 55 } });

  const country_id = watch("country_id");
  const state_id = watch("state_id");

  const showErrors = useCallback(
    (errorsObj) => {
      const fields = Object.keys(errorsObj);
      const messages = Object.values(errorsObj).toString();
      fields.map((element, index) =>
        setError(element, {
          type: "manual",
          message: messages.split(",")[index],
        })
      );
    },
    [setError]
  );

  useEffect(() => {
    if (!isNew) {
      getUserFn(user_id);
    }
  }, [getUserFn, isNew, user_id]);

  useEffect(() => {
    if (userData) {
      const fields = Object.keys(userData);
      const values = Object.values(userData);
      fields.map((field, index) =>
        setValue(field, values[index], { shouldValidate: false })
      );
    }
  }, [setValue, userData]);

  useEffect(() => {
    countriesError &&
      openNotification(
        "Error!",
        "Error cargando lista de paises, recargue e intente nuevamnete",
        "error"
      );
    prefixesError &&
      openNotification(
        "Error!",
        "Error cargando lista de prefijos, recargue e intente nuevamnete",
        "error"
      );
    statesError &&
      openNotification(
        "Error!",
        "Error cargando lista de estados, recargue e intente nuevamnete",
        "error"
      );
    citiesError &&
      openNotification(
        "Error!",
        "Error cargando lista de ciudades, recargue e intente nuevamnete",
        "error"
      );
    rolesError &&
      openNotification(
        "Error!",
        "Error cargando lista de roles, recargue e intente nuevamnete",
        "error"
      );
  }, [citiesError, countriesError, prefixesError, rolesError, statesError]);

  useEffect(() => {
    getCountriesFn();
    getPrefixesFn();
    getRolesFn({ status: "ACTIVE" });

    return () => {
      cleanFn();
      cleanUsersStateFn();
    };
  }, [cleanFn, cleanUsersStateFn, getCountriesFn, getPrefixesFn, getRolesFn]);

  useEffect(() => {
    if (postUserSuccess) {
      navigate("/dashboard/users");
      openNotification("¡Éxito!", "Usuario creado exitosamente", "success");
    } else if (postUserError) {
      if (postUserError.response.data.errors) {
        showErrors(postUserError.response.data.errors);
      }
      openNotification("Error!", "Este usuario no pudo ser creado", "error");
    }
  }, [navigate, postUserError, postUserSuccess, showErrors]);

  useEffect(() => {
    country_id && getStatesFn(country_id);
  }, [country_id, getStatesFn]);
  useEffect(() => {
    state_id && getCitiesFn(state_id);
  }, [state_id, getCitiesFn]);

  const onSubmit = (data) => {
    postUserFn(data);
  };

  return (
    <LoggedLayout>
      <Container>
        <Header>
          <Link to="/dashboard/users">
            <LeftArrowIcon />
          </Link>

          <Title isNew={isNew}>
            {isNew ? "Creación de Usuario" : "Visualizar Usuario"}
          </Title>
        </Header>
        {isLoadingGetUser ? (
          <LoadingComponent />
        ) : getUserError ? (
          <Result
            status="404"
            title="No encontrado"
            subTitle="Es posible que este usuario no exista"
            extra={<Link to="/dashboard/users">Volver a la lista</Link>}
          />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputsContainer gap="56px">
              <InputContainer width="360px">
                <InputLabel disabled={!isNew}>Nombres*</InputLabel>
                <InputTag
                  disabled={!isNew}
                  {...register("first_name", { required: "Campo requerido" })}
                  placeholder="Inserte nombre"
                />
                {errors.first_name && (
                  <ErrorLabel>{errors.first_name.message}</ErrorLabel>
                )}
              </InputContainer>

              <InputContainer width="360px">
                <InputLabel>Apellidos*</InputLabel>
                <InputTag
                  disabled={!isNew}
                  {...register("last_name", { required: "Campo requerido" })}
                  placeholder="Inserte apellido"
                />
                {errors.last_name && (
                  <ErrorLabel>{errors.last_name.message}</ErrorLabel>
                )}
              </InputContainer>
            </InputsContainer>

            <InputsContainer gap="56px">
              <InputContainer width="360px">
                <InputLabel disabled={!isNew}>Correo Electrónico*</InputLabel>
                <InputTag
                  disabled={!isNew}
                  {...register("email", { required: "Campo requerido" })}
                  placeholder="mail@mail.com"
                />
                {errors.email && (
                  <ErrorLabel>{errors.email.message}</ErrorLabel>
                )}
              </InputContainer>

              <InputContainer width="360px">
                <InputLabel disabled={!isNew}>Teléfono del negocio</InputLabel>
                <RowContainer>
                  <Select
                    disabled
                    value={watch("prefix_id")}
                    showSearch
                    optionFilterProp="label"
                    bordered={false}
                    virtual={false}
                    width="40%"
                    border="none"
                    loading={loadingPrefixes}
                    {...register("prefix_id", { required: true })}
                    onChange={(value) =>
                      setValue("prefix_id", value, { shouldValidate: true })
                    }
                  >
                    {prefixesData?.map(({ code, id, country_name }) => (
                      <Select.Option
                        value={id}
                        label={`${country_name} ${code}`}
                      >
                        + {code}
                      </Select.Option>
                    ))}
                  </Select>
                  <InputTag
                    disabled={!isNew}
                    border="none"
                    {...register("phone", { required: "Campo requerido" })}
                    placeholder="54321098"
                  />
                </RowContainer>
                {errors.phone && (
                  <ErrorLabel>{errors.phone.message}</ErrorLabel>
                )}
              </InputContainer>
            </InputsContainer>

            <InputsContainer>
              <InputContainer width="776px">
                <InputLabel disabled={!isNew}>Dirección</InputLabel>
                <RowContainer>
                  <InputTag
                    disabled={!isNew}
                    placeholder="Ingrese su dirección..."
                    {...register("addresses", { required: "Campo Requerido" })}
                    border="none"
                    name="addresses"
                  />
                  <AddressIcon />
                </RowContainer>
                {errors.addresses && (
                  <ErrorLabel>{errors.addresses.message}</ErrorLabel>
                )}
              </InputContainer>
            </InputsContainer>

            <InputsContainer gap="24px">
              <InputContainer width="176px">
                <InputLabel disabled={!isNew}>País</InputLabel>
                <Select
                  bordered={false}
                  virtual={false}
                  width="176px"
                  loaading={loadingCountries}
                  disabled
                  value={watch("country_id")}
                  placeholder="Seleccione"
                  showSearch
                  optionFilterProp="label"
                  {...register("country_id", { required: "Campo Requerido" })}
                  onChange={(value) => {
                    setValue("country_id", value, { shouldValidate: true });
                    setValue("state_id", null, { shouldValidate: false });
                    setValue("city_id", null, { shouldValidate: false });
                  }}
                >
                  {countriesData?.map(({ id, name }) => (
                    <Select.Option value={id} label={name}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
                {errors.country_id && (
                  <ErrorLabel>{errors.country_id.message}</ErrorLabel>
                )}
              </InputContainer>

              <InputContainer width="176px">
                <InputLabel disabled={!isNew}>Estado</InputLabel>
                <Select
                  bordered={false}
                  virtual={false}
                  width="176px"
                  placeholder="Seleccione"
                  showSearch
                  optionFilterProp="label"
                  disabled={loadingStates || !isNew}
                  loading={loadingStates}
                  value={watch("state_id")}
                  {...register("state_id", { required: "Campo Requerido" })}
                  onChange={(value) => {
                    setValue("state_id", value, { shouldValidate: true });
                    setValue("city_id", null, { shouldValidate: false });
                  }}
                >
                  {statesData?.map(({ id, name }) => (
                    <Select.Option value={id} label={name}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
                {errors.state_id && (
                  <ErrorLabel>{errors.state_id.message}</ErrorLabel>
                )}
              </InputContainer>
              <InputContainer width="176px">
                <InputLabel disabled={!isNew}>Ciudad</InputLabel>
                <Select
                  bordered={false}
                  virtual={false}
                  width="176px"
                  placeholder="Seleccione"
                  showSearch
                  optionFilterProp="label"
                  value={watch("city_id")}
                  loading={loadingCities}
                  disabled={loadingCities || !state_id || !isNew}
                  {...register("city_id", { required: "Campo Requerido" })}
                  onChange={(value) =>
                    setValue("city_id", value, { shouldValidate: true })
                  }
                >
                  {citiesData?.map(({ id, name }) => (
                    <Select.Option value={id} label={name}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
                {errors.city_id && (
                  <ErrorLabel>{errors.city_id.message}</ErrorLabel>
                )}
              </InputContainer>

              <InputContainer width="176px">
                <InputLabel disabled={!isNew}>Código postal</InputLabel>
                <InputTag
                  disabled={!isNew}
                  {...register("postal_code", { required: "Campo requerido" })}
                  placeholder="12345"
                />
                {errors.postal_code && (
                  <ErrorLabel>{errors.postal_code.message}</ErrorLabel>
                )}
              </InputContainer>
            </InputsContainer>
            <InputsContainer>
              <InputContainer width="176px">
                <InputLabel disabled={!isNew}>Rol*</InputLabel>
                <Select
                  bordered={false}
                  virtual={false}
                  width="176px"
                  loading={loadingRoles}
                  disabled={loadingRoles || !isNew}
                  placeholder="Seleccione"
                  value={watch("role_id")}
                  {...register("role_id", { required: "Campo requerido" })}
                  onChange={(value) =>
                    setValue("role_id", value, { shouldValidate: true })
                  }
                >
                  {rolesData?.map(({ name, id }) => (
                    <Select.Option value={id}>{name}</Select.Option>
                  ))}
                </Select>
                {errors.role_id && (
                  <ErrorLabel>{errors.role_id.message}</ErrorLabel>
                )}
              </InputContainer>
            </InputsContainer>

            {isNew && (
              <ButtonsContainer>
                <CancelButton margin="0 24px 0 0" htmlType="button">
                  <ButtonText>
                    <Link to="/dashboard/users">Cancelar</Link>
                  </ButtonText>
                </CancelButton>
                <Button htmlType="submit" loading={isLoadingPostUser}>
                  Guardar
                </Button>
              </ButtonsContainer>
            )}
          </form>
        )}
      </Container>
    </LoggedLayout>
  );
}
const mapStateToProps = (state) => ({
  loadingCountries: state.locations.loadingCountries,
  countriesData: state.locations.countriesData,
  countriesError: state.locations.countriesError,

  loadingPrefixes: state.locations.loadingPrefixes,
  prefixesData: state.locations.prefixesData,
  prefixesError: state.locations.prefixesError,

  loadingStates: state.locations.loadingStates,
  statesData: state.locations.statesData,
  statesError: state.locations.statesError,

  loadingCities: state.locations.loadingCities,
  citiesData: state.locations.citiesData,
  citiesError: state.locations.citiesError,

  loadingRoles: state.roles.loadingRoles,
  rolesData: state.roles.rolesData,
  rolesError: state.roles.rolesError,

  isLoadingPostUser: state.users.isLoadingPostUser,
  postUserSuccess: state.users.postUserSuccess,
  postUserError: state.users.postUserError,

  isLoadingGetUser: state.users.isLoadingGetUser,
  userData: state.users.userData,
  getUserError: state.users.getUserError,
});
const mapDispatchToProps = (dispatch) => ({
  getCountriesFn: () => dispatch(getCountries()),
  getStatesFn: (country_id) => dispatch(getStates(country_id)),
  getPrefixesFn: () => dispatch(getPrefixes()),
  getCitiesFn: (state_id) => dispatch(getCities(state_id)),
  getRolesFn: (body) => dispatch(getRoles(body)),
  postUserFn: (userBody) => dispatch(postUser(userBody)),
  cleanFn: (state_id) => dispatch(clean(state_id)),
  getUserFn: (id) => dispatch(getUser(id)),
  cleanUsersStateFn: (state_id) => dispatch(cleanUsersState(state_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewUserForm);
