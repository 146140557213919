import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Container,
  HeaderContainer,
  Tittle,
  PendingCountBox,
  TittlePendingCountBox,
  TotalPendingCountBox,
  InputContainer,
  InputLabel,
  Input,
  DatePickerContainer,
  DatePicker,
  SearchHeaderContainer,
  TableContainer,
  Table,
  Row,
  RowTitle,
  RowText,
  RowTextFirst,
  Button,
} from "./styles";
import { CalendarIcon } from "../../../assets/icons";
import LoggedLayout from "../../../ui/components/layout/index";
import { getApplicationDeclined } from "../store/actions";
import { useForm } from "react-hook-form";
import openNotification from "../../components/notification";
import PaginationComponent from "../../components/pagination";

const formatdate = (date) =>
  new Date(date).toLocaleDateString("en-US", { timeZone: "UTC" });

const columns = [
  {
    title: "Establecimiento",
    dataIndex: "establishment",
    key: "establishment",
    render: (data) => (
      <Row>
        <RowTitle>Establecimiento</RowTitle>
        <RowTextFirst>{data}</RowTextFirst>
      </Row>
    ),
  },
  {
    title: "Fecha de solicitud",
    dataIndex: "application_date",
    key: "applicationdate",
    render: (data) => (
      <Row>
        <RowTitle>Fecha de solicitud</RowTitle>
        <RowText>{formatdate(data)}</RowText>
      </Row>
    ),
  },
  {
    title: "Fecha de Declinación",
    dataIndex: "expiration_date",
    key: "declineddate",
    render: (data) => (
      <Row>
        <RowTitle>Fecha de Declinación</RowTitle>
        <RowText>{formatdate(data)}</RowText>
      </Row>
    ),
  },
];

const DeclinedApplications = ({
  isLoadingDeclinedList,
  meta,
  declinedList,
  declinedError,
  getList,
}) => {
  const navigate = useNavigate();
  const { handleSubmit, register, setValue, watch } = useForm();

  useEffect(() => {
    if (declinedError) {
      openNotification("Error", "Error obteniendo lista", "error");
    }
  }, [declinedError]);

  useEffect(() => {
    const body = {
      page: 1,
      name: null,
      from: null,
      to: null,
      paginate: 15,
    };
    getList(body);
  }, [getList]);

  const onSubmit = (body) => {
    getList(body);
  };

  const getListFn = (bd) => {
    const body = {
      ...bd,
      name: watch("name"),
      from: watch("from"),
      to: watch("to"),
    };
    getList(body);
  };

  return (
    <LoggedLayout>
      <Container>
        <HeaderContainer>
          <Tittle>Establecimientos Declinados</Tittle>
          <PendingCountBox>
            <TittlePendingCountBox>
              Solicitudes pendientes
            </TittlePendingCountBox>
            <TotalPendingCountBox>24</TotalPendingCountBox>
          </PendingCountBox>
        </HeaderContainer>

        <SearchHeaderContainer onSubmit={handleSubmit(onSubmit)}>
          <InputContainer>
            <InputLabel>Nombre del establecimiento</InputLabel>
            <Input
              placeholder="Ej: Floridita"
              {...register("name")}
              disabled={isLoadingDeclinedList}
            />
          </InputContainer>
          <DatePickerContainer>
            <InputLabel>Desde - Hasta</InputLabel>
            <DatePicker
              placeholder={["dd/mm/aaaa  -  ", "dd/mm/aaaa"]}
              getPopupContainer={(trigger) => trigger}
              suffixIcon={<CalendarIcon />}
              format="yyyy/MM/DD"
              {...register("from")}
              {...register("to")}
              onChange={(_, value) => {
                setValue("from", value[0]);
                setValue("to", value[1]);
              }}
            />
          </DatePickerContainer>
          <InputContainer>
            <Button htmlType="submit" disabled={isLoadingDeclinedList}>
              Buscar
            </Button>
          </InputContainer>
        </SearchHeaderContainer>
        <TableContainer>
          <Table
            loading={isLoadingDeclinedList}
            dataSource={declinedList}
            columns={columns}
            pagination={false}
            onRow={(allData) => ({
              onClick: () =>
                navigate(
                  `/dashboard/applications/declined/detail/${allData.id}`
                ),
            })}
          />
        </TableContainer>
        {meta && <PaginationComponent meta={meta} changePage={getListFn} />}
      </Container>
    </LoggedLayout>
  );
};

const mapStateToProps = (state) => ({
  isLoadingDeclinedList: state.applicationsDeclined.isLoadingDeclinedList,
  meta: state.applicationsDeclined.meta,
  declinedList: state.applicationsDeclined.declinedList,
  declinedError: state.applicationsDeclined.declinedError,
});

const mapDispatchToProps = (dispatch) => ({
  getList: (id) => dispatch(getApplicationDeclined(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeclinedApplications);
