import * as types from "../types";

const initialState = {
  loadingPrivilges: false,
  privilegesData: undefined,
  privilegesError: undefined,

  loadingPostPrivi: false,
  postPriviSuccess: undefined,
  postPriviError: undefined,
};

const privilegesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PRIVILEGES:
      return {
        ...state,
        loadingPrivilges: true,
        privilegesData: undefined,
        privilegesError: undefined,
      };

    case types.GET_PRIVILEGES_SUCCESS:
      return {
        ...state,
        loadingPrivilges: false,
        privilegesData: action.payload,
        privilegesError: undefined,
      };

    case types.GET_PRIVILEGES_ERROR:
      return {
        ...state,
        loadingPrivilges: false,
        privilegesData: undefined,
        privilegesError: action.payload.error,
      };

    case types.POST_PRIVILEGES:
      return {
        ...state,
        loadingPostPrivi: true,
        postPriviSuccess: undefined,
        postPriviError: undefined,
      };

    case types.POST_PRIVILEGES_SUCCESS:
      return {
        ...state,
        loadingPostPrivi: false,
        postPriviSuccess: true,
        postPriviError: undefined,
      };

    case types.POST_PRIVILEGES_ERROR:
      return {
        ...state,
        loadingPostPrivi: false,
        postPriviSuccess: false,
        postPriviError: action.payload.error,
      };

    case types.CLEAN_PRIVI:
      return initialState;

    default:
      return state;
  }
};

export default privilegesReducer;
