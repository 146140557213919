import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LeftArrowIcon } from "../../../assets/icons";
import LoggedLayout from "../../../ui/components/layout/index";
import { getUsersByRole } from "../store/actions";

import openNotification from "../../components/notification/index";
import {
  Container,
  Table,
  Header,
  Title,
  GlobalText,
  TableContentContainer,
  ParentContainer,
  StatusContainer,
  Switch,
  ActionOption,
  Text,
} from "./styles";
import PaginationComponent from "../../components/pagination";

function UserByRoleList({
  getRolesFn,
  loadingUsersByRole,
  usersByRoleData,
  usersByRoleError,
  meta,
}) {
  const { role_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    usersByRoleError &&
      openNotification(
        "Error",
        "Error al obtener lista de usuarios con este rol",
        "error"
      );
  }, [usersByRoleError]);

  useEffect(() => {
    getRolesFn({ role_id, page: 1, paginate: 15 });
  }, [getRolesFn, role_id]);

  const [checked, setChecked] = useState(true);
  const columns = [
    {
      title: "Nombre y Apellido",
      dataIndex: "first_name",
      width: "40%",
      render: (first_name, allData) => (
        <ParentContainer>
          <StatusContainer>
            <Text>
              {first_name} {allData.last_name}
            </Text>
          </StatusContainer>
        </ParentContainer>
      ),
    },
    {
      title: "Correo",
      dataIndex: "email",
      width: "20%",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "30%",
      render: (statusData) => (
        <ParentContainer>
          <StatusContainer>
            <GlobalText>{statusData}</GlobalText>
          </StatusContainer>
        </ParentContainer>
      ),
    },
    {
      title: "Rol",
      dataIndex: "role",
      width: "15%",
    },
    {
      title: "Acciones",
      width: "5%",
      dataIndex: "status",
      render: () => (
        <ActionOption>
          <Switch checked={checked} onClick={() => setChecked(!checked)} />
        </ActionOption>
      ),
    },
  ];

  const getList = (bdy) => {
    const body = {
      ...bdy,
      role_id,
    };
    getRolesFn(body);
  };

  return (
    <LoggedLayout>
      <Container>
        <Header>
          <Link to="/dashboard/roles">
            <LeftArrowIcon />
          </Link>
          <Title>{usersByRoleData?.[0]?.role}</Title>
        </Header>

        <Title>Listado de Usuarios</Title>

        <TableContentContainer>
          <Table
            bordered
            dataSource={usersByRoleData}
            columns={columns}
            scroll={{ x: "max-content" }}
            loading={loadingUsersByRole}
            pagination={false}
            onRow={(allData) => ({
              onClick: () => navigate(`/dashboard/user/detail/${allData.id}`),
            })}
          />
        </TableContentContainer>
        {meta && <PaginationComponent meta={meta} changePage={getList} />}
      </Container>
    </LoggedLayout>
  );
}

const mapStateToProps = (state) => ({
  loadingUsersByRole: state.roles.loadingUsersByRole,
  usersByRoleData: state.roles.usersByRoleData,
  usersByRoleError: state.roles.usersByRoleError,
  meta: state.roles.meta,
});
const mapDispatchToProps = (dispatch) => ({
  getRolesFn: (body) => dispatch(getUsersByRole(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserByRoleList);
