import * as types from "../types";

const initialState = {
  isLoadingChangePassword: false,
  changePasswordSuccess: false,
  changePasswordError: undefined,
  token: undefined,
};

const ChangePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_PASSWORD:
      return {
        isLoadingChangePassword: true,
        changePasswordSuccess: false,
        changePasswordError: undefined,
        token: undefined,
      };
    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        isLoadingChangePassword: false,
        changePasswordSuccess: true,
        changePasswordError: undefined,
        token: action.payload.token,
      };
    case types.CHANGE_PASSWORD_ERROR:
      return {
        isLoadingChangePassword: false,
        changePasswordSuccess: false,
        changePasswordError: action.payload,
        token: undefined,
      };
    case types.RESET_PASSWORD:
      return initialState;

    default:
      return state;
  }
};

export default ChangePasswordReducer;
