import React, { useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import LoggedLayout from "../../../ui/components/layout/index";

import {
  Container,
  Button,
  CancelButton,
  ButtonText,
  ButtonsContainer,
  Input,
  InputLabel,
  InputContainer,
  InputsContainer,
  Header,
  Title,
  ErrorLabel,
} from "./styles";

import openNotification from "../../components/notification";
import { cleanRoles, postRole } from "../store/actions";
import { connect } from "react-redux";

function NewRoleForm({
  postRoleFn,
  postRoleSuccess,
  postRoleError,
  loadingPostRole,
  cleanFn,
}) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    setError,
  } = useForm();

  const navigate = useNavigate();

  const showErrors = useCallback(
    (errorsObj) => {
      const fields = Object.keys(errorsObj);
      const messages = Object.values(errorsObj).toString();
      fields.map((element, index) =>
        setError(element, {
          type: "manual",
          message: messages.split(",")[index],
        })
      );
    },
    [setError]
  );

  useEffect(() => {
    if (postRoleSuccess) {
      navigate("/dashboard/roles");
      openNotification("¡Éxito!", "Rol agregado exitosamente", "success");
    } else if (postRoleError) {
      if (postRoleError.response.data.errors) {
        showErrors(postRoleError.response.data.errors);
      }
      openNotification("Error!", "Este usuario no pudo ser creado", "error");
    }
    return () => cleanFn();
  }, [postRoleSuccess, postRoleError, showErrors, cleanFn, navigate]);

  const onSubmit = (data) => {
    postRoleFn(data);
  };

  return (
    <LoggedLayout height="352px">
      <Container>
        <Header>
          <Title>Creación de Rol</Title>
        </Header>

        <form onSubmit={handleSubmit(onSubmit)}>
          <InputsContainer>
            <InputContainer width="546px">
              <InputLabel>Nombres*</InputLabel>
              <Input
                placeholder="Insertar nombre"
                {...register("name", { required: "Campo requerido" })}
                onChange={(value) =>
                  setValue("name", value.target.value, { shouldValidate: true })
                }
              />
              {errors.name && <ErrorLabel>{errors.name.message}</ErrorLabel>}
            </InputContainer>
          </InputsContainer>

          <ButtonsContainer>
            <CancelButton margin="0 24px 0 0" htmlType="button">
              <ButtonText>
                <Link to="/dashboard/roles">Cancelar</Link>
              </ButtonText>
            </CancelButton>
            <Button
              htmlType="submit"
              disabled={loadingPostRole}
              loading={loadingPostRole}
            >
              Guardar
            </Button>
          </ButtonsContainer>
        </form>
      </Container>
    </LoggedLayout>
  );
}
const mapStateToProps = (state) => ({
  postRoleSuccess: state.roles.postRoleSuccess,
  postRoleError: state.roles.postRoleError,
  loadingPostRole: state.roles.loadingPostRole,
});
const mapDispatchToProps = (dispatch) => ({
  postRoleFn: (body) => dispatch(postRole(body)),
  cleanFn: () => dispatch(cleanRoles()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewRoleForm);
