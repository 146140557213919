import React, { useState } from "react";
import {
  Title,
  Input,
  LabelText,
  InputContainer,
  SecondContainer,
  InfoContainer,
  TextInfo,
  SubTitle,
  AddCoinText,
  SelectCountry,
  SelectsContainer,
  SelectCoin,
  OptionCoinText,
  IconContainer,
} from "./styles";
import { InfoIcon, DeleteIcon, CubaFlagIcon } from "../../../assets/icons";
import LoggedLayout from "../../../ui/components/layout/index";

export default function BusinessConfiguration() {
  const [retencion, setRetencion] = useState({ retencion: "15%" });
  const [coin, setCoin] = useState(1);

  const onChangeInput = ({ target }) => {
    const { value, name } = target;
    const regex = /^[0-9]+$/;
    if (regex.test(value) || value === "" || value.includes("%")) {
      setRetencion({ ...retencion, [name]: value });
    }
  };

  const onBlurInput = ({ target }) => {
    const { value, name } = target;
    if (value !== "") {
      setRetencion({ ...retencion, [name]: `${value}%` });
    }
  };

  const onFocusInput = ({ target }) => {
    const { name } = target;
    setRetencion({ ...retencion, [name]: "" });
  };

  return (
    <LoggedLayout>
      <Title>Configuración</Title>
      <SecondContainer>
        <InputContainer>
          <LabelText>Retencion</LabelText>
          <Input
            type="text"
            name="retencion"
            value={retencion?.retencion}
            onChange={(e) => onChangeInput(e)}
            onBlur={(e) => onBlurInput(e)}
            onFocus={(e) => onFocusInput(e)}
          />
        </InputContainer>
        <InfoContainer>
          <InfoIcon />
          <TextInfo>Porcentaje de retención del monto de la GiftCard</TextInfo>
        </InfoContainer>
      </SecondContainer>
      <SubTitle>Monedas permitidas</SubTitle>
      {[...Array(coin)].map((name) => (
        <SelectsContainer key={name}>
          <SelectCountry defaultValue="CUP">
            <SelectCountry.Option value="CUP">
              <CubaFlagIcon />
            </SelectCountry.Option>
          </SelectCountry>
          <SelectCoin defaultValue="CUP">
            <SelectCoin.Option value="CUP">
              <OptionCoinText>Peso cubano (CUP)</OptionCoinText>
            </SelectCoin.Option>
          </SelectCoin>
          <IconContainer onClick={() => setCoin((prevState) => prevState - 1)}>
            <DeleteIcon />
          </IconContainer>
        </SelectsContainer>
      ))}
      <AddCoinText onClick={() => setCoin((prevState) => prevState + 1)}>
        Agregar nueva moneda
      </AddCoinText>
    </LoggedLayout>
  );
}
