import React from "react";
import { Container, InputLabel, InputValue, Bubble, Text } from "./styles";

const InputServices = ({ label, services = [], width, maxWidth }) => (
  <Container width={width} maxWidth={maxWidth}>
    <InputLabel>{label}</InputLabel>
    <InputValue>
      {services?.map(({ name, id }) => (
        <Bubble key={id}>
          <Text>{name}</Text>
        </Bubble>
      ))}
    </InputValue>
  </Container>
);

export default InputServices;
