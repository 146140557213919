import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import LoggedLayout from "../../../ui/components/layout/index";
import {
  Container,
  Button,
  CancelButton,
  ButtonText,
  ButtonsContainer,
  InputLabel,
  InputContainer,
  InputsContainer,
  Header,
  Title,
  Select,
  Checkbox,
  PermissionsContainer,
  CheckLabel,
  PermissionSection,
} from "./styles";

import openNotification from "../../components/notification";
import {
  cleanPrivi,
  getPrivileges,
  getRoles,
  postPrivileges,
} from "../store/actions";
import { connect } from "react-redux";
import LoadingComponent from "../../components/spinner/index";

function PermissionsView({
  loadingPrivilges,
  privilegesData,
  privilegesError,
  getPrivilegesFn,
  getRolesFn,
  loadingRoles,
  rolesData,
  rolesError,
  postPrivilegesFn,
  loadingPostPrivi,
  postPriviSuccess,
  postPriviError,
  clean,
}) {
  const [roleSelected, setRoleSelected] = useState();

  const { handleSubmit, control, setValue } = useForm();

  const { append, fields, remove } = useFieldArray({
    control,
    name: "form",
  });

  useEffect(() => {
    getRolesFn({ status: "ACTIVE" });
  }, [getRolesFn]);

  useEffect(() => {
    privilegesData &&
      privilegesData?.map(({ id, actions, name }) =>
        append({
          [id]: {
            fields: actions.map((ele) => ({
              [ele.id]: ele.allow,
            })),
            name: actions.map((ele) => ele.name),
          },
          nameToShow: name,
        })
      );
  }, [append, privilegesData]);

  useEffect(() => {
    roleSelected && getPrivilegesFn(roleSelected);
  }, [getPrivilegesFn, roleSelected]);

  useEffect(() => {
    rolesData?.length > 0 && setRoleSelected(rolesData?.[0]?.id);
  }, [rolesData]);

  useEffect(() => {
    if (postPriviSuccess) {
      openNotification(
        "¡Éxito!",
        "Permisos actualizados correctamente",
        "success"
      );
      if (postPriviError) {
        openNotification("¡Error!", "Error actualizando permisos", "error");
        getPrivilegesFn(roleSelected);
      }
    }
    return () => clean();
  }, [clean, getPrivilegesFn, postPriviError, postPriviSuccess, roleSelected]);

  useEffect(() => {
    if (privilegesError) {
      openNotification("¡Error!", "Error cargando permisos", "error");
    }
    if (rolesError) {
      openNotification("¡Error!", "Error cargando roles", "error");
    }
  }, [privilegesError, rolesError]);

  const onSubmit = (data) => {
    const myData = data.form;
    const body = {};

    myData.forEach((elemento) => {
      body[Object.keys(elemento)[0]] = elemento[
        Object.keys(elemento)[0]
      ].fields.map((ele) =>
        (Object.values(ele).toString() === "true"
          ? Object.keys(ele)
          : ""
        ).toString()
      );
    });
    postPrivilegesFn({ body, role_id: roleSelected });
  };

  return (
    <LoggedLayout background="transparent" padding="0">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <Header>
            <Title>Permisos</Title>
          </Header>

          <InputsContainer>
            <InputContainer width="168px">
              <InputLabel>Rol*</InputLabel>
              <Select
                disabled={loadingPrivilges || loadingRoles || loadingPostPrivi}
                loading={loadingPrivilges || loadingRoles || loadingPostPrivi}
                bordered={false}
                virtual={false}
                defaultValue="Seleccione"
                width="168px"
                value={roleSelected}
                onChange={(event) => {
                  remove();
                  setRoleSelected(event);
                }}
              >
                {rolesData?.map(({ name, id }) => (
                  <Select.Option value={id}>{name}</Select.Option>
                ))}
              </Select>
            </InputContainer>
          </InputsContainer>

          {loadingPrivilges ? (
            <LoadingComponent />
          ) : (
            <PermissionsContainer>
              {fields?.map((field, fieldIdx) => (
                <PermissionSection>
                  <CheckLabel>{field.nameToShow}</CheckLabel>

                  {field[Object.keys(field)[0]].fields.map((myfield, index) => (
                    <div>
                      <Checkbox
                        disabled={loadingPostPrivi}
                        defaultChecked={myfield[Object.keys(myfield)[0]]}
                        onChange={(value) => {
                          setValue(
                            `form.[${fieldIdx}].${
                              Object.keys(field)[0]
                            }.fields[${index}].${Object.keys(myfield)[0]}`,
                            value.target.checked
                          );
                        }}
                      >
                        {field[Object.keys(field)[0]].name[index]}
                      </Checkbox>
                    </div>
                  ))}
                </PermissionSection>
              ))}
            </PermissionsContainer>
          )}
        </Container>
        <ButtonsContainer>
          <CancelButton margin="0 16px 0 0" htmlType="button">
            <ButtonText>Cancelar</ButtonText>
          </CancelButton>
          <Button htmlType="submit">Guardar</Button>
        </ButtonsContainer>
      </form>
    </LoggedLayout>
  );
}

const mapStateToProps = (state) => ({
  loadingPrivilges: state.privileges.loadingPrivilges,
  privilegesData: state.privileges.privilegesData,
  privilegesError: state.privileges.privilegesError,

  loadingPostPrivi: state.privileges.loadingPostPrivi,
  postPriviSuccess: state.privileges.postPriviSuccess,
  postPriviError: state.privileges.postPriviError,

  loadingRoles: state.roles.loadingRoles,
  rolesData: state.roles.rolesData,
  rolesError: state.roles.rolesError,
});
const mapDispatchToProps = (dispatch) => ({
  getPrivilegesFn: (role_id) => dispatch(getPrivileges(role_id)),
  getRolesFn: (name) => dispatch(getRoles(name)),
  postPrivilegesFn: (role_id) => dispatch(postPrivileges(role_id)),
  clean: (role_id) => dispatch(cleanPrivi(role_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsView);
