import * as types from "../types";

const initialState = {
  isLoadingDetails: false,
  detailsData: undefined,
  detailsError: undefined,
};

const applicationPendingDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_APPLICATIONS_PENDING_DETAIL:
      return {
        ...state,
        isLoadingDetails: true,
      };
    case types.GET_APPLICATIONS_PENDING_DETAIL_SUCCESS:
      return {
        ...state,
        isLoadingDetails: false,
        detailsData: action.payload.data,
        detailsError: undefined,
      };

    case types.GET_APPLICATIONS_PENDING_DETAIL_ERROR:
      return {
        ...state,
        isLoadingDetails: false,
        detailsData: undefined,
        detailsError: action.payload.error,
      };

    default:
      return state;
  }
};

export default applicationPendingDetailsReducer;
