import { takeEvery, call, put } from "redux-saga/effects";
import giftsCardsService from "../../../../apis/services/giftsCardsServices";
import * as types from "../types";

function* getAmountsHandler() {
  try {
    const amounts = yield call(giftsCardsService.getGiftsCardsAmounts);

    yield put({
      type: types.GET_GIFT_CARDS_AMOUNTS_SUCCESS,
      payload: { amounts },
    });
  } catch (error) {
    yield put({
      type: types.GET_GIFT_CARDS_AMOUNTS_ERROR,
      payload: {
        error,
        amounts: {
          totalAmount: "-",
          amountSaleUsed: "-",
          amountSaleOnhold: "-",
        },
      },
    });
  }
}

function* getPaidGiftCards({ payload }) {
  try {
    const result = yield call(giftsCardsService.getPaidList, payload);
    yield put({
      type: types.GET_PAID_GIFT_CARDS_SUCCESS,
      payload: { data: result.data.data, meta: result.meta },
    });
  } catch (error) {
    yield put({ type: types.GET_PAID_GIFT_CARDS_ERROR, payload: { error } });
  }
}

function* getPendingGiftCards({ payload }) {
  try {
    const result = yield call(giftsCardsService.getPendingList, payload);
    yield put({
      type: types.GET_PENDING_GIFT_CARDS_SUCCESS,
      payload: { data: result.data.data, meta: result.meta },
    });
  } catch (error) {
    yield put({ type: types.GET_PENDING_GIFT_CARDS_ERROR, payload: { error } });
  }
}

export default function* root() {
  yield takeEvery(types.GET_GIFT_CARDS_AMOUNTS, getAmountsHandler);
  yield takeEvery(types.GET_PAID_GIFT_CARDS, getPaidGiftCards);
  yield takeEvery(types.GET_PENDING_GIFT_CARDS, getPendingGiftCards);
}
