import React from "react";
import PublicLayout from "../../ui/components/publicLayout";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Form,
  Title,
  SubTitle,
  InputContainer,
  InputLabel,
  Input,
  SubmitBtn,
  CancelBtn,
  Illustration,
  BtnContainer,
  ErrorLabel,
} from "./styles";

export default function CreatePassword() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    window.console.log("data=>", data);
  };

  return (
    <PublicLayout>
      <Container>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title>Crea tu contraseña</Title>
          <SubTitle>Cashship Business</SubTitle>
          <InputContainer>
            <InputLabel>Nueva Contraseña</InputLabel>
            <Input
              placeholder="Nueva contraseña"
              type="password"
              {...register("password", { required: "Campo requerido" })}
            />
            {errors?.password && (
              <ErrorLabel>{errors?.password?.message}</ErrorLabel>
            )}
          </InputContainer>
          <InputContainer>
            <InputLabel>Confirmar Contraseña</InputLabel>
            <Input
              placeholder="Confirmar Contraseña"
              type="password"
              {...register("confirm_password", { required: "Campo requerido" })}
            />
            {errors?.confirm_password && (
              <ErrorLabel>{errors?.confirm_password?.message}</ErrorLabel>
            )}
          </InputContainer>
          <BtnContainer>
            <SubmitBtn htmlType="submit">Guardar contraseña</SubmitBtn>
            <CancelBtn type="button" onClick={() => navigate("/")}>
              Cancelar
            </CancelBtn>
          </BtnContainer>
        </Form>
        <Illustration>
          <img src="/password/password-back.svg" alt="password-background" />
        </Illustration>
      </Container>
    </PublicLayout>
  );
}
