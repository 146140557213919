import React, { useState } from "react";

import LoggedLayout from "../../../ui/components/layout/index";
import {
  Container,
  EstablishmentContainer,
  GreenBoxLabel,
  GreenBoxAmount,
  Title,
  Header,
  Table,
  Row,
  GoBackText,
  InputsContainer,
  InputContainer,
  InputLabel,
  Input,
  Tab,
  TablesContainer,
  TableContentContainer,
  /* GlobalText,
  StatusContainer,
  ParentContainer, */
  FinalLabel,
  TableFooter,
  Button,
  FooterGreenBox,
} from "./styles";
import { LeftArrowIcon } from "../../../assets/icons";
import { Link } from "react-router-dom";

const originDataPending = [];
const originDataPaid = [];

for (let i = 0; i < 50; i += 1) {
  originDataPending.push({
    key: i.toString(),
    code: `0000${i}`,
    type: "Tipo de GiftCard",
    usedDate: "6/21/19",
    unitaryPrice: "$ 120",
    comission: "$ 120",
    amount: "$ 120",
  });
}
for (let i = 0; i < 50; i += 1) {
  originDataPaid.push({
    key: i.toString(),
    code: `000000012${i}`,
    pack: "01/03/21",
    type: "Tipo de GiftCard",
    usedDate: "1/15/12",
    amount: "$ 120",
  });
}

const TabFooter = ({ tab }) => (
  <TableFooter>
    <FooterGreenBox>
      <GreenBoxLabel fontSize="10px" lineHeight="14px">
        {tab === 1 ? "Total Pendiente" : "Total Pagado"}
      </GreenBoxLabel>
      <GreenBoxAmount marginTop="0">$ 6,200.00</GreenBoxAmount>
    </FooterGreenBox>
    {tab === 1 && (
      <Button>
        <Link to="/dashboard/giftCardPayments/payments">Pagar</Link>
      </Button>
    )}
  </TableFooter>
);

export default function GiftCardPaymentEstablishmentDetail() {
  const [dataPending] = useState(originDataPending);
  const [dataPaid] = useState(originDataPaid);

  const { TabPane } = Tab;

  const columnsPendingPayment = [
    {
      title: "Código de GiftCard",
      dataIndex: "code",
      width: "25%",
    },
    {
      title: "Tipo de GiftCard",
      dataIndex: "type",
      width: "15%",
    },
    {
      title: "Fecha Usado",
      dataIndex: "usedDate",
      width: "15%",
    },
    {
      title: "Precio Unitario",
      dataIndex: "unitaryPrice",
      width: "15%",
    },
    {
      title: "Comisión",
      dataIndex: "comission",
      width: "15%",
    },
    {
      title: "Monto a Pagar",
      dataIndex: "amount",
      width: "15%",
    },
  ];

  const columnsPaid = [
    {
      title: "Código de GiftCard",
      dataIndex: "code",
      width: "40%",
    },
    {
      title: "No. Lote",
      dataIndex: "pack",
      width: "15%",
    },
    {
      title: "Tipo de GiftCard",
      dataIndex: "type",
      width: "15%",
    },
    {
      title: "Fecha de Giftcard Usado",
      dataIndex: "usedDate",
      width: "15%",
    },
    {
      title: "Monto",
      dataIndex: "amount",
      width: "15%",
    },
  ];

  const tabs = [
    {
      id: 1,
      name: "Pendientes por pagar",
      column: columnsPendingPayment,
      tableData: dataPending,
    },
    {
      id: 2,
      name: "Pagado",
      column: columnsPaid,
      tableData: dataPaid,
    },
  ];

  const inputs = [
    [
      { id: 1, name: "Tipo de negocio", value: "Restaurante", width: "100%" },
      {
        id: 2,
        name: "Correo del negocio",
        value: "mail@mail.com",
        width: "100%",
      },
      {
        id: 3,
        name: "Tipo de Identificación",
        value: "Carnet",
        width: "204px",
      },
    ],
    [
      { id: 1, name: "Número Id", value: "000000000", width: "194px" },
      {
        id: 2,
        name: "Fecha de Expiración *",
        value: "28/09/2028",
        width: "194px",
      },
      { id: 3, name: "País", value: "Cuba", width: "100%" },
      { id: 4, name: "Estado", value: "Habana Vieja", width: "100%" },
      { id: 5, name: "Ciudad", value: "Habana", width: "100%" },
    ],
    [
      {
        id: 1,
        name: "Código",
        value: "12345",
        width: "194px",
      },
      {
        id: 2,
        name: "Teléfono del negocio",
        value: "+53 54312345",
        width: "194px",
      },
      {
        id: 3,
        name: "Dirección",
        value: "Dirección...",
        width: "100%",
      },
    ],
    [
      {
        id: 1,
        name: "Método de pago",
        value: "Cuenta bancaria",
        width: "200px",
      },
      {
        id: 2,
        name: "Nombre del dueño de la cuenta",
        value: "John Doe",
        width: "100%",
      },
      {
        id: 3,
        name: "Moneda",
        value: "CUP",
        width: "200px",
      },
    ],
    [
      {
        id: 1,
        name: "Nombre del Banco",
        value: "Banco Internacional de CUBA",
        width: "412px",
      },
      {
        id: 2,
        name: "Tipo de cuenta",
        value: "Corriente",
        width: "202px",
      },
      {
        id: 3,
        name: "Número de cuenta",
        value: "1234567890",
        width: "182px",
      },
    ],
  ];

  return (
    <LoggedLayout background="transparent" padding="0">
      <Container>
        <EstablishmentContainer>
          <Header>
            <Row>
              <Link to="/dashboard/giftCardPayments/list">
                <LeftArrowIcon />
              </Link>
              <GoBackText>Tipos de negocios</GoBackText>
            </Row>
            <Title>Nombre del establecimiento</Title>
          </Header>

          {inputs.map((input) => (
            <InputsContainer>
              {input.map(({ id, name, value, width }) => (
                <InputContainer key={id} width={width}>
                  <InputLabel>{name}</InputLabel>
                  <Input
                    placeholder={name}
                    value={value}
                    disabled
                    width={width}
                  />
                </InputContainer>
              ))}
            </InputsContainer>
          ))}
        </EstablishmentContainer>

        <TablesContainer>
          <Tab type="card">
            {tabs.map(({ id, name, column, tableData }) => (
              <TabPane tab={name} key={id.toString()}>
                <TableContentContainer>
                  <Table
                    bordered
                    dataSource={tableData}
                    columns={column}
                    pagination={{
                      pageSizeOptions: ["5", "10", "20", "50"],
                      responsive: true,
                      locale: { items_per_page: "" },
                      showLessItems: true,
                    }}
                  />
                  <FinalLabel>Showing: 1 - 4 of 4</FinalLabel>
                  <TabFooter tab={id} />
                </TableContentContainer>
              </TabPane>
            ))}
          </Tab>
        </TablesContainer>
      </Container>
    </LoggedLayout>
  );
}
