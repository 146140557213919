import styled from "styled-components";
import { Button as ButtonBase } from "antd";

export const Container = styled.form``;

export const Title = styled.label`
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  color: #35403b;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 392px;
  margin-top: 48px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const InputLabel = styled.label`
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  color: #35403b;
`;

export const Input = styled.input`
  background: #fcfdfd;
  border: 1px solid #35403b;
  border-radius: 4px;
  height: 48px;
  padding: 12px 16px;
`;

export const ButtonsContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  max-width: 1035px;
  width: 100%;
  margin-top: 48px;
  gap: 16px;
`;

export const CancelBtn = styled.button`
  padding: 12px 16px;

  min-width: 106px;
  height: 48px;
  background: #fcfdfd;
  border-radius: 8px;
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  color: #5ecf59;
  border: none;
`;

export const SubmitBtn = styled(ButtonBase)`
  padding: 12px 16px;
  min-width: 203px;
  height: 48px;
  border-radius: 8px;

  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  background: linear-gradient(45deg, #01c5b7 0%, #5ecf59 100%);
  color: #fcfdfd;
  border: none;
  :hover,
  :active,
  :focus {
    background: linear-gradient(45deg, #01c5b7 0%, #5ecf59 100%);
    color: #fcfdfd;
    border: none;
  }
`;

export const Content = styled.div`
  max-width: 1035px;
  width: 100%;
  min-height: 392px;
  background: #fcfdfd;
  border-radius: 16px;
  padding: 40px;
`;

export const ErrorLabel = styled.label`
  font-family: "Mulish";
  font-style: italic;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #f35726;
`;
