import { call, takeEvery, put } from "redux-saga/effects";
import loginService from "../../../../apis/services/loginService";
import * as types from "../types";

function* loginHandler(action) {
  try {
    const body = {
      email: action.payload.email.toLowerCase(),
      password: action.payload.password,
    };
    const result = yield call(loginService.login, body);

    yield put({
      type: types.AUTH_LOGIN_SUCCESS,
      payload: { token: result.token },
    });
  } catch (error) {
    yield put({
      type: types.AUTH_LOGIN_ERROR,
      payload: { error },
    });
  }
}

export default function* root() {
  yield takeEvery(types.AUTH_LOGIN, loginHandler);
}
