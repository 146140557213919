export const GET_LIST = "GET_LIST";
export const GET_LIST_SUCCESS = "GET_LIST_SUCCESS";
export const GET_LIST_ERROR = "GET_LIST_ERROR";
export const GET_AMOUNTS = "GET_AMOUNTS";
export const GET_AMOUNTS_SUCCESS = "GET_AMOUNTS_SUCCESS";
export const GET_AMOUNTS_ERROR = "GET_AMOUNTS_ERROR";
export const CHANGE_STATUS = "CHANGE_STATUS";
export const CHANGE_STATUS_SUCCESS = "CHANGE_STATUS_SUCCESS";
export const CHANGE_STATUS_ERROR = "CHANGE_STATUS_ERROR";
export const GET_DETAILS = "GET_DETAILS";
export const GET_DETAILS_SUCCESS = "GET_DETAILS_SUCCESS";
export const GET_DETAILS_ERROR = "GET_DETAILS_ERROR";
export const CLEAN_ESTABLISHMENTS = "CLEAN_ESTABLISHMENTS";
