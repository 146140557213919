import React from "react";
import Icon from "@ant-design/icons";

const LeftArrowSvg = () => (
  <svg
    width="8"
    height="13"
    viewBox="0 0 8 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.41 11.1669L2.83 6.58691L7.41 1.99691L6 0.586914L0 6.58691L6 12.5869L7.41 11.1669Z"
      fill="#111415"
    />
  </svg>
);
const RightArrowSvg = () => (
  <svg
    width="8"
    height="13"
    viewBox="0 0 8 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.589844 11.1669L5.16984 6.58691L0.589844 1.99691L1.99984 0.586914L7.99984 6.58691L1.99984 12.5869L0.589844 11.1669Z"
      fill="#111415"
    />
  </svg>
);

export const LeftArrowIcon = () => <Icon component={LeftArrowSvg} />;
export const RightArrowIcon = () => <Icon component={RightArrowSvg} />;
