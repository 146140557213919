import * as types from "../types";

const initialState = {
  isLoadingSalesReport: false,
  saleReportsData: undefined,
  meta: undefined,
  salesReportError: undefined,

  amounts: undefined,
};

const salesReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SALES_LIST:
      return {
        ...state,
        isLoadingSalesReport: true,
        meta: undefined,
        saleReportsData: undefined,
      };

    case types.GET_SALES_LIST_SUCCESS:
      return {
        isLoadingSalesReport: false,
        saleReportsData: action.payload.data,
        meta: action.payload.meta,
        salesReportError: undefined,
      };

    case types.GET_SALES_LIST_ERROR:
      return {
        isLoadingSalesReport: false,
        saleReportsData: undefined,
        meta: undefined,
        salesReportError: action.payload.error,
      };
    case types.CLEAN_STATE:
      return {
        isLoadingSalesReport: false,
        saleReportsData: undefined,
        meta: undefined,
        salesReportError: undefined,
      };

    case types.GET_AMOUTNS_SUCCESS:
      return {
        ...state,
        amounts: action.payload.amounts,
      };

    default:
      return state;
  }
};

export default salesReportReducer;
