import styled from "styled-components";
import {
  Table as TableBase,
  Select as SelectBase,
  DatePicker as DatePickerBase,
  Col as ColBase,
  Button as ButtonBase,
} from "antd";

export const InputSection = styled.form`
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    margin-bottom: 30px;
    margin: 26px 0px 0px 0px;
  }
`;
export const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const GlobalText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
`;
export const StatusContainer = styled.div`
  padding: 4px 8px;
  height: fit-content;
  background: #f0f2f1;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  width: fit-content;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  font-family: Sora;
  flex-direction: column;
  padding: 15px 6px 50px 36px;
  @media screen and (max-width: 480px) {
    padding: 0;
  }
`;

export const HeadSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 20px;
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;
export const Title = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #35403b;
  @media screen and (max-width: 480px) {
    margin-bottom: 16px;
  }
`;

export const GiftCardData = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;
export const GreenBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;
  width: 190px;
  height: 60px;
  background: rgba(183, 239, 180, 0.25);
  border: 1px solid #b7efb4;
  box-sizing: border-box;
  border-radius: 8px;
  margin-right: 11px;
  @media screen and (max-width: 480px) {
    height: fit-content;
    justify-content: center;
  }
`;

export const GeneralText = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #575f66;
`;
export const NumberGiftCard = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #299c27;
`;
export const Input = styled.input`
  width: 204px;
  height: 48px;
  background: #fafafa;
  border: 1px solid #9da6a2;
  border-radius: 4px;
  padding-left: 16px;
  margin-right: 23px;
  @media screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;
export const InputTitle = styled.span`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
  background: #fdfdfd;
  padding: 0 4px;
  margin: 0 12px;
  top: 8px;
  width: max-content;
  position: relative;
  z-index: 1;
`;
export const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
export const LeftContainer = styled.div`
  display: flex;
  justify-content: left;
`;
export const RightContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-right: 10px;
`;
export const NameText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
  cursor: pointer;
  :hover {
    color: #44b641;
  }
`;

export const TableContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-right: 51px;
  @media screen and (max-width: 480px) {
    margin-right: 0;
  }
`;

export const Table = styled(TableBase)`
  width: 100%;
  .ant-table-thead > tr > th {
    font-family: Sora;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    color: #9da6a2;
    padding: 0;
    border: none;
    background: none;
    padding-left: 8px;
  }
  .ant-table-tbody > tr > td {
    padding: 8px 0 8px 8px;
  }

  &.ant-table.ant-table-bordered
    > &.ant-table-container
    > &.ant-table-content
    > table
    > tbody
    > tr
    > td:first-child {
    background: red;
    text-align: left;
  }

  .ant-table table,
  .ant-table-thead > tr > th:not(:first-child) {
    text-align: center;
  }
  .ant-pagination-options {
    .ant-select {
      background: #fcfcfc;
      border: 1px solid #c7ced1;
      box-sizing: border-box;
      border-radius: 4px;

      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;

      font-family: Sora;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #333333;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      box-shadow: none;
      border: none;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
    }
  }

  .ant-pagination-options {
    order: -1;
    margin-left: 0;
    margin-right: 16px;
    @media screen and (max-width: 480px) {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  .ant-pagination-item {
    width: 58px;
    height: 56px;
    background: #fcfcfc;
    border: none;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    display: flex;
    a {
      font-family: Sora;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #9daaae;
    }
  }
  .ant-pagination-item-active {
    background: #44b641;
    a {
      color: #fcfcfc;
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    width: 58px;
    height: 56px;
    background: #fcfcfc;
  }
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    outline: 0;
    width: 58px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a.ant-pagination-item-link {
    width: inherit;
  }

  .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #44b641;
  }

  .ant-table-pagination.ant-pagination {
    margin: 61px 0px 0px 0px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    text-align: end;
    padding-right: 8px;
  }
  td:nth-child(5) {
    text-align: right;
  }
  .ant-table-thead > tr > th::before {
    display: none;
  }
  .ant-pagination-prev:focus-visible .ant-pagination-item-link,
  .ant-pagination-next:focus-visible .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: #44b641;
    border: none;
  }
  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
    color: #9daaae;
    border: none;
    cursor: not-allowed;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: none;
  }

  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table {
    border-top: none;
  }
  .ant-table.ant-table-bordered > .ant-table-container {
    border-left: none;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    border-right: none;
  }
  .ant-table-tbody > tr > td {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #35403b;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    border-right: none;
    padding-bottom: 4px;
  }
  .ant-table-pagination.ant-pagination {
    margin: 46px 0px 0px 0px;
    justify-content: center;
    @media screen and (max-width: 480px) {
      margin: 26px 0px 0px 0px;
    }
  }
  .ant-pagination-options .ant-select {
    width: 107px;
    height: 56px;
    @media screen and (max-width: 480px) {
      margin: 15px 0 30px 0;
    }
  }
  .ant-select-arrow,
  .anticon {
    color: #333333;
  }
  .ant-pagination.mini .ant-pagination-prev,
  .ant-pagination.mini .ant-pagination-next {
    @media screen and (max-width: 480px) {
      width: 30px;
    }
  }
`;
export const Select = styled(SelectBase)`
  height: 48px;
  width: 340px;
  background: #fafafa;
  border: 1px solid #9da6a2;
  box-sizing: border-box;
  border-radius: 4px;
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 48px;
    align-items: center;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

export const DatePicker = styled(DatePickerBase.RangePicker)`
  width: 313px;
  height: 48px;
  background: #fdfdfd;
  margin-right: 22px;

  border: 1px solid #35403b;
  :hover,
  :focus,
  :active {
    border: 1px solid #35403b;
    box-shadow: none;
  }
  box-sizing: border-box;
  border-radius: 4px;

  @media screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 10px;
  }

  &.ant-picker-focused {
    border: 1px solid #35403b;
    box-shadow: none;
  }

  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  .ant-picker-content th,
  .ant-picker-content td {
    font-family: Sora;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #5ab828;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border: 1px solid #5ab828;
  }
  .ant-picker-header-view button:hover,
  .ant-picker-header > button:hover {
    color: #5ab828;
  }

  .ant-picker-input > input {
    font-family: Sora;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }
  .ant-picker-today-btn {
    color: #5ab828;
    font-family: Sora;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
  }

  .ant-picker-input {
    width: 100%;
    max-width: 103px;
  }

  .ant-picker-input:first-child > input {
    text-align: right;
  }

  .ant-picker-range-separator {
    padding: 0 4px;
  }

  .ant-picker-suffix {
    margin-left: auto;
  }

  .ant-picker-active-bar {
    background: #5ab828;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-inner::after,
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after,
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after {
    background: #b7efb4;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after {
    border-top-color: #5ab828;
    border-bottom-color: #5ab828;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
  tr
    > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after {
    border-left-color: #5ab828;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after {
    border-right-color: #5ab828;
  }
`;
export const RowText = styled(ColBase)`
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
  font-family: Sora;
`;
export const Row = styled.div`
  display: flex;
  justify-content: center;
`;

export const RowTitle = styled(ColBase)`
  display: none;
`;
export const Button = styled(ButtonBase)`
  height: 48px;
  border-radius: 8px;

  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  color: #fcfdfd;
  background: linear-gradient(45deg, #01c5b7 0%, #5ecf59 100%);
  border: none;
  margin-left: 24px;
  :hover,
  :focus,
  :active {
    color: #fcfdfd;
    background: linear-gradient(45deg, #01c5b7 0%, #5ecf59 100%);
    border: none;
  }
`;
