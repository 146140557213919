import * as types from "../types";

export const getList = (body) => ({
  payload: body,
  type: types.GET_BUSINESS_TYPES_LIST,
});

export const changeStatusBusinessType = (id) => ({
  payload: id,
  type: types.CHANGE_STATUS_BUSINESS_TYPE,
});

export const getBusinessTypeDetails = (id) => ({
  payload: id,
  type: types.GET_BUSINESS_TYPE_DETAILS,
});

export const postBusinessType = (body) => ({
  payload: body,
  type: types.POST_BUSINESS_TYPE,
});

export const putBusinessType = (body) => ({
  payload: body,
  type: types.PUT_BUSINESS_TYPE,
});

export const cleanState = () => ({
  type: types.CLEAN_STATE,
  payload: {},
});

export const getServicesList = () => ({
  type: types.GET_SERVICES,
  payload: {},
});
