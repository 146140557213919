import * as types from "../types";

const initialState = {
  isLoadingAdding: false,
  addedSuccess: false,
  newID: undefined,
  addedError: undefined,

  isLoadingServices: false,
  servicesList: undefined,
  servicesError: undefined,

  isLoadingEdit: false,
  editSuccess: false,
  editError: undefined,
};

const newBusinessTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.POST_BUSINESS_TYPE:
      return {
        isLoadingAdding: true,
        addedSuccess: false,
        addedError: undefined,
        newID: undefined,
      };
    case types.PUT_BUSINESS_TYPE:
      return {
        ...state,
        isLoadingAdding: true,
      };
    case types.POST_BUSINESS_TYPE_SUCCESS:
      return {
        newID: action.payload.id,
        isLoadingAdding: false,
        addedSuccess: true,
        addedError: undefined,
      };
    case types.POST_BUSINESS_TYPE_ERROR:
      return {
        isLoadingAdding: false,
        addedSuccess: false,
        addedError: action.payload.error,
        newID: undefined,
      };

    case types.GET_SERVICES:
      return {
        ...state,
        isLoadingServices: true,
      };
    case types.GET_SERVICES_SUCCESS:
      return {
        ...state,
        isLoadingServices: false,
        servicesList: action.payload.data,
        servicesError: undefined,
      };
    case types.GET_SERVICES_ERROR:
      return {
        ...state,
        isLoadingServices: false,
        servicesList: undefined,
        servicesError: action.payload.error,
      };
    case types.CLEAN_STATE:
      return initialState;
    default:
      return state;
  }
};

export default newBusinessTypeReducer;
