import apiResource from "..";

const rolesUrl = (body) => {
  let url = "backoffice/roles?";

  if (body?.name) {
    url += `&name=${body?.name}`;
  }
  if (body?.page) {
    url += `&page=${body?.page}`;
  }
  if (body?.paginate) {
    url += `&paginate=${body?.paginate}`;
  }
  if (body?.status) {
    url += `&status=${body?.status}`;
  }

  return url;
};

const locationsServices = {
  getCountries: async () => {
    const response = await apiResource.fetch("GET", "countries");
    return response;
  },
  getStates: async (country_id) => {
    const response = await apiResource.fetch("GET", `states/${country_id}`);
    return response;
  },
  getCities: async (state_id) => {
    const response = await apiResource.fetch("GET", `cities/${state_id}`);
    return response;
  },
  getPrefixes: async () => {
    const response = await apiResource.fetch("GET", "prefixes");
    return response;
  },
  getRoles: async (name) => {
    const response = await apiResource.fetch("GET", rolesUrl(name));
    return response;
  },
};

export default locationsServices;
