import styled from "styled-components";
import { Menu as MenuBase } from "antd";

export const Container = styled.div`
  width: 224px;
  height: 816px;
  background: #fcfdfd;
  padding-top: 24px;
  @media screen and (max-width: 480px) {
    display: none;
}
`;

export const Content = styled.div``;

export const Menu = styled(MenuBase)`
  li.ant-menu-submenu:nth-child(1) > div:nth-child(1) > span:nth-child(2) {
    font-family: Sora;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #35403b;
  }

  &.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
  &.ant-menu-inline.ant-menu-root
    .ant-menu-submenu-title
    > .ant-menu-title-content {
    font-family: Sora;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #35403b;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: inherit;
  }
  .ant-menu:not(.ant-menu-horizontal)
    .ant-menu-item-selected
    > .ant-menu-title-content {
    color: #44b641;
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    display: none;
  }

  .ant-menu-submenu:hover
    > .ant-menu-submenu-title
    > .ant-menu-submenu-expand-icon,
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #44b641;
  }
`;
