import React, { useEffect } from "react";
import { connect } from "react-redux";
import LoggedLayout from "../../../ui/components/layout/index";
import { GetGiftCardsAmounts } from "../store/actions";
import GifCardsPaidTab from "./components/paid";
import GifCardsPendingTab from "./components/pending";
import {
  Container,
  Header,
  GreenBox,
  GreenBoxContainer,
  Title,
  GreenBoxLabel,
  GreenBoxAmount,
  Content,
  Tab,
} from "./styles";

function GiftCardPaymentList({ isLoadingAmounts, amounts, getAmounts }) {
  useEffect(() => {
    getAmounts();
  }, [getAmounts]);
  const { TabPane } = Tab;

  const tabs = [
    {
      id: 1,
      name: "Pendientes por pagar",
      component: <GifCardsPendingTab />,
    },
    {
      id: 2,
      name: "Pagado",
      component: <GifCardsPaidTab />,
    },
  ];

  const formatAmount = (value) =>
    value
      ?.toString()
      .replace(/,/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const greenBoxData = [
    {
      id: 1,
      name: "Total vendidos",
      amount: isLoadingAmounts
        ? "Loading..."
        : `${formatAmount(amounts?.totalAmount)} $`,
    },
    {
      id: 2,
      name: "Total pagado",
      amount: isLoadingAmounts
        ? "Loading..."
        : `${formatAmount(amounts?.amountSaleUsed)} $`,
    },
    {
      id: 3,
      name: "Total pendiente de pago",
      amount: isLoadingAmounts
        ? "Loading..."
        : `${formatAmount(amounts?.amountSaleOnhold)} $`,
    },
  ];

  return (
    <LoggedLayout background="transparent" padding="0">
      <Container>
        <Header>
          <Title>Pagos de GiftCard</Title>
          <GreenBoxContainer>
            {greenBoxData.map(({ id, name, amount }) => (
              <GreenBox key={id}>
                <GreenBoxLabel>{name}</GreenBoxLabel>
                <GreenBoxAmount>{amount}</GreenBoxAmount>
              </GreenBox>
            ))}
          </GreenBoxContainer>
        </Header>

        <Content>
          <Tab type="card" destroyInactiveTabPane>
            {tabs.map(({ id, name, component }) => (
              <TabPane tab={name} key={id.toString()}>
                {component}
              </TabPane>
            ))}
          </Tab>
        </Content>
      </Container>
    </LoggedLayout>
  );
}

const mapStateToProps = (state) => ({
  isLoadingAmounts: state.giftsCardsAmounts.isLoadingAmounts,
  amounts: state.giftsCardsAmounts.amounts,
});
const mapDispatchToProps = (dispatch) => ({
  getAmounts: () => dispatch(GetGiftCardsAmounts()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiftCardPaymentList);
