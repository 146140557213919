import React, { useEffect } from "react";
import LoggedLayout from "../../../ui/components/layout/index";
import { useNavigate, useParams } from "react-router-dom";
import BusinessView from "../../components/businessInfoView/index";
import { Container } from "./styles";
import { connect } from "react-redux";
import { getDetails } from "../store/actions";
import LoadingComponent from "../../components/spinner";
import { EstablishmentsIcon } from "../../../assets/icons";

function EstablishmentsDetails({
  getDetailsFn,
  detailsData,
  isLoadingDetails,
  // detailsSuccess,
}) {
  const { code } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    getDetailsFn(code);
  }, [code, getDetailsFn]);

  const formatdate = (date) =>
    new Date(date).toLocaleDateString("en-US", { timeZone: "UTC" });

  const dataToDetails = {
    user: {
      email: detailsData?.user?.email,
      full_name: detailsData?.user?.full_name,
      phone: detailsData?.user?.phone,
    },
    id: detailsData?.business_id,
    business: {
      name: detailsData?.business_name, // ! falta en string
      type: detailsData?.business_type_name,
      country: detailsData?.business_country_name,
      state: detailsData?.business_state_name,
      city: detailsData?.business_city_name,
      zip: detailsData?.business_postal_code,
      phone: `+${detailsData?.business_prefix} ${detailsData?.business_phone}`,
      email: detailsData?.business_email,
      document_type: detailsData?.business_document_type,
      document_number: detailsData?.business_id_number,
      document_expiration: formatdate(detailsData?.business_expiration_date),
      services: detailsData?.business_services,
      address: detailsData?.business_address,
      hours: detailsData?.business_hours,
      description: detailsData?.business_description,
      logo: detailsData?.business_image_path,
      images: detailsData?.business_images,
      documents: detailsData?.business_documentations,
      questions: detailsData?.business_questionnary, // ! falta
      bank_data: {
        name: detailsData?.business_bank?.name_owner,
        currency: detailsData?.business_bank?.currency,
        bank: detailsData?.business_bank?.bank,
        type: detailsData?.business_bank?.type_account,
        account_number: detailsData?.business_bank?.account_number,
      },
    },
  };

  return (
    <LoggedLayout>
      <Container>
        {isLoadingDetails ? (
          <LoadingComponent />
        ) : (
          <BusinessView
            dataToShow={dataToDetails}
            setShowInfo={() => navigate("/dashboard/establishments/list")}
            title="Establecimientos"
            icon={<EstablishmentsIcon />}
            showButtons={false}
          />
        )}
      </Container>
    </LoggedLayout>
  );
}
const mapStateToProps = (state) => ({
  isLoadingDetails: state.establishment.isLoadingDetails,
  detailsData: state.establishment.detailsData,
  detailsSuccess: state.establishment.detailsSuccess,
  detailsError: state.establishment.detailsError,
});
const mapDispatchToProps = (dispatch) => ({
  getDetailsFn: (code) => {
    dispatch(getDetails(code));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EstablishmentsDetails);
