import React, { useState } from "react";
import { ZoomInOutlined } from "@ant-design/icons";

import {
  Container,
  LogoContainer,
  PictureContainer,
  Title,
  LogoImageContainer,
  BusinessImage,
  PicturesRowContainer,
  LogoImage,
  Image,
  ImageLogo,
  PrevMaskImage,
} from "./styles";

const ImageModifyView = ({ logo, pictures = [] }) => {
  const [visible, setVisible] = useState(false);

  return (
    <Container>
      <LogoContainer>
        <Title>Logo</Title>
        <LogoImageContainer>
          <LogoImage>
            <ImageLogo
              src={logo}
              preview={{
                mask: (
                  <PrevMaskImage>
                    <ZoomInOutlined /> Visualizar
                  </PrevMaskImage>
                ),
              }}
            />
          </LogoImage>
        </LogoImageContainer>
      </LogoContainer>
      <PictureContainer>
        <Title>Fotos</Title>
        <PicturesRowContainer>
          <Image.PreviewGroup
            preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
          >
            {pictures?.map(({ id, imagen_path }) => (
              <BusinessImage key={id}>
                <Image
                  key={id}
                  src={imagen_path}
                  preview={{
                    mask: (
                      <PrevMaskImage>
                        <ZoomInOutlined /> Visualizar
                      </PrevMaskImage>
                    ),
                  }}
                  onClick={() => setVisible(true)}
                />
              </BusinessImage>
            ))}
          </Image.PreviewGroup>
        </PicturesRowContainer>
      </PictureContainer>
    </Container>
  );
};
export default ImageModifyView;
