import styled from "styled-components";
import { Menu as MenuBase } from "antd";

export const Container = styled.div`
  position: absolute;
  right: 24px;
  top: 32px;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  gap: 16px;

  svg:last-child {
    margin-left: 8px;
  }
  span {
    cursor: pointer;
  }
`;

export const UserName = styled.label`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #299c27;
`;

export const LogOut = styled.button`
  background: none;
  color: red;
  border-radius: 4px;
  border-color: red;
  cursor: pointer;

  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
`;

export const Menu = styled(MenuBase)`
  &.ant-dropdown-menu {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
  }
`;

export const ChangePassword = styled.button`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;

  background: none;
  color: #299c27;
  border-radius: 4px;
  border-color: #299c27;
  cursor: pointer;
`;
