import React, { useEffect } from "react";
import {
  Form,
  Container,
  Content,
  Title,
  SubTitle,
  InputContainer,
  Label,
  Input,
  ForgetBtn,
  Checkbox,
  Button,
  ImgContainer,
  ErrorLabel,
} from "./styles";
import { login, cleanToken } from "./store/actions";
import { connect } from "react-redux";
import { notification } from "antd";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PublicLayout from "../../ui/components/publicLayout";

function LoginComponent({
  loginUser,
  isLoading,
  loginError,
  loginSuccess,
  token,
  deleteToken,
}) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (loginError) {
      notification.error({
        message: "Error",
        description: loginError?.response?.data?.message,
      });
    }
  }, [loginError]);

  const navigate = useNavigate();

  useEffect(() => {
    if (loginSuccess) {
      window.localStorage.setItem("token", token);
      navigate("/dashboard/establishments/list");
    }
    return () => deleteToken();
  }, [loginSuccess, navigate, token, deleteToken]);

  const onSubmit = (data) => loginUser(data);

  return (
    <PublicLayout>
      <Container>
        <Content>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Title>Iniciar Sesión</Title>
            <SubTitle>Cashship Business BackOffice</SubTitle>
            <InputContainer>
              <Label>Correo electrónico</Label>
              <Input
                placeholder="Correo electrónico"
                {...register("email", {
                  required: "You must provide this field",
                })}
                disabled={isLoading}
              />
              {errors?.email && (
                <ErrorLabel>{errors?.email.message}</ErrorLabel>
              )}
            </InputContainer>
            <InputContainer>
              <Label>Contraseña</Label>
              <Input
                placeholder="Contraseña"
                {...register("password", {
                  required: "You must provide this field",
                })}
                disabled={isLoading}
                type="password"
              />
              {errors?.password && (
                <ErrorLabel>{errors?.password.message}</ErrorLabel>
              )}
            </InputContainer>
            <ForgetBtn type="button">Olvide mi contraseña</ForgetBtn>
            <Button
              onClick={() => onSubmit()}
              htmlType="submit"
              loading={isLoading}
            >
              Login
            </Button>
            <Checkbox>Recordar inicio de sesión en este dispositivo</Checkbox>
          </Form>
          <ImgContainer>
            <img src="/login/loginimage.png" alt="login-illustration" />
          </ImgContainer>
        </Content>
      </Container>
    </PublicLayout>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.login.isLoading,
  loginError: state.login.loginError,
  loginSuccess: state.login.loginSuccess,
  token: state.login.token,
});

const mapDispatchToProps = (dispatch) => ({
  loginUser: ({ email, password }) => dispatch(login({ email, password })),
  deleteToken: () => dispatch(cleanToken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
