import apiResource from "..";

const penddingUrl = (body) => {
  let url = "backoffice/enforcements/review?";
  if (body.page) {
    url += `page=${body.page}`;
  }
  if (body.name) {
    url += `name=${body.name}&`;
  }
  if (body.from) {
    url += `&from=${body.from}`;
  }
  if (body.to) {
    url += `&to=${body.to}`;
  }
  if (body.paginate) {
    url += `&paginate=${body.paginate}`;
  }

  return url;
};
const declinedUrl = (body) => {
  let url = "backoffice/enforcements/declined?";
  if (body.page) {
    url += `page=${body.page}`;
  }
  if (body.name) {
    url += `name=${body.name}&`;
  }
  if (body.from) {
    url += `&from=${body.from}`;
  }
  if (body.to) {
    url += `&to=${body.to}`;
  }
  if (body.paginate) {
    url += `&paginate=${body.paginate}`;
  }

  return url;
};
const updateUrl = (body) => {
  let url = "backoffice/enforcements?";
  if (body.page) {
    url += `page=${body.page}`;
  }
  if (body.name) {
    url += `name=${body.name}&`;
  }
  if (body.from) {
    url += `&from=${body.from}`;
  }
  if (body.to) {
    url += `&to=${body.to}`;
  }
  if (body.paginate) {
    url += `&paginate=${body.paginate}`;
  }
  if (body.status) {
    url += `&status=${body.status}`;
  }

  return url;
};

const applicationsService = {
  getApplicationsPendgin: async (body) => {
    const response = await apiResource.fetch("GET", penddingUrl(body));
    return response;
  },
  getDeclinedList: async (body) => {
    const response = await apiResource.fetch("GET", declinedUrl(body));
    return response;
  },
  getUpdateList: async (body) => {
    const response = await apiResource.fetch("GET", updateUrl(body));
    return response;
  },
  getPendingAmounts: async () => {
    const response = await apiResource.fetch(
      "GET",
      "backoffice/enforcements/status/count"
    );
    return response.data;
  },
  getDetails: async (id) => {
    const response = await apiResource.fetch(
      "GET",
      `backoffice/enforcements/show/${id}`
    );
    return response.data;
  },
  putApplication: async (body) => {
    const bodyToSend = {
      status: body.status,
      comment: body.comment ?? null,
    };

    const response = await apiResource.fetch(
      "PUT",
      `backoffice/enforcements/${body.id}`,
      bodyToSend
    );
    return response.data;
  },
};

export default applicationsService;
