import styled from "styled-components";
import { Switch as SwitchBase } from "antd";

export const Container = styled.div`
  padding: 8px 16px;
  background: #ebfbea;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
`;

export const Question = styled.label`
  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #35403b;
`;

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 24px;
`;

export const Option = styled.label`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) =>
    (props.active === true || props.active === "true" ? "#299C27" : "#35403b")};
`;

export const Switch = styled(SwitchBase)`
  &.ant-switch-checked {
    &.ant-switch {
      border: 1px solid #44b641;
    }

    .ant-switch-handle::before {
      background-color: #44b641;
    }
    :disabled {
      &.ant-switch {
        border: 1px solid #44b641;
      }
      .ant-switch-handle::before {
        background-color: #44b641;
      }
    }
  }
  &.ant-switch-checked:focus {
    box-shadow: 0 0 0 2px rgba(68, 182, 65, 0.18);
  }

  &.ant-switch {
    background-color: #fcfdfd;
    border: 1px solid #44b641;
    height: 24px;
  }
  .ant-switch-handle::before {
    background-color: #9da6a2;
    color: #44b641;
  }
  margin: 0 8px;
`;
