import styled from "styled-components";

export const Container = styled.div`
  width: ${(props) => props.width ?? "100%"};
  max-width: ${(props) => props.maxWidth ?? "100%"};
  display: flex;
  flex-direction: column;
`;

export const InputLabel = styled.label`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #6a736f;
  margin-left: 12px;
  margin-top: -8px;
  background: #fdfdfd;
  position: relative;
  top: 8px;
  z-index: 1;
  width: fit-content;
  padding: 0px 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const InputValue = styled.div`
  width: 100%;
  min-height: 48px;
  background: #fafafa;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
`;

export const Value = styled.label`
  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #6a736f;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
