import { createStore, combineReducers, applyMiddleware } from "redux";

import reduxSaga from "redux-saga";

import rootSaga from "./sagas";
import loginReducer from "../modules/Login/store/reducers/login";
import establishmentListReducer from "../modules/establishments/store/reducers";
import businessTypesReducer from "../modules/businessTypes/store/reducers";
import businessTypesDetailsReducer from "../modules/businessTypes/store/reducers/detailsReducer";
import newBusinessTypeReducer from "../modules/businessTypes/store/reducers/newTypeReducer";
import applicationsPendingReducer from "../modules/applications/store/reducers";
import applicationPendingDetailsReducer from "../modules/applications/store/reducers/detailsReducer";
import applicationDeclinesReducer from "../modules/applications/store/reducers/declinedReducer";
import applicationUpdateReducer from "../modules/applications/store/reducers/updateReducer";
import salesReportReducer from "../modules/salesReport/store/reducers";
import giftsCardsReportReducer from "../modules/giftCardPayments/store/reducers/amountsReduder";
import giftsCardsReportPaidReducer from "../modules/giftCardPayments/store/reducers/paidReduder";
import giftsCardsPendingReducer from "../modules/giftCardPayments/store/reducers/pendingReduder";
import approveDeclineReducer from "../modules/applications/store/reducers/approveDeclineReducer";
import userListReducer from "../modules/users/store/reducers/index";
import usersLocReducer from "../modules/users/store/reducers/usersLocReducer";
import rolesReducer from "../modules/users/store/reducers/rolesReducer";
import privilegesReducer from "../modules/users/store/reducers/privilegesReducer";
import userReducer from "../routes/protectedWrapper/store/reducers";
import ChangePasswordReducer from "../modules/changePassword/store/reducers/index";

const rootReducer = combineReducers({
  login: loginReducer,
  establishment: establishmentListReducer,
  businessTypes: businessTypesReducer,
  businessTypesDetails: businessTypesDetailsReducer,
  newBusinessType: newBusinessTypeReducer,
  applicationsPending: applicationsPendingReducer,
  applicationsPendingDetails: applicationPendingDetailsReducer,
  applicationsDeclined: applicationDeclinesReducer,
  applicationsUpdate: applicationUpdateReducer,
  salesReports: salesReportReducer,
  giftsCardsAmounts: giftsCardsReportReducer,
  giftsCardsPaid: giftsCardsReportPaidReducer,
  giftsCardsPending: giftsCardsPendingReducer,
  approveDecline: approveDeclineReducer,
  users: userListReducer,
  locations: usersLocReducer,
  roles: rolesReducer,
  privileges: privilegesReducer,
  user: userReducer,
  password: ChangePasswordReducer,
});
const sagaMiddleware = reduxSaga();

export default () => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

  if (typeof composeEnhancers !== "undefined") {
    return {
      ...createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(sagaMiddleware))
      ),
      runSaga: sagaMiddleware.run(rootSaga),
    };
  }

  return {
    ...createStore(rootReducer, applyMiddleware(sagaMiddleware)),
    runSaga: sagaMiddleware.run(rootSaga),
  };
};
