import apiResource from "..";

const loginService = {
  login: async (body) => {
    const response = await apiResource.fetch("POST", "backoffice/login", body);
    return response;
  },
};

export default loginService;
