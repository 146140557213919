import styled from "styled-components";
import {
  Table as TableBase,
  Form as FormBase,
  Typography as TypographyBase,
  DatePicker as DatePickerBase,
  Select as SelectBase,
  Tabs as TabBase,
  Button as ButtonBase,
} from "antd";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 0;
  font-family: Sora;
  background: transparent;
  margin-left: 10px;

  @media screen and (max-width: 480px) {
    margin-left: 0;
  }
`;

export const Form = styled(FormBase)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Typography = styled(TypographyBase)``;

export const GreenBox = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;
  width: 232.67px;
  height: 68px;
  background: rgba(183, 239, 180, 0.25);
  border: 1px solid #b7efb4;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 1;
`;

export const GreenBoxLabel = styled.label`
  font-size: ${(props) => props.fontSize || "14px"};
  line-height: ${(props) => props.lineHeight || "20px"};
  display: flex;
  align-items: center;
  text-align: center;
  color: #575f66;
  text-align: center;
`;

export const GreenBoxAmount = styled.label`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #299c27;
  margin-top: ${(props) => props.marginTop || "8px"}; ;
`;
export const Title = styled.h1`
  margin: 16px 0 0 0;
  width: 189px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #35403b;
  display: flex;
  align-self: flex-start;

  @media screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 24px;
    align-self: unset;
    justify-content: center;
    margin-top: 0;
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #fcfcfc;
  padding: 24px 16px;
  border-radius: 16px;
  flex-wrap: wrap;
  gap: 16px;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    gap: 0;
  }
`;

export const GreenBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

export const Input = styled.input`
  width: 204px;
  height: 48px;
  background: #fafafa;
  border: 1px solid #9da6a2;
  border-radius: 4px;
  padding-left: 16px;
  margin-right: 30px;

  @media screen and (max-width: 480px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const Table = styled(TableBase)`
  width: 100%;
  .ant-table-thead > tr > th {
    font-family: Sora;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    color: #9da6a2;
    padding: 0;
    border: none;
    background: none;
    padding-left: 8px;
  }
  .ant-table-tbody > tr > td {
    padding: 8px 0 8px 8px;
  }

  &.ant-table.ant-table-bordered
    > &.ant-table-container
    > &.ant-table-content
    > table
    > tbody
    > tr
    > td:first-child {
    background: red;
    text-align: left;
  }

  .ant-table table,
  .ant-table-thead > tr > th:not(:first-child) {
    text-align: center;
  }
  .ant-pagination-options {
    .ant-select {
      background: #fcfcfc;
      border: 1px solid #c7ced1;
      box-sizing: border-box;
      border-radius: 4px;

      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;

      font-family: Sora;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #333333;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      box-shadow: none;
      border: none;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
    }
  }

  .ant-pagination-options {
    order: -1;
    margin-left: 0;
    margin-right: 16px;
    @media screen and (max-width: 480px) {
      display: inline-block;
    }
  }

  .ant-pagination-item {
    width: 58px;
    height: 56px;
    background: #fcfcfc;
    border: none;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    display: flex;
    a {
      font-family: Sora;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #9daaae;
    }
  }
  .ant-pagination-item-active {
    background: #44b641;
    a {
      color: #fcfcfc;
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    width: 58px;
    height: 56px;
    background: #fcfcfc;
  }
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    outline: 0;
    width: 58px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a.ant-pagination-item-link {
    width: inherit;
  }

  .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #44b641;
  }

  .ant-table-pagination.ant-pagination {
    margin: 61px 0px 0px 0px;
  }

  .ant-table-container table > thead > tr:first-child {
    text-align: end;
    padding-right: 8px;
  }

  .ant-table-thead > tr > th::before {
    display: none;
  }
  .ant-pagination-prev:focus-visible .ant-pagination-item-link,
  .ant-pagination-next:focus-visible .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: #44b641;
    border: none;
  }
  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
    color: #9daaae;
    border: none;
    cursor: not-allowed;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: none;
  }

  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table {
    border-top: none;
  }
  .ant-table.ant-table-bordered > .ant-table-container {
    border-left: none;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    border-right: none;
  }
  .ant-table-tbody > tr > td {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #35403b;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    border-right: none;
    padding-bottom: 4px;
  }
  .ant-table-pagination.ant-pagination {
    margin: 11px 0px 0px 0px;
    justify-content: center;
  }
  .ant-pagination-options .ant-select {
    width: 107px;
    height: 56px;
    @media screen and (max-width: 480px) {
      margin: 15px 200px 15px 15px;
    }
  }
  .ant-select-arrow,
  .anticon {
    color: #333333;
  }
  .ant-pagination.mini .ant-pagination-prev,
  .ant-pagination.mini .ant-pagination-next {
    @media screen and (max-width: 480px) {
      width: 30px;
    }
  }
  @media screen and (max-width: 480px) {
    td:nth-child(1) {
      display: inline-block;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  justify-content: flex-end;
`;

export const InputsContainer = styled.form`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin: 0 0 40px 0;
  width: 100%;
  flex-wrap: wrap;
  gap: 24px;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const InputLabel = styled.label`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
  background: #fdfdfd;
  padding: 0 4px;
  margin: 0 12px;
  position: relative;
  top: 8px;
  width: max-content;
  z-index: 1;
`;

export const DatePicker = styled(DatePickerBase)``;

export const RangePickerContainer = styled.div`
  .ant-picker {
    padding: 9px 11px 4px;
  }
  .ant-picker-suffix {
    margin-bottom: 5px;
  }
  .ant-picker-range {
    width: 301px;
    height: 48px;
    border-radius: 4px;
    border: 1px solid #35403b;
    box-shadow: 0 0 0 2px #299c2713;
    :hover,
    :focus,
    :active {
      border: 1px solid #35403b;
      box-shadow: none;
    }

    .ant-picker-input > input {
      font-family: Sora;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
    }
    .ant-picker-panel-container .ant-picker-panels {
      flex-direction: column;

      .ant-picker:hover,
      .ant-picker-focused {
        width: 100%;
      }
    }
    .ant-picker-input {
      font-size: 16px;
    }
    .ant-picker-range-separator {
      margin-right: 14px;
    }
    .ant-picker-input > input:placeholder-shown {
      font-size: 16px;
      color: #9da6a2;
    }
    .ant-picker-cell-in-view.ant-picker-cell-today
      .ant-picker-cell-inner::before {
      border: 1px solid #299c27;
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start
      .ant-picker-cell-inner {
      background: #299c27;
    }
    &.ant-picker-range .ant-picker-active-bar {
      background: #299c27;
    }
    .ant-picker-date-panel
      .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
      .ant-picker-cell-inner::after,
    .ant-picker-date-panel
      .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
      .ant-picker-cell-inner::after {
      background: #299c275e;
    }
    .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before {
      background: #299c2713;
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
      border-top: 1px dashed #299c279b;
      border-bottom: 1px dashed #299c279b;
    }
    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
    tr
      > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
    .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
      border-right: 1px dashed #299c279b;
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after,
    tr
      > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
    tr
      > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
    .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after {
      border-left: 1px dashed #299c279b;
    }

    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }
`;

export const Select = styled(SelectBase)`
  width: 224px;
  height: 48px;

  background: #fdfdfd;

  border: 1px solid #9da6a2;
  box-sizing: border-box;
  border-radius: 4px;

  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #111415;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  margin-top: 24px;
  width: 100%;
  padding: 0;
`;

export const TableContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fcfdfd;
  padding: 16px;
  border-radius: ${(props) => props.borderRadius || "16px"};
  width: 100%;
`;

export const Tab = styled(TabBase)`
  width: 100%;
  .ant-tabs-nav {
    margin-bottom: 0;
    :before {
      display: none;
    }

    .ant-tabs-tab {
      border: none;
      background: transparent;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #9da6a2;
      height: 56px;
    }
    .ant-tabs-tab-active {
      background: #fcfdfd;
    }
  }

  .ant-tabs-tab {
    border-radius: 16px 16px 0 0 !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-btn:active {
    color: #35403b;
  }

  @media screen and (min-width: 1361px) {
    .ant-tabs-nav-list {
      margin-left: 16px;
    }

    .ant-tabs-tab:nth-child(2) {
      min-width: 186px;
      display: flex;
      justify-content: center;
    }
  }

  @media screen and (max-width: 480px) {
    .ant-tabs-content
      > .ant-tabs-tabpane:nth-child(2)
      > ${TableContentContainer} {
      border-radius: 16px 0 16px 16px;
    }
    .ant-tabs-content
      > .ant-tabs-tabpane:nth-child(1)
      > ${TableContentContainer} {
      border-radius: 0 16px 16px 16px;
    }

    .ant-tabs-nav-wrap {
      display: flex;
      min-width: 100%;

      .ant-tabs-nav-list {
        min-width: 100%;
      }

      .ant-tabs-tab {
        display: flex;
        flex: 1;
        justify-content: center;
      }
    }
  }
`;

export const Button = styled(ButtonBase)`
  padding: 12px 16px;

  min-width: 117px;
  height: 42px;

  :hover,
  :active,
  :focus {
    background: ${(props) =>
    (props.disabled
      ? "linear-gradient(45deg, #01C5B7 0%, #5ECF59 100%)"
      : "linear-gradient(45deg, #01C5B7 0%, #5ECF59 100%)")};
    color: #ffffff;
  }

  background: ${(props) =>
    (props.disabled
      ? "linear-gradient(45deg, #01C5B7 0%, #5ECF59 100%)"
      : "linear-gradient(45deg, #01C5B7 0%, #5ECF59 100%)")};
  border-radius: 8px;
  border: none;

  font-family: Sora;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 1361px) {
    margin-left: 24px;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 16px 0 8px 0;
  }
`;

export const TableValue = styled.div`
  display: ${(props) => (props.isFlex ? "Flex" : "")};
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
`;
