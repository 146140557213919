import styled from "styled-components";
import { Checkbox as CheckboxBase, Button as ButtonBase } from "antd";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px;
  min-height: 100vh;
  background: #ebfaf9;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 392px;
  width: 100%;
`;

export const Content = styled.div`
  max-width: 973px;
  width: 100%;
  min-height: 664px;
  height: 100%;
  background: #fcfdfd;
  border-radius: 16px;
  padding: 40px;
  align-items: center;
  display: flex;
`;

export const Title = styled.label`
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #35403b;
  text-align: center;
`;

export const SubTitle = styled.label`
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #35403b;
  text-align: center;
  margin: 8px 0px 30px 0px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

export const Label = styled.label`
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
`;

export const Input = styled.input`
  background: #fcfdfd;
  border: 1px solid #35403b;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 4px 0px;
  height: 48px;
  padding: 12px 16px;
`;

export const ForgetBtn = styled.button`
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  border: none;
  background: none;
  color: #44b641;
  cursor: pointer;
  margin: 16px 0px 24px 0px;
`;

export const Checkbox = styled(CheckboxBase)`
  margin-top: 24px;
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #35403b;
`;

export const Button = styled(ButtonBase)`
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  border-radius: 8px;
  height: 48px;
  background: linear-gradient(45deg, #01c5b7 0%, #5ecf59 100%);
  color: #fcfdfd;
  border: none;

  :hover,
  :active,
  :focus {
    background: linear-gradient(45deg, #01c5b7 0%, #5ecf59 100%);
    color: #fcfdfd;
    border: none;
  }
`;
export const ImgContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  img {
    width: 92%;
  }
`;

export const ErrorLabel = styled.label`
  font-family: "Mulish";
  font-style: italic;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #f35726;
`;
