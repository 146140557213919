import { call, takeEvery, put } from "redux-saga/effects";
import * as types from "../types";
import usersService from "../../../../apis/services/usersServices";
import locationsServices from "../../../../apis/services/locationsServices";

function* getListHandler({ payload }) {
  try {
    const body = {
      page: payload.page,
      paginate: payload.paginate,
      name: payload.name,
    };
    const data = yield call(usersService.getList, body);

    yield put({
      type: types.GET_USERS_LIST_SUCCESS,
      payload: { data: data.data.data, meta: data.meta },
    });
  } catch (error) {
    yield put({ type: types.GET_USERS_LIST_ERROR, payload: { error } });
  }
}

function* getCountriesHandler() {
  try {
    const result = yield call(locationsServices.getCountries);
    yield put({
      type: types.GET_COUNTRIES_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({ type: types.GET_COUNTRIES_ERROR, payload: { error } });
  }
}

function* getPrefixesHandler() {
  try {
    const result = yield call(locationsServices.getPrefixes);
    yield put({
      type: types.GET_PREFIXES_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({ type: types.GET_PREFIXES_ERROR, payload: { error } });
  }
}
function* getStatesHandler({ payload }) {
  try {
    const result = yield call(locationsServices.getStates, payload);
    yield put({
      type: types.GET_STATES_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({ type: types.GET_STATES_ERROR, payload: { error } });
  }
}
function* getCitiesHandler({ payload }) {
  try {
    const result = yield call(locationsServices.getCities, payload);
    yield put({
      type: types.GET_CITIES_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({ type: types.GET_CITIES_ERROR, payload: { error } });
  }
}

function* getRolesHandler({ payload }) {
  try {
    const result = yield call(locationsServices.getRoles, payload);
    yield put({
      type: types.GET_ROLES_SUCCESS,
      payload: { data: result.data.data, rolesMeta: result.meta },
    });
  } catch (error) {
    yield put({ type: types.GET_ROLES_ERROR, payload: { error } });
  }
}

function* postUser({ payload }) {
  try {
    yield call(usersService.postUser, payload);
    yield put({
      type: types.POST_USER_SUCCESS,
      payload: {},
    });
  } catch (error) {
    yield put({ type: types.POST_USER_ERROR, payload: { error } });
  }
}

function* getUser({ payload }) {
  try {
    const result = yield call(usersService.getUser, payload);
    yield put({
      type: types.GET_USER_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({ type: types.GET_USER_ERROR, payload: { error } });
  }
}

function* postRole({ payload }) {
  try {
    const result = yield call(usersService.postRole, payload);
    yield put({
      type: types.POST_ROLE_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({ type: types.POST_ROLE_ERROR, payload: { error } });
  }
}

function* getUsersByRole({ payload }) {
  try {
    const result = yield call(usersService.getUsersByRoleID, payload);
    yield put({
      type: types.GET_USERS_BY_ROLE_SUCCESS,
      payload: { data: result.data.data, meta: result.meta },
    });
  } catch (error) {
    yield put({ type: types.GET_USERS_BY_ROLE_ERROR, payload: { error } });
  }
}

function* getPriviByRole({ payload }) {
  try {
    const result = yield call(usersService.getPriviByRoleID, payload);
    yield put({
      type: types.GET_PRIVILEGES_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({ type: types.GET_PRIVILEGES_ERROR, payload: { error } });
  }
}

function* postPrivileges({ payload }) {
  try {
    yield call(usersService.postPrivileges, payload);
    yield put({
      type: types.POST_PRIVILEGES_SUCCESS,
      payload: {},
    });
  } catch (error) {
    yield put({ type: types.POST_PRIVILEGES_ERROR, payload: { error } });
  }
}

function* changeRoleStatus({ payload }) {
  try {
    yield call(usersService.changeRoleStatus, payload);
    yield put({ type: types.CHANGE_ROLE_STATUS_SUCCESS, payload });
  } catch (error) {
    yield put({ type: types.CHANGE_ROLE_STATUS_ERROR, payload: { error } });
  }
}

function* changeUserStatus({ payload }) {
  try {
    yield call(usersService.changeUserStatus, payload);
    yield put({ type: types.CHANGE_USER_STATUS_SUCCESS, payload });
  } catch (error) {
    yield put({ type: types.CHANGE_USER_STATUS_ERROR, payload: { error } });
  }
}

export default function* root() {
  yield takeEvery(types.GET_USERS_LIST, getListHandler);
  yield takeEvery(types.CHANGE_USER_STATUS, changeUserStatus);
  yield takeEvery(types.GET_COUNTRIES, getCountriesHandler);
  yield takeEvery(types.GET_PREFIXES, getPrefixesHandler);
  yield takeEvery(types.GET_STATES, getStatesHandler);
  yield takeEvery(types.GET_CITIES, getCitiesHandler);
  yield takeEvery(types.GET_ROLES, getRolesHandler);
  yield takeEvery(types.POST_USER, postUser);
  yield takeEvery(types.GET_USER, getUser);
  yield takeEvery(types.POST_ROLE, postRole);
  yield takeEvery(types.GET_USERS_BY_ROLE, getUsersByRole);
  yield takeEvery(types.GET_PRIVILEGES, getPriviByRole);
  yield takeEvery(types.POST_PRIVILEGES, postPrivileges);
  yield takeEvery(types.CHANGE_ROLE_STATUS, changeRoleStatus);
}
