export const GET_APPLICATIONS_PENDING = "GET_APPLICATIONS_PENDING";
export const GET_APPLICATIONS_PENDING_SUCCESS = "GET_APPLICATIONS_PENDING_SUCCESS";
export const GET_APPLICATIONS_PENDING_ERROR = "GET_APPLICATIONS_PENDING_ERROR";
export const GET_PENDING_AMOUNTS = "GET_PENDING_AMOUNTS";
export const GET_PENDING_AMOUNTS_SUCCESS = "GET_PENDING_AMOUNTS_SUCCESS";
export const GET_PENDING_AMOUNTS_ERROR = "GET_PENDING_AMOUNTS_ERROR";

export const GET_APPLICATIONS_PENDING_DETAIL = "GET_APPLICATIONS_PENDING_DETAIL";
export const GET_APPLICATIONS_PENDING_DETAIL_SUCCESS = "GET_APPLICATIONS_PENDING_DETAIL_SUCCESS";
export const GET_APPLICATIONS_PENDING_DETAIL_ERROR = "GET_APPLICATIONS_PENDING_DETAIL_ERROR";

export const GET_APPLICATIONS_DECLINED = "GET_APPLICATIONS_DECLINED";
export const GET_APPLICATIONS_DECLINED_SUCCESS = "GET_APPLICATIONS_DECLINED_SUCCESS";
export const GET_APPLICATIONS_DECLINED_ERROR = "GET_APPLICATIONS_DECLINED_ERROR";

export const GET_APPLICATIONS_UPDATE = "GET_APPLICATIONS_UPDATE";
export const GET_APPLICATIONS_UPDATE_SUCCESS = "GET_APPLICATIONS_UPDATE_SUCCESS";
export const GET_APPLICATIONS_UPDATE_ERROR = "GET_APPLICATIONS_UPDATE_ERROR";

export const PUT_APPLICATION = "PUT_APPLICATION";
export const PUT_APPLICATION_SUCCESS = "PUT_APPLICATION_SUCCESS";
export const PUT_APPLICATION_ERROR = "PUT_APPLICATION_ERROR";
export const CLEAN_PUT = "CLEAN_PUT";
export const HANDLE_MODAL = "HANDLE_MODAL";
