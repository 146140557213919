import * as types from "../types";

const initialState = {
  isLoadingList: false,
  listData: undefined,
  listError: undefined,
  meta: undefined,

  isLoadingAmounts: false,
  amountsData: undefined,
  amountsError: undefined,

  isLoadingChange: false,
  changeSuccess: undefined,
  changeError: undefined,
};

const establishmentListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST:
      return {
        ...state,
        isLoadingList: true,
        listData: undefined,
        listError: undefined,
        meta: undefined,
      };

    case types.GET_LIST_SUCCESS:
      return {
        ...state,
        isLoadingList: false,
        listData: action.payload.data.data,
        listError: undefined,
        meta: action.payload.data.meta,
      };

    case types.GET_LIST_ERROR:
      return {
        ...state,
        isLoadingList: false,
        listData: undefined,
        listError: action.payload.error,
      };
    case types.GET_AMOUNTS:
      return {
        ...state,
        isLoadingAmounts: true,
        amountsData: undefined,
        amountsError: undefined,
      };
    case types.GET_AMOUNTS_SUCCESS:
      return {
        ...state,
        isLoadingAmounts: false,
        amountsData: action.payload.data,
        amountsError: undefined,
      };
    case types.GET_AMOUNTS_ERROR:
      return {
        ...state,
        isLoadingAmounts: false,
        amountsData: undefined,
        amountsError: action.payload.error,
      };
    case types.CHANGE_STATUS:
      return {
        ...state,
        isLoadingChange: true,
        changeSuccess: undefined,
        changeError: undefined,
      };
    case types.CHANGE_STATUS_SUCCESS: {
      const changed = state?.listData?.data?.find(
        ({ code }) => code === action.payload.code
      );

      changed.active = !changed.active;

      return {
        ...state,
        isLoadingChange: false,
        changeSuccess: true,
        changeError: undefined,
      };
    }
    case types.CHANGE_STATUS_ERROR:
      return {
        ...state,
        isLoadingChange: false,
        changeSuccess: false,
        changeError: action.payload.error,
      };

    case types.GET_DETAILS:
      return {
        ...state,
        isLoadingDetails: true,
        detailsData: undefined,
        detailsSuccess: undefined,
        detailsError: undefined,
      };

    case types.GET_DETAILS_SUCCESS:
      return {
        ...state,
        isLoadingDetails: false,
        detailsData: action.payload.data,
        detailsSuccess: true,
        detailsError: undefined,
      };
    case types.GET_DETAILS_ERROR:
      return {
        ...state,
        isLoadingDetails: false,
        detailsData: undefined,
        detailsSuccess: false,
        detailsError: action.payload.error,
      };

    case types.CLEAN_ESTABLISHMENTS:
      return { ...state, changeSuccess: undefined, initialState };

    default:
      return state;
  }
};

export default establishmentListReducer;
