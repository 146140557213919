import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import LoggedLayout from "../../../ui/components/layout/index";
import openNotification from "../../components/notification";
import PaginationComponent from "../../components/pagination";
import { changeRoleStatus, cleanRoles, getRoles } from "../store/actions";

import {
  Container,
  Table,
  Header,
  Title,
  GlobalText,
  InputContainer,
  InputsContainer,
  Input,
  InputLabel,
  TableContentContainer,
  Button,
  ParentContainer,
  StatusContainer,
  Switch,
  ActionOption,
  Text,
} from "./styles";

function RoleList({
  loadingRoles,
  rolesData,
  rolesMeta,
  rolesError,
  getRolesFn,
  changeStatusFn,
  loadingChangeStatus,
  changeStatusSuccess,
  changeStatusError,
  clean,
}) {
  const navigate = useNavigate();
  const [name, setName] = useState();

  useEffect(() => {
    if (changeStatusSuccess) {
      openNotification("¡Éxito!", "Estatus cambiado exitosamente", "success");
    } else if (changeStatusError) {
      openNotification(
        "Error",
        "No se pudo cambiar el status del rol, intente nuevamente",
        "error"
      );
    }
  }, [changeStatusSuccess, changeStatusError, clean]);

  useEffect(() => {
    rolesError &&
      openNotification(
        "Error",
        "Error cargando roles, intente nuevamente",
        "error"
      );
  }, [rolesError]);

  useEffect(() => {
    getRolesFn({});
    return () => clean();
  }, [clean, getRolesFn]);

  const handleSearch = () => {
    getRolesFn({ name });
  };

  const handleStatusChange = (role_id) => {
    changeStatusFn(role_id);
  };

  const columns = [
    {
      title: "Rol",
      dataIndex: "name",
      width: "55%",
      render: (nameData, allData) => (
        <ParentContainer
          onClick={() => navigate(`/dashboard/role/${allData.id}`)}
        >
          <StatusContainer>
            <Text>{nameData}</Text>
          </StatusContainer>
        </ParentContainer>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "35%",
      render: (statusData, allData) => (
        <ParentContainer
          onClick={() => navigate(`/dashboard/role/${allData.id}`)}
        >
          <StatusContainer>
            <GlobalText>{statusData}</GlobalText>
          </StatusContainer>
        </ParentContainer>
      ),
    },
    {
      title: "Acciones",
      width: "5%",
      render: (data) => (
        <ActionOption>
          <Switch
            disabled={loadingChangeStatus}
            checked={data.status === "ACTIVE"}
            onClick={() => handleStatusChange(data.id)}
          />
        </ActionOption>
      ),
    },
  ];

  const getListFn = (bdy) => {
    const body = {
      ...bdy,
      name,
    };
    getRolesFn(body);
  };

  return (
    <LoggedLayout>
      <Container>
        <Header>
          <Title>Roles</Title>
          <Button margin="0 8px 0 0">
            <Link to="/dashboard/role/new">Crear</Link>
          </Button>
        </Header>

        <InputsContainer>
          <InputContainer>
            <InputLabel>Buscador</InputLabel>
            <Input
              placeholder="Nombre"
              value={name}
              onChange={(event) => setName(event.currentTarget.value)}
            />
          </InputContainer>
          <Button
            alignSelf="flex-end"
            margin="0 0 0 40px"
            onClick={() => handleSearch()}
          >
            Buscar
          </Button>
        </InputsContainer>

        <TableContentContainer>
          <Table
            bordered
            dataSource={rolesData}
            columns={columns}
            scroll={{ x: "max-content" }}
            loading={loadingRoles}
            pagination={false}
          />
        </TableContentContainer>
        {rolesMeta && (
          <PaginationComponent meta={rolesMeta} changePage={getListFn} />
        )}
      </Container>
    </LoggedLayout>
  );
}

const mapStateToProps = (state) => ({
  loadingRoles: state.roles.loadingRoles,
  rolesData: state.roles.rolesData,
  rolesError: state.roles.rolesError,
  rolesMeta: state.roles.rolesMeta,

  loadingChangeStatus: state.roles.loadingChangeStatus,
  changeStatusSuccess: state.roles.changeStatusSuccess,
  changeStatusError: state.roles.changeStatusError,
});
const mapDispatchToProps = (dispatch) => ({
  getRolesFn: (body) => dispatch(getRoles(body)),
  changeStatusFn: (role_id) => dispatch(changeRoleStatus(role_id)),
  clean: () => dispatch(cleanRoles()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleList);
