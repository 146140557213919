import styled from "styled-components";

export const Container = styled.div`
  background: #ebfaf9;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Children = styled.div`
  margin: 144px 48px 56px 52px;

  max-width: 1116px;
  width: 100%;
  min-height: 352px;
  ${(props) => props.height && `height: ${props.height}`};
  background: ${(props) => props.background || "#fcfdfd"};
  border-radius: 16px;
  padding: ${(props) => props.padding || "16px"};
  @media screen and (max-width: 480px) {
    margin: 25% 3%;
    width: 94%;
  }
`;
