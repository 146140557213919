import * as types from "../types";

const initialState = {
  loadingPostRole: false,
  postRoleSuccess: undefined,
  postRoleError: undefined,

  loadingUsersByRole: false,
  usersByRoleData: undefined,
  usersByRoleError: undefined,
  meta: undefined,

  loadingChangeStatus: false,
  changeStatusSuccess: undefined,
  changeStatusError: undefined,

  rolesData: undefined,
  rolesMeta: undefined,
  rolesError: undefined,
  loadingRoles: false,
};

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.POST_ROLE:
      return {
        loadingPostRole: true,
        postRoleSuccess: undefined,
        postRoleError: undefined,
      };
    case types.POST_ROLE_SUCCESS:
      return {
        loadingPostRole: false,
        postRoleSuccess: true,
        postRoleError: undefined,
      };
    case types.POST_ROLE_ERROR:
      return {
        loadingPostRole: false,
        postRoleSuccess: undefined,
        postRoleError: action.payload.error,
      };

    case types.GET_USERS_BY_ROLE:
      return {
        ...state,
        loadingUsersByRole: true,
        usersByRoleData: undefined,
        meta: undefined,
        usersByRoleError: undefined,
      };

    case types.GET_USERS_BY_ROLE_SUCCESS:
      return {
        ...state,
        loadingUsersByRole: false,
        usersByRoleData: action.payload.data,
        meta: action.payload.meta,
        usersByRoleError: undefined,
      };

    case types.GET_USERS_BY_ROLE_ERROR:
      return {
        ...state,
        loadingUsersByRole: false,
        usersByRoleData: undefined,
        meta: undefined,
        usersByRoleError: action.payload.error,
      };

    case types.GET_ROLES:
      return {
        ...state,
        loadingRoles: true,
        rolesata: undefined,
        rolesError: undefined,
        rolesMeta: undefined,
      };
    case types.GET_ROLES_SUCCESS:
      return {
        ...state,
        rolesData: action.payload.data,
        rolesMeta: action.payload.rolesMeta,
        rolesError: undefined,
        loadingRoles: false,
      };
    case types.GET_ROLES_ERROR:
      return {
        ...state,
        rolesData: undefined,
        rolesMeta: undefined,
        rolesError: action.payload,
        loadingRoles: false,
      };

    case types.CHANGE_ROLE_STATUS:
      return {
        ...state,
        loadingChangeStatus: true,
        changeStatusSuccess: undefined,
        changeStatusError: undefined,
      };

    case types.CHANGE_ROLE_STATUS_SUCCESS: {
      const changed = state.rolesData.find(({ id }) => id === action.payload);
      changed.status = changed.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";

      return {
        ...state,
        loadingChangeStatus: false,
        changeStatusSuccess: true,
        changeStatusError: undefined,
      };
    }

    case types.CHANGE_ROLE_STATUS_ERROR:
      return {
        ...state,
        loadingChangeStatus: false,
        changeStatusSuccess: undefined,
        changeStatusError: action.payload.error,
      };

    case types.CLEAN_ROLES:
      return initialState;

    default:
      return state;
  }
};

export default rolesReducer;
