import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Container } from "../protectedWrapper/styles";
import { cleanUser, setUser } from "../protectedWrapper/store/actions";
import { connect } from "react-redux";
import userServices from "../../apis/services/userService";

function PublicWrapper({ children, SetUser, userClean }) {
  const [loading, isLoading] = useState(false);
  const navigate = useNavigate();
  const token = window.localStorage.getItem("token");

  useEffect(() => {
    const checkSession = async () => {
      isLoading(true);
      try {
        const response = await userServices.getMe();
        SetUser(response.user);
        isLoading(false);
        if (token) {
          navigate("/dashboard/establishments/list");
        }
      } catch (error) {
        window.localStorage.removeItem("token");
        userClean();
        isLoading(false);
      }
    };
    checkSession();
  }, [SetUser, navigate, token, userClean]);

  return (
    <div>
      {loading ? (
        <Container>
          <Spin
            indicator={
              <LoadingOutlined style={{ fontSize: 48, color: "green" }} spin />
            }
          />
        </Container>
      ) : (
        children
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  userData: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
  userClean: () => dispatch(cleanUser()),
  SetUser: (body) => dispatch(setUser(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicWrapper);
