import React, { useEffect } from "react";
import {
  Container,
  HeaderContainer,
  Tittle,
  PendingCountBox,
  TittlePendingCountBox,
  TotalPendingCountBox,
  InputContainer,
  InputLabel,
  Input,
  DatePickerContainer,
  DatePicker,
  SearchHeaderContainer,
  TableContainer,
  Table,
  Row,
  RowTitle,
  RowText,
  Label,
  RowTextFirst,
  Select,
  SelectContainer,
  Button,
} from "./styles";
import { connect } from "react-redux";
import { CalendarIcon } from "../../../assets/icons";
import LoggedLayout from "../../../ui/components/layout/index";
import { getApplicationsUpdate } from "../store/actions";
import { useForm } from "react-hook-form";
import openNotification from "../../components/notification";
import { useNavigate } from "react-router-dom";
import PaginationComponent from "../../components/pagination";

const formatdate = (date) =>
  new Date(date).toLocaleDateString("en-US", { timeZone: "UTC" });

const columns = [
  {
    title: "Establecimiento",
    dataIndex: "establishment",
    key: "establishment",
    render: (data) => (
      <Row>
        <RowTitle>Establecimiento</RowTitle>
        <RowTextFirst>{data}</RowTextFirst>
      </Row>
    ),
  },
  {
    title: "Codigo",
    dataIndex: "reference",
    key: "code",
    render: (data) => (
      <Row>
        <RowTitle>Codigo</RowTitle>
        <RowText>{data}</RowText>
      </Row>
    ),
  },
  {
    title: "Fecha de solicitud",
    dataIndex: "application_date",
    key: "applicationdate",
    render: (data) => (
      <Row>
        <RowTitle>Fecha de solicitud</RowTitle>
        <RowText>{formatdate(data)}</RowText>
      </Row>
    ),
  },
  {
    title: "Status",
    dataIndex: "fincimex_status",
    key: "status",
    render: (data) => (
      <Row>
        <RowTitle>Status</RowTitle>
        <Label>{data}</Label>
      </Row>
    ),
  },
];

const UpdateApplications = ({
  isLoadingUpdateList,
  meta,
  updateList,
  updateError,
  getList,
}) => {
  const navigate = useNavigate();

  const { handleSubmit, register, setValue, watch } = useForm({
    defaultValues: { status: "INREVIEW" },
  });

  useEffect(() => {
    const body = {
      page: 1,
      from: null,
      to: null,
      name: null,
      status: watch("status"),
      paginate: 15,
    };
    getList(body);
  }, [getList, watch]);

  useEffect(() => {
    if (updateError) {
      const messages = Object.values(updateError?.response?.data?.errors);
      if (messages) {
        messages?.map((element) => openNotification("Error", element, "error"));
      } else {
        openNotification(
          "Error",
          "Error obteniendo lista, intente nuevamente",
          "error"
        );
      }
    }
  }, [updateError]);

  const onSubmit = (data) => {
    const body = { ...data, paginate: meta?.per_page };
    getList(body);
  };

  const paginateFc = (bd) => {
    const body = {
      ...bd,
      name: watch("name"),
      from: watch("from"),
      to: watch("to"),
      status: watch("status"),
    };
    getList(body);
  };

  return (
    <LoggedLayout>
      <Container>
        <HeaderContainer>
          <Tittle>Actualizaciones de datos</Tittle>
          <PendingCountBox>
            <TittlePendingCountBox>
              Solicitudes pendientes
            </TittlePendingCountBox>
            <TotalPendingCountBox>{updateList?.length}</TotalPendingCountBox>
          </PendingCountBox>
        </HeaderContainer>

        <SearchHeaderContainer onSubmit={handleSubmit(onSubmit)}>
          <InputContainer>
            <InputLabel>Nombre del establecimiento</InputLabel>
            <Input
              placeholder="Ej: Floridita"
              {...register("name")}
              disabled={isLoadingUpdateList}
            />
          </InputContainer>
          <DatePickerContainer>
            <InputLabel>Desde - Hasta</InputLabel>
            <DatePicker
              placeholder={["dd/mm/aaaa  -  ", "dd/mm/aaaa"]}
              getPopupContainer={(trigger) => trigger}
              suffixIcon={<CalendarIcon />}
              format="yyyy/MM/DD"
              {...register("from")}
              {...register("to")}
              onChange={(_, value) => {
                setValue("from", value[0]);
                setValue("to", value[1]);
              }}
            />
          </DatePickerContainer>
          <SelectContainer>
            <InputLabel>Estatus</InputLabel>
            <Select
              placeholder="Estatus"
              bordered={false}
              allowClear={false}
              {...register("status")}
              value={watch("status")}
              onChange={(value) => setValue("status", value)}
              disabled={isLoadingUpdateList}
            >
              <Select.Option key={2} value="INREVIEW">
                In Review
              </Select.Option>
              <Select.Option key={3} value="DECLINED">
                Declined
              </Select.Option>
            </Select>
          </SelectContainer>
          <InputContainer>
            <Button htmlType="submit" disabled={isLoadingUpdateList}>
              Buscar
            </Button>
          </InputContainer>
        </SearchHeaderContainer>
        <TableContainer>
          <Table
            loading={isLoadingUpdateList}
            dataSource={updateList}
            columns={columns}
            pagination={false}
            onRow={(element) => ({
              onClick: () =>
                navigate(`/dashboard/applications/update/detail/${element.id}`),
            })}
          />
        </TableContainer>
        {meta && <PaginationComponent meta={meta} changePage={paginateFc} />}
      </Container>
    </LoggedLayout>
  );
};

const mapStateToProps = (state) => ({
  isLoadingUpdateList: state.applicationsUpdate.isLoadingUpdateList,
  meta: state.applicationsUpdate.meta,
  updateList: state.applicationsUpdate.updateList,
  updateError: state.applicationsUpdate.updateError,
});

const mapDispatchToProps = (dispatch) => ({
  getList: (body) => dispatch(getApplicationsUpdate(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateApplications);
