import * as types from "../types";

const initialState = {
  isLoadingPending: false,
  pendingGiftsCards: undefined,
  meta: undefined,
  error: undefined,
};
const giftsCardsPendingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PENDING_GIFT_CARDS:
      return {
        isLoadingPending: true,
        pendingGiftsCards: undefined,
        meta: undefined,
        error: undefined,
      };

    case types.GET_PENDING_GIFT_CARDS_SUCCESS:
      return {
        isLoadingPending: false,
        pendingGiftsCards: action.payload.data,
        meta: action.payload.meta,
        error: undefined,
      };
    case types.GET_PENDING_GIFT_CARDS_ERROR:
      return {
        isLoadingPending: false,
        pendingGiftsCards: undefined,
        meta: undefined,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default giftsCardsPendingReducer;
