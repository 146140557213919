export const GET_BUSINESS_TYPES_LIST = "GET_BUSINESS_TYPES_LIST";
export const GET_BUSINESS_TYPES_LIST_SUCCESS = "GET_BUSINESS_TYPES_LIST_SUCCESS";
export const GET_BUSINESS_TYPES_LIST_ERROR = "GET_BUSINESS_TYPES_LIST_ERROR";
export const CHANGE_STATUS_BUSINESS_TYPE = "CHANGE_STATUS_BUSINESS_TYPE";
export const CHANGE_STATUS_BUSINESS_TYPE_SUCCESS = "CHANGE_STATUS_BUSINESS_TYPE_SUCCESS";
export const CHANGE_STATUS_BUSINESS_TYPE_ERROR = "CHANGE_STATUS_BUSINESS_TYPE_ERROR";

export const GET_BUSINESS_TYPE_DETAILS = "GET_BUSINESS_TYPE_DETAILS";
export const GET_BUSINESS_TYPE_DETAILS_SUCCESS = "GET_BUSINESS_TYPE_DETAILS_SUCCESS";
export const GET_BUSINESS_TYPE_DETAILS_ERROR = "GET_BUSINESS_TYPE_DETAILS_ERROR";
export const POST_BUSINESS_TYPE = "POST_BUSINESS_TYPE";
export const PUT_BUSINESS_TYPE = "PUT_BUSINESS_TYPE";

export const POST_BUSINESS_TYPE_SUCCESS = "POST_BUSINESS_TYPE_SUCCESS";
export const POST_BUSINESS_TYPE_ERROR = "POST_BUSINESS_TYPE_ERROR";

export const CLEAN_STATE = "CLEAN_STATE";

export const GET_SERVICES = "GET_SERVICES";
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_ERROR = "GET_SERVICES_ERROR";
