export const GET_GIFT_CARDS_AMOUNTS = "GET_GIFT_CARDS_AMOUNTS";
export const GET_GIFT_CARDS_AMOUNTS_SUCCESS = "GET_GIFT_CARDS_AMOUNTS_SUCCESS";
export const GET_GIFT_CARDS_AMOUNTS_ERROR = "GET_GIFT_CARDS_AMOUNTS_ERROR";

export const GET_PAID_GIFT_CARDS = "GET_PAID_GIFT_CARDS";
export const GET_PAID_GIFT_CARDS_SUCCESS = "GET_PAID_GIFT_CARDS_SUCCESS";
export const GET_PAID_GIFT_CARDS_ERROR = "GET_PAID_GIFT_CARDS_ERROR";

export const GET_PENDING_GIFT_CARDS = "GET_PENDING_GIFT_CARDS";
export const GET_PENDING_GIFT_CARDS_SUCCESS = "GET_PENDING_GIFT_CARDS_SUCCESS";
export const GET_PENDING_GIFT_CARDS_ERROR = "GET_PENDING_GIFT_CARDS_ERROR";
