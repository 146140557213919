import React from "react";
import { Container, FileName } from "./styles";
import { FileIcon } from "../../../../../assets/icons";

const Input = ({ file }) => (
  <Container>
    <FileName href={file?.file_path} target="_blank">
      {file?.document_name}
    </FileName>
    <FileIcon />
  </Container>
);

export default Input;
