import * as types from "../types";

export const getApplicationPendingList = (body) => ({
  type: types.GET_APPLICATIONS_PENDING,
  payload: body,
});

export const getApplicationPendingDetails = (id) => ({
  type: types.GET_APPLICATIONS_PENDING_DETAIL,
  payload: id,
});

export const getApplicationDeclined = (body) => ({
  type: types.GET_APPLICATIONS_DECLINED,
  payload: body,
});
export const getApplicationsUpdate = (body) => ({
  type: types.GET_APPLICATIONS_UPDATE,
  payload: body,
});

export const putBusinessApplication = (body) => ({
  // ! sirve para aprobar / negar un update de data o un nuevo negocio
  type: types.PUT_APPLICATION,
  payload: body,
});
export const handleModal = (body) => ({
  type: types.HANDLE_MODAL,
  payload: body,
});
export const cleanPutReducer = (body) => ({
  type: types.CLEAN_PUT,
  payload: body,
});
