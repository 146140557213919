import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  TableContainer,
  Table,
  Form,
  Typography,
  Input,
  Switch,
  ActionOption,
  StatusContainer,
  LeftContainer,
  SaveButton,
  CancelButton,
  GlobalText,
  NameText,
  ParentContainer,
  RowContent,
  TitleText,
  GreenText,
} from "./styles";
import { EditPenIcon } from "../../../assets/icons";
import LoggedLayout from "../../../ui/components/layout/index";
import {
  getList,
  changeStatusBusinessType,
  cleanState,
} from "../store/actions";
import { connect } from "react-redux";
import openNotification from "../../components/notification";
import PaginationComponent from "../../components/pagination/index";

const EditableCell = ({ editing, dataIndex, title, children }) => {
  const inputNode = <Input />;
  return (
    <td>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

function BusinessTypeList({
  getListData,
  isLoadingList,
  listData,
  meta,

  listError,
  changeStatus,

  isLoadingChangeStatus,
  changeStatusSuccess,
  changeStatusError,

  cleanFn,
}) {
  useEffect(() => {
    getListData({ page: 1, paginate: 15 });
  }, [getListData, cleanFn]);

  useEffect(() => {
    if (listError) {
      openNotification("Error", "Error getting Business Types List", "error");
    }
  }, [listError]);

  useEffect(() => {
    if (changeStatusSuccess) {
      openNotification("Sucess", "Status Changed successfully", "success");
    }
    if (changeStatusError) {
      openNotification("Error", "Erro chaging status", "error");
    }
    return cleanFn();
  }, [changeStatusError, changeStatusSuccess, cleanFn]);

  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record.code === editingKey;

  const cancel = () => {
    setEditingKey("");
  };
  const save = () => {
    setEditingKey("");
  };

  const columns = [
    {
      title: "Nombre del tipo de negocio",
      dataIndex: "name",
      width: "60%",
      editable: true,
      render: (nameData, allData) => (
        <Link to={`/dashboard/businessTypes/details/${allData.id}`}>
          <LeftContainer>
            <NameText>{nameData}</NameText>
          </LeftContainer>
        </Link>
      ),
    },
    {
      title: "Codigo",
      dataIndex: "code",
      width: "15%",
      editable: true,
    },
    {
      title: "Estatus",
      dataIndex: "status",
      width: "15%",
      render: (statusData) => (
        <ParentContainer>
          <StatusContainer>
            <GlobalText>{statusData}</GlobalText>
          </StatusContainer>
        </ParentContainer>
      ),
    },
    {
      title: "Acciones",
      width: "10%",
      dataIndex: "status",
      render: (status, allData) => {
        const editable = isEditing(allData);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save({ name: allData.name })}
              style={{
                marginRight: 2,
                color: "#35403b",
              }}
            >
              <SaveButton>Save</SaveButton>
            </Typography.Link>
            <CancelButton onClick={cancel}>Cancel</CancelButton>
          </span>
        ) : (
          <ActionOption>
            <Link to={`/dashboard/businessTypes/edit/${allData.id}`}>
              <EditPenIcon />
            </Link>

            <Switch
              disabled={isLoadingChangeStatus}
              defaultChecked={status === "ON" ? 1 : 0}
              checked={status === "ON"}
              onChange={() =>
                changeStatus({
                  id: allData.id,
                  code: allData.code,
                  status: allData.status === "ON" ? "OFF" : "ON",
                  name: allData.name,
                })
              }
            />
          </ActionOption>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "code" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <LoggedLayout>
      <TableContainer>
        <RowContent>
          <TitleText>Tipos de negocios</TitleText>
          <Link to="/dashboard/businessTypes/new">
            <GreenText>Añadir negocio</GreenText>
          </Link>
        </RowContent>
        <Table
          loading={isLoadingList}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowKey="code"
          bordered
          dataSource={listData}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
        />
      </TableContainer>
      {meta && <PaginationComponent meta={meta} changePage={getListData} />}
    </LoggedLayout>
  );
}

const mapStateToProps = (state) => ({
  isLoadingList: state.businessTypes.isLoadingList,
  listData: state.businessTypes.listData,
  meta: state.businessTypes.meta,
  listSuccess: state.businessTypes.listSuccess,
  listError: state.businessTypes.listError,

  isLoadingChangeStatus: state.businessTypes.isLoadingChangeStatus,
  changeStatusSuccess: state.businessTypes.changeStatusSuccess,
  changeStatusError: state.businessTypes.changeStatusError,
});
const mapDispatchToProps = (dispatch) => ({
  getListData: (body) => {
    dispatch(getList(body));
  },
  changeStatus: (code) => {
    dispatch(changeStatusBusinessType(code));
  },
  cleanFn: (code) => dispatch(cleanState(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessTypeList);
