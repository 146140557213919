import apiResource from "..";

const servicesService = {
  getServices: async () => {
    const response = await apiResource.fetch("GET", "services");
    return response.data;
  },
};

export default servicesService;
