import React, { useState, useEffect } from "react";
import userServices from "../../apis/services/userService";
import { useNavigate } from "react-router-dom";
import { cleanUser, setUser } from "./store/actions";
import { connect } from "react-redux";
import { Container } from "./styles";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function ProtectedWrapper({ children, userClean, SetUser }) {
  const [loading, isLoading] = useState(false);
  const navigate = useNavigate();
  const token = window.localStorage.getItem("token");

  useEffect(() => {
    const checkSession = async () => {
      isLoading(true);
      try {
        const response = await userServices.getMe();
        SetUser(response.user);
        isLoading(false);
      } catch (error) {
        window.localStorage.removeItem("token");
        userClean();
        navigate("/");
        isLoading(false);
      }
    };
    checkSession();
  }, [navigate, token, SetUser, userClean]);

  return (
    <div>
      <div>
        {loading ? (
          <Container>
            <Spin
              indicator={(
                <LoadingOutlined
                  style={{ fontSize: 48, color: "green" }}
                  spin
                />
              )}
            />
          </Container>
        ) : (
          children
        )}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  userData: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
  userClean: () => dispatch(cleanUser()),
  SetUser: (body) => dispatch(setUser(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedWrapper);
