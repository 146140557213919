import * as types from "../types";

export const getUsersList = ({ page, name, paginate }) => ({
  payload: { page, name, paginate },
  type: types.GET_USERS_LIST,
});
export const cleanUsersState = () => ({
  payload: {},
  type: types.CLEAN_USERS,
});
export const changeUserStatus = (id) => ({
  payload: id,
  type: types.CHANGE_USER_STATUS,
});

export const getCountries = () => ({
  payload: {},
  type: types.GET_COUNTRIES,
});

export const getStates = (country_id) => ({
  payload: country_id,
  type: types.GET_STATES,
});

export const getCities = (state_id) => ({
  payload: state_id,
  type: types.GET_CITIES,
});
export const getPrefixes = () => ({
  payload: {},
  type: types.GET_PREFIXES,
});
export const getRoles = ({ name, page, paginate, status }) => ({
  payload: { name, page, paginate, status },
  type: types.GET_ROLES,
});

export const postUser = (body) => ({
  payload: body,
  type: types.POST_USER,
});

export const getUser = (id) => ({
  payload: id,
  type: types.GET_USER,
});

export const postRole = (body) => ({
  payload: body,
  type: types.POST_ROLE,
});

export const getUsersByRole = ({ role_id, page, paginate }) => ({
  payload: { role_id, page, paginate },
  type: types.GET_USERS_BY_ROLE,
});

export const getPrivileges = (role_id) => ({
  payload: role_id,
  type: types.GET_PRIVILEGES,
});

export const postPrivileges = ({ body, role_id }) => ({
  payload: { body, role_id },
  type: types.POST_PRIVILEGES,
});
export const changeRoleStatus = (role_id) => ({
  payload: role_id,
  type: types.CHANGE_ROLE_STATUS,
});

export const cleanRoles = () => ({
  payload: {},
  type: types.CLEAN_ROLES,
});
export const cleanPrivi = () => ({
  payload: {},
  type: types.CLEAN_PRIVI,
});
export const clean = () => ({
  payload: {},
  type: types.CLEAN_LOC,
});
