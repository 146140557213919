import * as types from "../types";

export const changePassword = (body) => ({
  payload: body,
  type: types.CHANGE_PASSWORD,
});

export const resetPassword = () => ({
  payload: {},
  type: types.RESET_PASSWORD,
});
