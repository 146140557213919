import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Container,
  ContentDisplay,
  GoBackText,
  Row,
  Label,
  Text,
  AmountContainer,
  AmountText,
  Column,
  TitleText,
  Line,
  TypeImg,
} from "./styles";
import { connect } from "react-redux";
import { LeftArrowIcon } from "../../../assets/icons";
import LoggedLayout from "../../../ui/components/layout/index";
import { getBusinessTypeDetails } from "../store/actions";
import openNotification from "../../components/notification";
import { Result } from "antd";
import LoadingComponent from "../../components/spinner";

function BusinessTypeDetails({
  getDetails,
  isLoadingDetails,
  detailsData,
  detailsError,
}) {
  const { id } = useParams();

  useEffect(() => {
    getDetails(id);
  }, [getDetails, id]);

  useEffect(() => {
    if (detailsError) {
      openNotification("Error", "Error getting details, try again", "error");
    }
  }, [detailsError]);

  return (
    <LoggedLayout>
      <Container>
        <ContentDisplay>
          <Row>
            <Link to="/dashboard/businessTypes/list">
              <LeftArrowIcon />
            </Link>
            <GoBackText>Tipos de negocios</GoBackText>
          </Row>
          {isLoadingDetails ? (
            <LoadingComponent />
          ) : !detailsError ? (
            <div>
              <TitleText>Visualizar tipo de negocio</TitleText>
              <Row>
                <TypeImg
                  src={detailsData?.full_url_icon_active}
                  alt={detailsData?.name}
                />
                <TypeImg
                  src={detailsData?.full_url_icon_inactive}
                  alt={detailsData?.name}
                />
                <Column>
                  <Label>Nombre del tipo de Negocio</Label>
                  <Text>{detailsData?.name}</Text>
                </Column>
              </Row>
              <Row>
                <Column>
                  <Label>Codigo</Label>
                  <Text>{detailsData?.code}</Text>
                </Column>
                <Column>
                  <Label>Estatus</Label>
                  <Text>
                    {detailsData?.status === "ON" ? "Active" : "Inactive"}
                  </Text>
                </Column>
              </Row>
              <div>
                <Label>Precios GiftCards</Label>
                <AmountContainer>
                  {detailsData?.amount_availables?.map((ele) => (
                    <Column key={ele.id}>
                      <AmountText> {ele.name}</AmountText>
                      <Line />
                    </Column>
                  ))}
                </AmountContainer>
              </div>
              <div>
                <Label>Servicios</Label>
                <AmountContainer>
                  {detailsData?.service_availables?.map(
                    ({ service_name, service_id }) => (
                      <Column key={service_id}>
                        <AmountText> {service_name}</AmountText>
                        <Line />
                      </Column>
                    )
                  )}
                </AmountContainer>
              </div>
            </div>
          ) : (
            <Result
              status="404"
              title="Error obteniendo details"
              subTitle="Sorry, the business type you visited does not exist, try again"
              extra={
                <Link to="/dashboard/businessTypes/list">
                  <button>Back to List</button>
                </Link>
              }
            />
          )}
        </ContentDisplay>
      </Container>
    </LoggedLayout>
  );
}
const mapStateToProps = (state) => ({
  isLoadingDetails: state.businessTypesDetails.isLoadingDetails,
  detailsData: state.businessTypesDetails.detailsData,
  detailsError: state.businessTypesDetails.detailsError,
});
const mapDispatchToProps = (dispatch) => ({
  getDetails: (id) => {
    dispatch(getBusinessTypeDetails(id));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessTypeDetails);
