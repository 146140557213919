export const GET_USERS_LIST = "GET_USERS_LIST";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_ERROR = "GET_USERS_LIST_ERROR";
export const CLEAN_USERS = "CLEAN_USERS";

export const CHANGE_USER_STATUS = "CHANGE_USER_STATUS";
export const CHANGE_USER_STATUS_SUCCESS = "CHANGE_USER_STATUS_SUCCESS";
export const CHANGE_USER_STATUS_ERROR = "CHANGE_USER_STATUS_ERROR";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_ERROR = "GET_COUNTRIES_ERROR";

export const GET_STATES = "GET_STATES";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_ERROR = "GET_STATES_ERROR";

export const GET_CITIES = "GET_CITIES";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_ERROR = "GET_CITIES_ERROR";

export const GET_PREFIXES = "GET_PREFIXES";
export const GET_PREFIXES_SUCCESS = "GET_PREFIXES_SUCCESS";
export const GET_PREFIXES_ERROR = "PREFIXESTIES_ERROR";

export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_ERROR = "GET_ROLES_ERROR";

export const POST_USER = "POST_USER";
export const POST_USER_SUCCESS = "POST_USER_SUCCESS";
export const POST_USER_ERROR = "POST_USER_ERROR";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const POST_ROLE = "POST_ROLE";
export const POST_ROLE_SUCCESS = "POST_ROLE_SUCCESS";
export const POST_ROLE_ERROR = "POST_ROLE_ERROR";
export const CLEAN_ROLES = "CLEAN_ROLES";

export const GET_USERS_BY_ROLE = "GET_USERS_BY_ROLE";
export const GET_USERS_BY_ROLE_SUCCESS = "GET_USERS_BY_ROLE_SUCCESS";
export const GET_USERS_BY_ROLE_ERROR = "GET_USERS_BY_ROLE_ERROR";

export const GET_PRIVILEGES = "GET_PRIVILEGES";
export const GET_PRIVILEGES_SUCCESS = "GET_PRIVILEGES_SUCCESS";
export const GET_PRIVILEGES_ERROR = "GET_PRIVILEGES_ERROR";

export const POST_PRIVILEGES = "POST_PRIVILEGES";
export const POST_PRIVILEGES_SUCCESS = "POST_PRIVILEGES_SUCCESS";
export const POST_PRIVILEGES_ERROR = "POST_PRIVILEGES_ERROR";

export const CHANGE_ROLE_STATUS = "CHANGE_ROLE_STATUS";
export const CHANGE_ROLE_STATUS_SUCCESS = "CHANGE_ROLE_STATUS_SUCCESS";
export const CHANGE_ROLE_STATUS_ERROR = "CHANGE_ROLE_STATUS_ERROR";

export const CLEAN_LOC = "CLEAN_LOC";
export const CLEAN_PRIVI = "CLEAN_PRIVI";
