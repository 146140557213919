import { spawn } from "redux-saga/effects";

import loginSagas from "../modules/Login/store/sagas/loginSagas";
import establishmentsSagas from "../modules/establishments/store/sagas/index";
import businessTypesSagas from "../modules/businessTypes/store/sagas";
import applicationsSagas from "../modules/applications/store/sagas/index";
import salesReportsSagas from "../modules/salesReport/store/sagas/index";
import giftsCardsSagas from "../modules/giftCardPayments/store/sagas";
import usersSagas from "../modules/users/store/saga";
import passwordSaga from "../modules/changePassword/store/sagas";

export default function* rootSaga() {
  yield spawn(loginSagas);
  yield spawn(establishmentsSagas);
  yield spawn(businessTypesSagas);
  yield spawn(applicationsSagas);
  yield spawn(salesReportsSagas);
  yield spawn(giftsCardsSagas);
  yield spawn(usersSagas);
  yield spawn(passwordSaga);
}
