import React from "react";
import ReactDOM from "react-dom";
import RoutesComponent from "./routes/routes";
import "antd/dist/antd.css";

import { Provider } from "react-redux";

import myStore from "./store/store";

const storeInstance = myStore();

ReactDOM.render(
  <Provider store={storeInstance}>
    <RoutesComponent />
  </Provider>,
  document.getElementById("root"),
);
