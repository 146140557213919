import apiResource from "..";

const paidUrl = (body) => {
  let url = "backoffice/giftcards/paid?";

  if (body.name) {
    url += `name=${body.name}&`;
  }
  if (body.from) {
    url += `&from=${body.from}`;
  }
  if (body.to) {
    url += `&to=${body.to}`;
  }
  if (body.paginate) {
    url += `&paginate=${body.paginate}`;
  }
  if (body.page) {
    url += `&page=${body.page}`;
  }
  return url;
};

const pendingUrl = (body) => {
  let url = "backoffice/giftcards?";

  if (body.name) {
    url += `name=${body.name}&`;
  }
  if (body.from) {
    url += `&from=${body.from}`;
  }
  if (body.to) {
    url += `&to=${body.to}`;
  }
  if (body.paginate) {
    url += `&paginate=${body.paginate}`;
  }
  if (body.page) {
    url += `&page=${body.page}`;
  }
  if (body.status) {
    url += `&status=${body.status}`;
  }
  return url;
};

const giftsCardsService = {
  getGiftsCardsAmounts: async () => {
    const response = await apiResource.fetch(
      "GET",
      "backoffice/giftcards/status/sum"
    );
    return response.data;
  },
  getPaidList: async (body) => {
    const response = await apiResource.fetch("GET", paidUrl(body));
    return response;
  },
  getPendingList: async (body) => {
    const response = await apiResource.fetch("GET", pendingUrl(body));
    return response;
  },
};

export default giftsCardsService;
