import React from "react";
import HeaderComponent from "./header";
import SideBarComponent from "./sidebar";
import { Container, Content, Children } from "./styles";
import FooterComponent from "./footer/index";

export default function LoggedLayout({
  children,
  background,
  padding,
  height,
}) {
  return (
    <Container>
      <HeaderComponent />
      <Content>
        <SideBarComponent />
        <Children background={background} padding={padding} height={height}>
          {children}
        </Children>
      </Content>
      <FooterComponent />
    </Container>
  );
}
