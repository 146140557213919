import { call, takeEvery, put } from "redux-saga/effects";
import userServices from "../../../../apis/services/userService";
import * as types from "../types";

function* changePassword({ payload }) {
  try {
    const response = yield call(userServices.changePassword, payload);
    const token = response?.data?.token;
    yield put({ type: types.CHANGE_PASSWORD_SUCCESS, payload: { token } });
  } catch (error) {
    yield put({ type: types.CHANGE_PASSWORD_ERROR, payload: error });
  }
}

export default function* root() {
  yield takeEvery(types.CHANGE_PASSWORD, changePassword);
}
