import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  DatePicker,
  Input,
  InputContainer,
  InputLabel,
  InputsContainer,
  RangePickerContainer,
  Table,
  Select,
  TableContentContainer,
  TableValue,
  Button,
} from "../../styles";
import { GetPendingGiftCards } from "../../../store/actions";
import openNotification from "../../../../components/notification/index";
import { useForm } from "react-hook-form";
import PaginationComponent from "../../../../components/pagination";

function GifCardsPendingTab({
  isLoadingPending,
  pendingGiftsCards,
  meta,
  error,
  getList,
}) {
  const { register, handleSubmit, setValue, watch } = useForm();

  const onSubmit = (body) => {
    const bodyP = { ...body, paginate: 15 };
    getList(bodyP);
  };

  useEffect(() => {
    if (error) {
      openNotification("Error", "Error getting table data", "error");
    }
  }, [error]);
  useEffect(() => {
    const body = {
      name: null,
      from: null,
      to: null,
      paginate: null,
    };
    getList(body);
  }, [getList]);

  const formatdate = (date) =>
    new Date(date).toLocaleDateString("en-US", { timeZone: "UTC" });
  const formatAmount = (value) =>
    value
      ?.toString()
      .replace(/,/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const column = [
    {
      title: "Establecimiento",
      dataIndex: "business_name",
      width: "20%",
      render: (nameData) => (
        <Link to="/dashboard/giftCardPayments/establishment">
          <TableValue isFlex>
            <span>{nameData}</span>
          </TableValue>
        </Link>
      ),
    },
    {
      title: "No. Referencia",
      dataIndex: "status",
      width: "17%",
    },
    {
      title: "Fecha Pendiente Desde",
      dataIndex: "date_pending_start",
      width: "17%",
      render: (date) => (
        <Link to="/dashboard/giftCardPayments/establishment">
          <TableValue>
            <span>{formatdate(date)}</span>
          </TableValue>
        </Link>
      ),
    },
    {
      title: "Fecha Pendiente Hasta",
      dataIndex: "date_pending_end",
      width: "17%",
      render: (date) => (
        <Link to="/dashboard/giftCardPayments/establishment">
          <TableValue>
            <span>{formatdate(date)}</span>
          </TableValue>
        </Link>
      ),
    },
    {
      title: "Monto Pendiente",
      dataIndex: "sum",
      width: "17%",
      render: (sum, allData) => (
        <Link to="/dashboard/giftCardPayments/establishment">
          <TableValue>
            <span>{`${formatAmount(sum)} ${allData.currency_symbol}`}</span>
          </TableValue>
        </Link>
      ),
    },
    /* {
      title: "Acción",
      dataIndex: "operation",
      width: "10%",
      render: () => <ActionText>Pagar</ActionText>,
    }, */
  ];

  const getListFn = (bdy) => {
    const body = {
      ...bdy,
      name: watch("name"),
      from: watch("from"),
      to: watch("to"),
      status: watch("status"),
    };
    getList(body);
  };

  return (
    <TableContentContainer>
      <InputsContainer onSubmit={handleSubmit(onSubmit)}>
        <InputContainer>
          <InputLabel>Nombre del establecimiento</InputLabel>
          <Input
            placeholder="Ej. La Floridita"
            {...register("name")}
            disabled={isLoadingPending}
          />
        </InputContainer>

        <InputContainer>
          <InputLabel>Desde - Hasta</InputLabel>
          <RangePickerContainer>
            <DatePicker.RangePicker
              disabled={isLoadingPending}
              placeholder={["dd/mm/aaaa", "dd/mm/aaaa"]}
              allowClear={false}
              getPopupContainer={(trigger) => trigger}
              format="yyyy/MM/DD"
              {...register("from")}
              {...register("to")}
              onChange={(_, value) => {
                setValue("from", value[0]);
                setValue("to", value[1]);
              }}
            />
          </RangePickerContainer>
        </InputContainer>
        <InputContainer>
          <InputLabel>Estatus</InputLabel>
          <Select
            placeholder="Estatus"
            bordered={false}
            allowClear
            {...register("status")}
            value={watch("status")}
            onChange={(value) => setValue("status", value)}
            disabled={isLoadingPending}
          >
            <Select.Option key={1} value="APPROVED">
              Approved
            </Select.Option>
            <Select.Option key={2} value="INREVIEW">
              In Review
            </Select.Option>
            <Select.Option key={3} value="DECLINED">
              Declined
            </Select.Option>
            <Select.Option key={3} value={null}>
              All
            </Select.Option>
          </Select>
        </InputContainer>
        <InputContainer>
          <Button htmlType="submit" disabled={isLoadingPending}>
            Buscar
          </Button>
        </InputContainer>
      </InputsContainer>

      <Table
        loading={isLoadingPending}
        bordered
        dataSource={pendingGiftsCards}
        columns={column}
        scroll={{ x: "max-content" }}
        pagination={false}
      />
      {meta && <PaginationComponent meta={meta} changePage={getListFn} />}
    </TableContentContainer>
  );
}
const mapStateToProps = (state) => ({
  isLoadingPending: state.giftsCardsPending.isLoadingPending,
  pendingGiftsCards: state.giftsCardsPending.pendingGiftsCards,
  meta: state.giftsCardsPending.meta,
  error: state.giftsCardsPending.error,
});
const mapDispatchToProps = (dispatch) => ({
  getList: (body) => dispatch(GetPendingGiftCards(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GifCardsPendingTab);
