import styled from "styled-components";

export const Container = styled.div`
  height: 208px;
  background: #0e766e;
  display: flex;
  justify-content: center;
  padding: 24px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  max-width: 1500px;
  justify-content: space-around;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  justify-content: space-between;
`;

export const Rights = styled.label`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #fcfdfd;
`;
export const SupportContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.label`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #fcfdfd;
  margin-bottom: 16px;
`;

export const Links = styled.label`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #fcfdfd;
`;
