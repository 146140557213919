import styled from "styled-components";
import {
  DatePicker as DateBase,
  Table as TableBase,
  Col as ColBase,
  Select as SelectBase,
  Button as ButtonBase,
} from "antd";

export const Container = styled.div`
  padding: 3px 16px 3px 8px;
  min-height: 616px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const Tittle = styled.h5`
  height: 48px;
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #35403b;
`;

export const PendingCountBox = styled.div`
  width: fit-content;
  min-width: 267px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px 0px;
  background: rgba(183, 239, 180, 0.25);
  border: 1px solid #b7efb4;
  box-sizing: border-box;
  border-radius: 8px;
`;

export const TittlePendingCountBox = styled.p`
  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #575f66;
  margin: 0px 0px 8px;
  white-space: nowrap;
`;

export const TotalPendingCountBox = styled.p`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #299c27;
  margin: 0px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: flex-end;
`;

export const InputLabel = styled.label`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
  position: relative;
  top: 7.5px;
  background: #fdfdfd;
  width: fit-content;
  margin: 0 12px;
  padding: 0 4px;
  z-index: 1;
  white-space: nowrap;
`;

export const Input = styled.input`
  width: 204px;
  height: 48px;
  background: #fdfdfd;
  border: 1px solid #35403b;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 16px;
  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  &:focus-visible {
    outline: 0px;
  }
  ::placeholder {
    color: #9da6a2;
  }
`;

export const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
  margin-left: 26px;
`;

export const DatePicker = styled(DateBase.RangePicker)`
  width: 301px;
  height: 48px;
  background: #fdfdfd;

  border: 1px solid #35403b;
  :hover,
  :focus,
  :active {
    border: 1px solid #35403b;
    box-shadow: none;
  }
  box-sizing: border-box;
  border-radius: 4px;

  &.ant-picker-focused {
    border: 1px solid #35403b;
    box-shadow: none;
  }

  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  .ant-picker-content th,
  .ant-picker-content td {
    font-family: Sora;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #5ab828;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border: 1px solid #5ab828;
  }
  .ant-picker-header-view button:hover,
  .ant-picker-header > button:hover {
    color: #5ab828;
  }

  .ant-picker-input > input {
    font-family: Sora;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }
  .ant-picker-today-btn {
    color: #5ab828;
    font-family: Sora;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
  }

  .ant-picker-input {
    width: 100%;
    max-width: 103px;
  }

  .ant-picker-input:first-child > input {
    text-align: right;
  }

  .ant-picker-range-separator {
    padding: 0 4px;
  }

  .ant-picker-suffix {
    margin-left: auto;
  }

  .ant-picker-active-bar {
    background: #5ab828;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-inner::after,
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after,
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after {
    background: #b7efb4;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after {
    border-top-color: #5ab828;
    border-bottom-color: #5ab828;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
  tr
    > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after {
    border-left-color: #5ab828;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after {
    border-right-color: #5ab828;
  }
`;

export const SearchHeaderContainer = styled.form`
  display: flex;
`;

export const TableContainer = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
`;

export const RowText = styled(ColBase)`
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
  font-family: Sora;
`;

export const Label = styled(ColBase)`
  display: flex;
  max-height: 24px;
  min-width: 80px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  background: #f0f2f1;
  border-radius: 8px;
  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
`;

export const RowTextFirst = styled(ColBase)`
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #35403b;
  font-family: Sora;
  &:hover,
  :focus,
  :target {
    color: #44b641;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const RowTitle = styled(ColBase)`
  display: none;
`;

export const Table = styled(TableBase)`
  &.ant-table-wrapper {
    width: 100%;
  }
  .ant-table-thead > tr > th {
    font-family: Sora;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    color: #9da6a2;
    padding: 0;
    border: none;
    background: none;
    padding: 4px 0px 12px;
  }
  .ant-table-tbody > tr > td {
    padding: 4px 8px;
  }

  .ant-pagination-options {
    .ant-select {
      background: #fcfcfc;
      border: 1px solid #f0f2f1;
      box-sizing: border-box;
      border-radius: 4px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Sora;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #333333;
    }
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none;
    border: none;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
  }

  .ant-pagination-options {
    min-width: 107px;
    order: -1;
    margin-left: 0;
    margin-right: 16px;
  }

  .ant-pagination-item {
    min-width: 58px;
    height: 56px;
    background: #fcfcfc;
    border: none;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    display: flex;
    a {
      font-family: Sora;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #9daaae;
    }
  }
  .ant-pagination-item-active {
    background: #44b641;
    a {
      color: #fcfcfc;
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    width: 58px;
    height: 56px;
    background: #fcfcfc;
  }
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    outline: 0;
    width: 58px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a.ant-pagination-item-link {
    width: inherit;
  }

  .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #44b641;
  }

  .ant-table-pagination.ant-pagination {
    margin: 41px 0px 0px 0px;
  }
  .ant-table table,
  .ant-table-thead > tr > th {
    text-align: center;
  }
  .ant-table table,
  .ant-table-thead > tr > th:nth-child(1) {
    text-align: left;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    padding-right: 8px;
  }

  td:nth-child(1) {
    width: 52%;
    max-width: 520px;
  }
  td:nth-child(2) {
    width: 13%;
    max-width: 94px;
  }
  td:nth-child(3) {
    width: 15%;
    max-width: 110px;
  }
  td:nth-child(4) {
    text-align: center;
    width: 10%;
    max-width: 88px;
  }
  tr.ant-table-row > td:nth-child(2) > div:nth-child(1) {
    justify-content: center;
  }
  tr.ant-table-row > td:nth-child(3) > div:nth-child(1) {
    justify-content: center;
  }
  tr.ant-table-row > td:nth-child(4) > div:nth-child(1) {
    justify-content: center;
  }
  .ant-table-thead > tr > th::before {
    display: none;
  }
  .ant-pagination-prev:focus-visible .ant-pagination-item-link,
  .ant-pagination-next:focus-visible .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: #44b641;
    border: none;
  }
  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
    color: #9daaae;
    border: none;
    cursor: not-allowed;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: none;
  }
  .ant-table-row {
    cursor: pointer;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
  margin-left: 24px;
`;

export const Select = styled(SelectBase)`
  display: flex;
  align-items: center;
  width: 340px;
  height: 48px;

  box-sizing: border-box;
  border-radius: 4px;

  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #9da6a2;
  color: #111415;
  background: #fafafa;
`;

export const Button = styled(ButtonBase)`
  height: 48px;
  border-radius: 8px;

  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  color: #fcfdfd;
  background: linear-gradient(45deg, #01c5b7 0%, #5ecf59 100%);
  border: none;
  margin-left: 24px;
  :hover,
  :focus,
  :active {
    color: #fcfdfd;
    background: linear-gradient(45deg, #01c5b7 0%, #5ecf59 100%);
    border: none;
  }
`;
