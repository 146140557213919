import React from "react";
import { LogoIcon } from "../../../assets/icons";
import FooterComponent from "../layout/footer";
import { Container, Header } from "./styles";
import { useLocation } from "react-router-dom";

export default function PublicLayout({ children }) {
  const location = useLocation();
  const isCreate = location?.pathname === "/create-password";

  return (
    <Container>
      {isCreate && (
        <Header>
          <LogoIcon />
        </Header>
      )}
      {children}
      <FooterComponent />
    </Container>
  );
}
