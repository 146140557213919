import React from "react";
import { LeftArrowIcon, RightArrowIcon } from "./icons";
import { Container, Content, Ul, Li, Showing, Select } from "./styles";

export default function PaginationComponent({ meta, changePage }) {
  const options = ["5", "10", "15", "20", "25", "50", "100"];

  const legth = meta?.links?.length;
  const lastOne = legth - 2;
  const isLast = meta?.links[lastOne]?.active;
  const current_page = meta?.current_page;

  return (
    <Container>
      <Content>
        <Select
          bordered={false}
          onChange={(value) => changePage({ paginate: value })}
          value={meta?.per_page}
        >
          {options.map((ele) => (
            <Select.Option value={ele}>{ele}</Select.Option>
          ))}
        </Select>
        <Ul>
          {current_page > 1 && (
            <Li
              onClick={() =>
                changePage({
                  page: current_page - 1,
                  paginate: meta?.per_page,
                })
              }
            >
              <LeftArrowIcon />
            </Li>
          )}
          {meta?.links.map(
            ({ label, active }) =>
              !(
                label.includes("&laquo; Previous") ||
                label.includes("Next &raquo;")
              ) && (
                <Li
                  active={active}
                  onClick={() =>
                    changePage({ page: label, paginate: meta?.per_page })
                  }
                >
                  {label}
                </Li>
              )
          )}
          {!isLast && (
            <Li
              onClick={() =>
                changePage({
                  page: current_page + 1,
                  paginate: meta?.per_page,
                })
              }
            >
              <RightArrowIcon />
            </Li>
          )}
        </Ul>
        <Showing>
          Mostrando {meta?.from} - {meta?.to} de {meta?.total}
        </Showing>
      </Content>
    </Container>
  );
}
