import * as types from "../types";

const initialState = {
  isLoadingList: false,
  listData: undefined,
  listSuccess: false,
  listError: undefined,
  meta: undefined,

  isLoadingChangeStatus: false,
  changeStatusSuccess: false,
  changeStatusError: undefined,
};

const businessTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BUSINESS_TYPES_LIST:
      return {
        ...state,
        isLoadingList: true,
        meta: undefined,
        listData: undefined,
        listSuccess: undefined,
        listError: undefined,
      };
    case types.GET_BUSINESS_TYPES_LIST_SUCCESS:
      return {
        ...state,
        isLoadingList: false,
        listData: action.payload.data,
        meta: action.payload.meta,
        listSuccess: true,
        listError: undefined,
      };

    case types.GET_BUSINESS_TYPES_LIST_ERROR:
      return {
        ...state,
        isLoadingList: false,
        listData: undefined,
        meta: undefined,
        listSuccess: false,
        listError: action.payload.error,
      };

    case types.CHANGE_STATUS_BUSINESS_TYPE:
      return {
        ...state,
        isLoadingChangeStatus: true,
        changeStatusSuccess: false,
        changeStatusError: undefined,
      };

    case types.CHANGE_STATUS_BUSINESS_TYPE_SUCCESS: {
      const changed = state.listData.find(({ id }) => id === action.payload.id);
      changed.status = changed.status === "ON" ? "OFF" : "ON";

      return {
        ...state,
        isLoadingChangeStatus: false,
        changeStatusSuccess: true,
        changeStatusError: undefined,
      };
    }
    case types.CHANGE_STATUS_BUSINESS_TYPE_ERROR:
      return {
        ...state,
        isLoadingChangeStatus: false,
        changeStatusSuccess: false,
        changeStatusError: action.payload.error,
      };
    case types.CLEAN_STATE:
      return {
        ...state,
        changeStatusSuccess: undefined,
        changeStatusError: undefined,
      };

    default:
      return state;
  }
};
export default businessTypesReducer;
