import React from "react";
import { Spin } from "antd";
import { Container } from "./styles";
import { LoadingOutlined } from "@ant-design/icons";

export default function LoadingComponent({ message = "Cargando..." }) {
  return (
    <Container>
      <label htmlFor="foo">{message}</label>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </Container>
  );
}
