import React, { useEffect } from "react";
import { FromDateIcon } from "../../../assets/icons";
import LoggedLayout from "../../../ui/components/layout/index";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Container,
  HeadSection,
  Title,
  GiftCardData,
  GreenBlock,
  InputSection,
  GeneralText,
  RightContainer,
  NumberGiftCard,
  Input,
  TableContainer,
  Table,
  Select,
  LeftContainer,
  NameText,
  GlobalText,
  ParentContainer,
  StatusContainer,
  DatePicker,
  InputTitle,
  ColumnContent,
  Row,
  RowTitle,
  RowText,
  Button,
} from "./styles";
import { getSalesReports, cleanState } from "../store/actions";
import openNotification from "../../components/notification";
import { useForm } from "react-hook-form";
import PaginationComponent from "../../components/pagination";

function SalesReport({
  getList,
  isLoadingSalesReport,
  saleReportsData,
  meta,
  salesReportError,
  amounts,
}) {
  useEffect(() => {
    const body = {
      name: null,
      paginate: 15,
      from: null,
      to: null,
      status: null,
    };
    getList(body);
  }, [getList]);

  useEffect(() => {
    if (salesReportError) {
      openNotification(
        "Error",
        "Error getting report sales, try again",
        "error"
      );
    }
    return cleanState();
  }, [salesReportError]);

  const giftCardData = [
    {
      key: 1,
      title: "GiftCard vendidos",
      number: amounts?.totalSale,
    },
    {
      key: 2,
      title: "GiftCard usados",
      number: amounts?.totalSaleUsed,
    },
    {
      key: 3,
      title: "GiftCard pendientes",
      number: amounts?.totalSaleOnhold,
    },
  ];

  const formatdate = (date) =>
    new Date(date).toLocaleDateString("en-US", { timeZone: "UTC" });

  const formatAmount = (value) =>
    value
      ?.toString()
      .replace(/,/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      render: (nameData) => (
        <LeftContainer>
          {/* <Link to="/dashboard/salesReport/detail"> */}
          <NameText>{nameData}</NameText>
          {/* </Link> */}
        </LeftContainer>
      ),
    },
    {
      title: "Fecha de Uso",
      dataIndex: "date_used",
      key: "date_used",
      render: (data) => (
        <Row>
          <RowTitle>Fecha de Declinación</RowTitle>
          <RowText>{formatdate(data)}</RowText>
        </Row>
      ),
    },
    {
      title: "Gift Card usados",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (statusData) => (
        <ParentContainer>
          <StatusContainer>
            <GlobalText>{statusData}</GlobalText>
          </StatusContainer>
        </ParentContainer>
      ),
    },
    {
      title: "Monto",
      dataIndex: "sum",
      key: "sum",
      render: (sum) => (
        <RightContainer>
          <GlobalText>{formatAmount(sum)} $</GlobalText>
        </RightContainer>
      ),
    },
  ];

  const { handleSubmit, setValue, register, watch } = useForm();

  const onSubmit = (body) => {
    const bodyToSend = {
      ...body,
      paginate: meta?.per_page,
      page: meta?.current_page,
    };
    getList(bodyToSend);
  };

  const getListFn = (bdy) => {
    const body = {
      ...bdy,
      name: watch("name"),
      from: watch("from"),
      to: watch("to"),
      status: watch("status"),
    };
    getList(body);
  };

  return (
    <LoggedLayout>
      <Container>
        <HeadSection>
          <Title>Reportes de ventas</Title>
          <GiftCardData>
            {giftCardData.map(({ title, number, key }) => (
              <GreenBlock key={key}>
                <GeneralText>{title}</GeneralText>
                <NumberGiftCard>{number}</NumberGiftCard>
              </GreenBlock>
            ))}
          </GiftCardData>
        </HeadSection>
        <InputSection onSubmit={handleSubmit(onSubmit)}>
          <ColumnContent>
            <InputTitle>Nombre del establecimiento</InputTitle>
            <Input
              placeholder="Ej: Floridita"
              {...register("name")}
              disabled={isLoadingSalesReport}
            />
          </ColumnContent>
          <ColumnContent>
            <InputTitle> Desde - Hasta</InputTitle>
            <DatePicker
              placeholder={["dd/mm/aaaa  -  ", "dd/mm/aaaa"]}
              allowClear={false}
              suffixIcon={<FromDateIcon />}
              getPopupContainer={(trigger) => trigger}
              format="yyyy/MM/DD"
              {...register("from")}
              {...register("to")}
              onChange={(_, value) => {
                setValue("from", value[0]);
                setValue("to", value[1]);
              }}
            />
          </ColumnContent>
          <ColumnContent>
            <InputTitle>Estatus</InputTitle>
            <Select
              placeholder="Estatus"
              bordered={false}
              allowClear={false}
              getPopupContainer={(trigger) => trigger}
              virtual={false}
              {...register("status")}
              value={watch("status")}
              onChange={(value) => setValue("status", value)}
              disabled={isLoadingSalesReport}
            >
              <Select.Option key={1} value="APPROVED">
                Approved
              </Select.Option>
              <Select.Option key={2} value="INREVIEW">
                In Review
              </Select.Option>
              <Select.Option key={3} value="DECLINED">
                Declined
              </Select.Option>
            </Select>
          </ColumnContent>
          <ColumnContent>
            <Button htmlType="submit" disabled={isLoadingSalesReport}>
              Buscar
            </Button>
          </ColumnContent>
        </InputSection>
        <TableContainer>
          <Table
            loading={isLoadingSalesReport}
            dataSource={saleReportsData}
            columns={columns}
            bordered
            rowClassName="editable-row"
            pagination={false}
          />
        </TableContainer>
        {meta && <PaginationComponent meta={meta} changePage={getListFn} />}
      </Container>
    </LoggedLayout>
  );
}
const mapStateToProps = (state) => ({
  isLoadingSalesReport: state.salesReports.isLoadingSalesReport,
  saleReportsData: state.salesReports.saleReportsData,
  meta: state.salesReports.meta,
  salesReportError: state.salesReports.salesReportError,

  amounts: state.salesReports.amounts,
});
const mapDispatchToProps = (dispatch) => ({
  getList: (body) => {
    dispatch(getSalesReports(body));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesReport);
