import * as types from "../types";

const initialState = {
  countriesData: undefined,
  countriesError: undefined,
  loadingCountries: false,

  statesData: undefined,
  statesError: undefined,
  loadingStates: false,

  citiesData: undefined,
  citiesError: undefined,
  loadingCities: false,

  prefixesData: undefined,
  prefixesError: undefined,
  loadingPrefixes: false,
};

const usersLocReducer = (state = { initialState }, action) => {
  switch (action.type) {
    case types.GET_COUNTRIES:
      return {
        ...state,
        loadingCountries: true,
        countriesData: undefined,
        countriesError: undefined,
      };
    case types.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        loadingCountries: false,
        countriesData: action.payload,
        countriesError: undefined,
      };
    case types.GET_COUNTRIES_ERROR:
      return {
        ...state,
        loadingCountries: false,
        countriesData: undefined,
        countriesError: action.payload.error,
      };

    case types.GET_STATES:
      return {
        ...state,
        loadingStates: true,
        statesData: undefined,
        statesError: undefined,
      };
    case types.GET_STATES_SUCCESS:
      return {
        ...state,
        loadingStates: false,
        statesData: action.payload,
        statesError: undefined,
      };
    case types.GET_STATES_ERROR:
      return {
        ...state,
        loadingStates: false,
        statesData: undefined,
        statesError: action.payload.error,
      };

    case types.GET_CITIES:
      return {
        ...state,
        loadingCities: true,
        citiesData: undefined,
        citiesError: undefined,
      };
    case types.GET_CITIES_SUCCESS:
      return {
        ...state,
        loadingCities: false,
        citiesData: action.payload,
        citiesError: undefined,
      };
    case types.GET_CITIES_ERROR:
      return {
        ...state,
        loadingCities: false,
        citiesData: undefined,
        citiesError: action.payload.error,
      };

    case types.GET_PREFIXES:
      return {
        ...state,
        loadingPrefixes: true,
        prefixesData: undefined,
        prefixesError: undefined,
      };
    case types.GET_PREFIXES_SUCCESS:
      return {
        ...state,
        prefixesData: action.payload,
        prefixesError: undefined,
        loadingPrefixes: false,
      };
    case types.GET_PREFIXES_ERROR:
      return {
        ...state,
        prefixesData: undefined,
        prefixesError: action.payload,
        loadingPrefixes: false,
      };

    case types.CLEAN_LOC:
      return initialState;

    default:
      return state;
  }
};

export default usersLocReducer;
