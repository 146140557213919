import React, { useState } from "react";
import PublicLayout from "../../ui/components/publicLayout";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Form,
  Title,
  SubTitle,
  InputContainer,
  InputLabel,
  Input,
  SubmitBtn,
  CancelBtn,
  Illustration,
  BtnContainer,
  ErrorLabel,
} from "./styles";

export default function RecoverPassword() {
  const navigate = useNavigate();
  const [isSingle, setIsSingle] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    window.console.log("data =>", data);
    setIsSingle(!isSingle);
  };

  return (
    <PublicLayout>
      <Container>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title>Recuperar tu contraseña</Title>
          <SubTitle>Cashship Business</SubTitle>
          {isSingle ? (
            <InputContainer>
              <InputLabel>Correo electrónico</InputLabel>
              <Input
                placeholder="Correo electrónico"
                type="text"
                {...register("email", { required: "Campo requerido" })}
              />
              {errors?.email && (
                <ErrorLabel>{errors?.email?.message}</ErrorLabel>
              )}
            </InputContainer>
          ) : (
            <>
              <InputContainer>
                <InputLabel>Nueva Contraseña</InputLabel>
                <Input
                  placeholder="Nueva contraseña"
                  type="password"
                  {...register("password", { required: "Campo requerido" })}
                />
                {errors?.password && (
                  <ErrorLabel>{errors?.password?.message}</ErrorLabel>
                )}
              </InputContainer>
              <InputContainer>
                <InputLabel>Confirmar Contraseña</InputLabel>
                <Input
                  placeholder="Confirmar Contraseña"
                  type="password"
                  {...register("confirm_password", {
                    required: "Campo requerido",
                  })}
                />
                {errors?.confirm_password && (
                  <ErrorLabel>{errors?.confirm_password?.message}</ErrorLabel>
                )}
              </InputContainer>
            </>
          )}

          <BtnContainer>
            <SubmitBtn htmlType="submit">
              {isSingle
                ? "Enviar código de verificación"
                : "Cambiar contraseña"}
            </SubmitBtn>
            <CancelBtn type="button" onClick={() => navigate("/")}>
              Cancelar
            </CancelBtn>
          </BtnContainer>
        </Form>
        <Illustration>
          <img src="/password/password-back.svg" alt="password-background" />
        </Illustration>
      </Container>
    </PublicLayout>
  );
}
