import { call, takeEvery, put } from "redux-saga/effects";
import businessTypesService from "../../../../apis/services/businessTypesService";
import servicesService from "../../../../apis/services/servicesService";
import * as types from "../types";

function* getListHandler({ payload }) {
  try {
    const body = {
      page: payload.page,
      paginate: payload.paginate,
    };
    const data = yield call(businessTypesService.getBusinessTypesList, body);
    yield put({
      type: types.GET_BUSINESS_TYPES_LIST_SUCCESS,
      payload: { data: data.data.data, meta: data.meta },
    });
  } catch (error) {
    yield put({ type: types.GET_BUSINESS_TYPES_LIST_ERROR, payload: error });
  }
}

function* changeStatus({ payload }) {
  try {
    const body = {
      name: payload.name,
      code: payload.code,
      status: payload.status,
      id: payload.id,
    };

    yield call(businessTypesService.changeStatus, body);

    yield put({
      type: types.CHANGE_STATUS_BUSINESS_TYPE_SUCCESS,
      payload: { id: payload.id },
    });
  } catch (error) {
    yield put({
      type: types.CHANGE_STATUS_BUSINESS_TYPE_ERROR,
      payload: error,
    });
  }
}

function* getDetails({ payload }) {
  try {
    const data = yield call(businessTypesService.getDetails, payload);

    yield put({
      type: types.GET_BUSINESS_TYPE_DETAILS_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: types.GET_BUSINESS_TYPE_DETAILS_ERROR,
      payload: { error },
    });
  }
}

function* postBusinessType({ payload }) {
  try {
    const data = yield call(businessTypesService.postBusinessType, payload);
    yield put({ type: types.POST_BUSINESS_TYPE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.POST_BUSINESS_TYPE_ERROR, payload: { error } });
  }
}

function* putBusinessType({ payload }) {
  try {
    const data = yield call(businessTypesService.putBusinessType, payload);
    yield put({ type: types.POST_BUSINESS_TYPE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.POST_BUSINESS_TYPE_ERROR, payload: { error } });
  }
}

function* getServicesList() {
  try {
    const data = yield call(servicesService.getServices);
    yield put({ type: types.GET_SERVICES_SUCCESS, payload: { data } });
  } catch (error) {
    yield put({ type: types.GET_SERVICES_ERROR, payload: { error } });
  }
}

export default function* root() {
  yield takeEvery(types.GET_BUSINESS_TYPES_LIST, getListHandler);
  yield takeEvery(types.CHANGE_STATUS_BUSINESS_TYPE, changeStatus);
  yield takeEvery(types.GET_BUSINESS_TYPE_DETAILS, getDetails);
  yield takeEvery(types.POST_BUSINESS_TYPE, postBusinessType);
  yield takeEvery(types.PUT_BUSINESS_TYPE, putBusinessType);
  yield takeEvery(types.GET_SERVICES, getServicesList);
}
